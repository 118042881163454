import React,{useState,useRef,useEffect} from 'react'
// import {Map, MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import maplibregl from 'maplibre-gl';
import './map.css';
import { useGlobalContext } from '../../../context'

maplibregl.setRTLTextPlugin(
    'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
    null,
    true // Lazy load the plugin
    );

export default function PointsMapTest() {
    const {allPoints} = useGlobalContext()
    useEffect(() => {
        
        if (map.current) return; //stops map from intializing more than once
        


        map.current = new maplibregl.Map({
          container: mapContainer.current,
          style: `https://api.maptiler.com/maps/streets/style.json?key=${API_KEY}`,
          center: [lng, lat],
          zoom: zoom
        });

        map.current.addControl(new maplibregl.NavigationControl(), 'top-right');
        console.log(allPoints)
        allPoints.data.map((point)=>{
            new maplibregl.Marker({color: point.point_type.color})
                .setLngLat([point.longitude,point.latitude])
                .setPopup(new maplibregl.Popup({ offset: 25 }).setHTML( `Shope Name: ${point.name}<br>
                Owner Name: ${point.owner_name}<br>
                Mobile: ${point.phone}` )) // sets a popup on this marker
                .addTo(map.current);
        })
    });

    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng] = useState(allPoints.data[0].longitude);
    const [lat] = useState(allPoints.data[0].latitude);
    const [zoom] = useState(14);
    const [API_KEY] = useState('PZ7apnmVVaxe5tYOG2ZW');

    console.log(mapContainer)
    console.log(map)

  return (
    <div className="map-wrap">
        <div ref={mapContainer} className="map" />
    </div>
    )
}

