import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Pane, Dialog, Textarea, FilePicker } from 'evergreen-ui';
import axios from "axios";
import Select from "react-select";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import { Button, Table } from "react-bootstrap";
import { Avatar } from "evergreen-ui"
import helpers from "../../../assets/js/helper";
import { Divider } from "@material-ui/core";
import APP_CONSTANTS from "../../../assets/js/constants";
const cookies = new Cookies();
const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
export const VisitPreview = (props) => {

    const [selectedFields, setSelectedFields] = useState([
        {
            id: 1,
            name: "name",
            selected: false,
            has_sub_name: false,
            value_name: "name",
            has_changes: false
        },
        {
            id: 2,
            name: "owner_name",
            selected: false,
            has_sub_name: false,
            value_name: "owner_name",
            has_changes: false
        },
        {
            id: 3,
            name: "owner_type",
            sub_name: "name",
            has_sub_name: true,
            selected: false,
            value_name: "owner_type_id"
        },
        {
            id: 4,
            name: "phone",
            has_sub_name: false,
            selected: false,
            value_name: "phone"
        },
        {
            id: 5,
            name: "near_by",
            has_sub_name: false,
            selected: false,
            value_name: "near_by"
        },
        {
            id: 6,
            name: "location_category",
            sub_name: "name",
            has_sub_name: true,
            selected: false,
            value_name: "location_category_id"
        },
        {
            id: 7,
            name: "classification",
            sub_name: "name",
            has_sub_name: true,
            selected: false,
            value_name: "classification_id"
        },
        {
            id: 8,
            name: "category",
            sub_name: "name",
            has_sub_name: true,
            selected: false,
            value_name: "category_id"
        },
        {
            id: 9,
            name: "visit_type",
            sub_name: "name",
            has_sub_name: true,
            selected: false,
            value_name: "visit_type_id"
        },
        {
            id: 10,
            name: "region",
            sub_name: "name",
            has_sub_name: true,
            selected: false,
            value_name: "region_id"
        },
        {
            id: 11,
            name: "latitude",
            has_sub_name: false,
            selected: false,
            value_name: "latitude"
        },
        {
            id: 11,
            name: "longitude",
            has_sub_name: false,
            selected: false,
            value_name: "longitude"
        },
        {
            id: 12,
            name: "has_delivery",
            has_sub_name: false,
            selected: false,
            value_name: "has_delivery"
        },
        {
            id: 13,
            name: "image",
            has_sub_name: false,
            selected: false,
            value_name: "image"
        }
    ]);
    const [loading, setloading] = useState(true);





    useEffect(() => {
        setloading(false)
        return () => {
            console.log("props.visit",props.visit);
        };
    }, [loading, props.showVisitPreview]);


    return (
        
            <Dialog
                isShown={props.showVisitPreview}
                onCloseComplete={() => props.setshowVisitPreview(false)}
                hasHeader={true}
                title="View Visit info"
                shouldCloseOnOverlayClick={false}
                hasFooter={false}
                width={'80%'}

                onConfirm={() => { }}
            >
                {loading ? <></> :
                    <>
                        <Table striped bordered hover>
                           
                            <tbody>

                            <tr>
                                <td style={{fontWeight: 'bold'}}>Point Name </td>
                                <td >{props?.visit?.point?.name}</td>
                                <td style={{fontWeight: 'bold'}}>Visited By </td>
                                <td >{props?.visit?.created_by?.full_name}</td>
                            </tr>
                            <tr>
                                <td style={{fontWeight: 'bold'}}>Visit Start Date time </td>
                                <td >{props?.visit?.end_date}</td>
                                <td style={{fontWeight: 'bold'}}>Visit End Date time </td>
                                <td >{props?.visit?.start_date}</td>
                            </tr>
                            <tr>
                                <td style={{fontWeight: 'bold'}}>is canceled</td>
                                <td>{props?.visit?.canceled?<span style={{padding:5,borderRadius:5,color:'white', backgroundColor:"red"}}>canceled</span>:<span style={{padding:5,borderRadius:5,color:'white', backgroundColor:"green"}}>valid</span>}</td>
                                <td style={{fontWeight: 'bold'}}>Cancelation reason</td>
                                <td>{props?.visit?.cancelation_reason}</td>
                                
                                
                            </tr>
                            <tr>
                                <td style={{fontWeight: 'bold'}}>Note</td>
                                <td colSpan={3}>{props?.visit?.note}</td>
                            </tr>
                                
                            </tbody>
                        </Table>
                        {props && props.visit && props.visit.visit_survey_values && props.visit.visit_survey_values.length>0?
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody>

                                
                                {props.visit.visit_survey_values.map((visitSurveyValue) => {
                                    
                                        return (
                                            <tr>
                                                <td>{visitSurveyValue?.survey_field?.name} </td>
                                                <td >{visitSurveyValue?.value}</td>
                                            </tr>)
                                })}
                                
                            
                            </tbody>
                        </Table>
                        :<p>There aren't any survey values</p>}
                    </>

                }

                <br />
                <br />
                <br />
                <br />


            </Dialog>

    );
};
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',

    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: '100%',
        border: '1px solid #ababab',
        borderRadius: 5,
        padding: 0,
        display: 'flex'
    }),
    container: () => ({

        width: '90%',
        position: 'relative',
        boxSizing: 'border-box',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

export default VisitPreview;
