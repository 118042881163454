import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Pane, Dialog,FilePicker } from 'evergreen-ui';
import axios from "axios";
import Select from "react-select";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
const cookies = new Cookies();
const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
export const PointImportForm = (props) => {
    const [loading, setloading] = useState(false);
    const [object,setobject] = useState(null)
    
    

    const submitForm = async () => {
        
        try {
            if(!object)
            {
                toast.error("please select a file")
                return;
            }
            setloading(true)
            let formdata = new FormData();

            formdata.append("uploaded_file", object)
            console.log(formdata);
                const resourcesResult = await axios({
                    url: Host + "import_customer_collectors_bills_payments",
                    method: "post",
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: formdata
                })
                console.log(resourcesResult);
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success(resourcesResult.data.data)
                        props.setshowPointImportForm(false,true)
                    } else if (resourcesResult.data.status === false) {
                        props.setshowPointImportForm(true,false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setshowPointImportForm(true,false)
                }
                setloading(false)
          
        } catch (error) {

            console.log(error);
            props.setshowPointImportForm(true,false)
            const resourcesResult=error.response            
            if (resourcesResult && resourcesResult.status === 400) {
                console.log(resourcesResult);
                if(typeof resourcesResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        resourcesResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    
                    toast.error(resourcesResult.data.data)
                }
                
                
            }
            else if(resourcesResult && resourcesResult.status === 401) {
             
                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
              
                toast.error("network error")
            }
        }
        
    };


    const onFileChange = (fileList)=>{
        
        if(fileList && fileList.length>0)
        {
            setobject(fileList[0])
        }

    }
    useEffect(() => {
        
      

        
        return () => {

        };
    }, [loading,props.showPointImportForm]);


    return (
        
        <Dialog
                isShown={props.showPointImportForm}
                onCloseComplete={() => props.setshowPointImportForm(false)}
                hasHeader={false}
                shouldCloseOnOverlayClick={false}
                confirmLabel="Save"
                cancelLabel="Cancel"
                onConfirm={() => {submitForm()}}
                >
           
            <h4>Import Points With Geofence</h4>
            {loading ? <div>loading ...</div> :
            <form >
                <div className="form-group">
                    <label htmlFor="file">Select JSON file </label>
                    <FilePicker onChange={(e)=>onFileChange(e)} name="file" width={350} height={24} />
                </div>
                
            </form>}
        </Dialog>

    );
};
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',

    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: '100%',
        border: '1px solid #ababab',
        borderRadius: 5,
        padding: 0,
        display: 'flex'
    }),
    container: () => ({

        width: '80%',
        position: 'relative',
        boxSizing: 'border-box',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

export default PointImportForm;
