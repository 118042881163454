import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { InputLabel } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 160,
        },
    },
};

export default function Dropdown({ users, selected, setSelected }) {
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelected(value);
    };

    return (
        <FormControl sx={{ width: 160 }}>
            <InputLabel id='select-label'>اختر مستخدم</InputLabel>
            <Select value={selected} onChange={handleChange} MenuProps={MenuProps} label='اختر مستخدم' labelId='select-label' id='select'>
                {users.map((user) => (
                    <MenuItem key={user?.id} value={user?.id}>
                        {user?.full_name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
