import * as React from 'react';
import TextField from '@mui/material/TextField';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { useGlobalContext } from '../../../context'

export default function BasicDateRangePicker() {
  // const [value, setValue] = React.useState([null, null]);
  const {fromToDate,setFromToDate} = useGlobalContext()
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormControl  sx={{ml:1}} variant="filled">
        <DateRangePicker          
          startText="From Date"
          endText="To Date"
          value={fromToDate}
          onChange={(newValue) => {
            setFromToDate(newValue);
          }}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField {...startProps} />
              <Box sx={{ mx: 2 }}> To </Box>
              <TextField {...endProps} />
            </React.Fragment>
          )}
        />
      </FormControl>
    </LocalizationProvider>
  );
}