import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { styled, makeStyles, useTheme } from "@material-ui/core/styles";
import MuiDrawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import MuiAppBar from "@material-ui/core/AppBar";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Link, NavLink } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import Tooltip from "@material-ui/core/Tooltip";
import Fade from "@material-ui/core/Fade";
import { Redirect } from "react-router-dom";
import Context from "../../assets/js/context";
import Collapse from "@material-ui/core/Collapse";
import Users from "./Users/Users";
import UserDailyPlans from "./UsersDailyPlans/UserDailyPlans";
import Departments from "./Departments/Departments";
import Districts from "./Districts/Districts";
import Visits from "./Visits/Visit";
import Settlements from "./Settlements/Settlements";
import Collections from "./Settlements/Collections";
import Regions from "./Regions/Regions";
import Surveys from "./Surveys/Surveys";
import Journeys from "./Journeys/Journeys";
import Roles from "./Roles/Roles";
import PointTypes from "./PointTypes/PointTypes";
import Points from "./Points/Points";
import PointRequests from "./PointRequests/PointRequests";
import Dashboard from "./Dashboard/Dashboard";
import Cookies from "universal-cookie";
import Divider from "@material-ui/core/Divider";
import { useSelector, useDispatch } from "react-redux";
import { setForceReload, selectForceReload } from "../reduxStore/forceReloadReducer";
import FollowUpType from "./FollowUpType/FollowUpType";
import FollowUpMethod from "./FollowUpMethod/FollowUpMethod";
import PointFollowUp from "./PointFollowUp/PointFollowUp";
import Host from "../../assets/js/Host";
import { toast } from "react-toastify";
import axios from "axios";

//new sideMenu
import { IconContext } from "react-icons";
import * as IoIcons from "react-icons/io5";
import * as AiIcons from "react-icons/ai";
import * as BsIcons from "react-icons/bs";
import * as MdIcons from "react-icons/md";
import * as GiIcons from "react-icons/gi";
import * as BiIcons from "react-icons/bi";
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import * as GrIcons from "react-icons/gr";
import * as SiIcone from "react-icons/si";
import SurveyBuild from "./Surveys/SurveyBuild";
import FieldList from "./FieldLists/FieldLists";
import FieldListValue from "./FieldListValues/FieldListValues";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";

import PendingActionsIcon from "@mui/icons-material/PendingActions";
import SchedulePointFollowUp from "./SchedulePointFollowUp/SchedulePointFollowUp";
import ErrorPage from "./RedirectsPages/ErrorPage";
import { useCallback } from "react";
import Invoices from "./Invoices/Invoices";
const cookies = new Cookies();
const token = cookies.get("token");
const language = cookies.get("language") ? cookies.get("language") : "ar";

function rendertitile(props) {
    if (props.match.path === "/Users") {
        return <div> Users </div>;
    }

    if (props.match.path === "/user_daily_plans") {
        return <div> User Daily Plans </div>;
    } else if (props.match.path === "/Departments") {
        return <div> Departments </div>;
    } else if (props.match.path === "/PointStatus") {
        return <div> Point Status </div>;
    } else if (props.match.path === "/Points") {
        return <div> Points </div>;
    } else if (props.match.path === "/PointRequests") {
        return <div> PointRequests </div>;
    } else if (props.match.path === "/Districts") {
        return <div> Districts </div>;
    } else if (props.match.path === "/Visits") {
        return <div> Visits </div>;
    } else if (props.match.path === "/Settlements") {
        return <div> Settlements </div>;
    } else if (props.match.path === "/Collections") {
        return <div> Collections </div>;
    } else if (props.match.path === "/Regions") {
        return <div> Regions </div>;
    } else if (props.match.path === "/Surveys") {
        return <div> Surveys </div>;
    } else if (props.match.path === "/Journeys") {
        return <div> Daily Journeys </div>;
    } else if (props.match.path === "/Roles") {
        return <div> Roles </div>;
    } else if (props.match.path === "/Dashboard") {
        return <div> Dashboard </div>;
    } else if (props.match.path === "/Department") {
        return <div> Departments </div>;
    } else if (props.match.path === "/field_lists") {
        return <div> Field Lists </div>;
    } else if (props.match.path === "/field_list_values") {
        return <div> Field Lists </div>;
    } else if (props.match.path === "/follow_up_type") {
        return <div> Follow Up Type </div>;
    } else if (props.match.path === "/follow_up_method") {
        return <div> Follow Up Method </div>;
    } else if (props.match.path === "/point_follow_ups") {
        return <div> Follow ups </div>;
    } else if (props.match.path === "/point_scedule_follow_ups") {
        return <div> Schedule Follow ups </div>;
    }
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}
// const pageLinks=[
//   {

//   }
// ];
const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 60,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: `calc(100% - ${60}px)`,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    appBar: {
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: "#132a3b",
        maxHeight: "64px",
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: drawerWidth,
    },
    title: {
        position: "absolute",
        left: "50%",
    },
    hide: {
        display: "none",
    },
    drawer: {
        // width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        // width: drawerWidth,
        backgroundColor: "#132a3b",
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-start",
    },
    drawerSecondHeader: {
        display: "flex",
        alignItems: "center",
        marginTop: 5,
        paddingRight: "16px",
        paddingLeft: "16px",
        backgroundColor: "#132a3b",
        // necessary for content to be below app bar
        // ...theme.mixins.toolbar,
    },
    content: {
        backgroundColor: "#fff",
        flex: 1,
        // height:'100vh',
        height: ({ height }) => `calc(${height}px - 25px )`,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        // marginLeft: -drawerWidth + 55,
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    listItems: {
        textAlign: "left",
        paddingLeft: "10px",
    },
    item: {
        "&:hover": {
            background: "#041929",
        },
        "&:active": {
            background: "#75CDFF",
        },
    },
    titleDiv: {
        marginLeft: "10px",
        marginRight: "10px",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "baseline",
        justifyContent: "flex-end",
        flex: "1",
    },
    appTitle: {
        [theme.breakpoints.down("md")]: {
            opacity: 0,
        },
    },
}));

export default function AppBarAndContainer(props) {
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
    useEffect(() => {
        function handleResize() {
            // console.log(getWindowDimensions())
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const props1 = {
        height: windowDimensions.height,
        // height: '100vh'
    };
    const classes = useStyles(props1);
    const theme = useTheme();
    const [openMastersMenu, setopenMastersMenu] = React.useState(false);
    const [openSettlementssMenu, setopenSettlementssMenu] = React.useState(false);
    const [openPoint, setOpenPoint] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const forceReload = useSelector(selectForceReload);
    const dispatch = useDispatch();
    const [userRole, setUserRoles] = useState([]);

    var timer;
    const handleDrawerOpen = () => {
        setOpen(true);
        timer = setTimeout(() => {
            dispatch(setForceReload(!forceReload));
        }, 500);
    };
    const handleDrawerClose = () => {
        setOpen(false);

        setopenMastersMenu(false);
        timer = setTimeout(() => {
            dispatch(setForceReload(!forceReload));
        }, 500);
    };
    const renderPage = React.useCallback(
        (props) => {
            let roleName = false;

            userRole &&
                userRole?.length > 0 &&
                userRole?.map((itm) => {
                    if (itm?.id === 4) {
                        roleName = "user";
                    } else if (itm?.id === 5) {
                        roleName = "admin";
                    }
                });

            if (props.match.path === "/Users" && roleName === "admin") {
                return <Users />;
            } else if (props.match.path === "/Users" && roleName !== "admin") {
                return <ErrorPage Code='401' ErrorMessage={"unauthorized access error"} title={"Error"} RedirectPage='/Dashboard' />; //TODO  not authorized page
            } else if (props.match.path == "/build_survey" && roleName === "admin") {
                return <SurveyBuild />;
            } else if (props.match.path === "/build_survey" && roleName !== "admin") {
                return <ErrorPage Code='401' ErrorMessage={"unauthorized access error"} title={"Error"} RedirectPage='/Dashboard' />; //TODO  not authorized page
            } else if (props.match.path === "/user_daily_plans" && roleName === "admin") {
                return <UserDailyPlans />;
            } else if (props.match.path === "/user_daily_plans" && roleName !== "admin") {
                return <ErrorPage Code='401' ErrorMessage={"unauthorized access error"} title={"Error"} RedirectPage='/Dashboard' />; //TODO  not authorized page
            } else if (props.match.path === "/Departments" && roleName === "admin") {
                return <Departments />;
            } else if (props.match.path === "/Departments" && roleName !== "admin") {
                return <ErrorPage Code='401' ErrorMessage={"unauthorized access error"} title={"Error"} RedirectPage='/Dashboard' />; //TODO  not authorized page
            } else if (props.match.path === "/PointStatus" && roleName === "admin") {
                return <PointTypes />;
            } else if (props.match.path === "/PointStatus" && roleName !== "admin") {
                return <ErrorPage Code='401' ErrorMessage={"unauthorized access error"} title={"Error"} RedirectPage='/Dashboard' />; //TODO  not authorized page
            } else if (props.match.path === "/Points" && roleName === "admin") {
                return <Points />;
            } else if (props.match.path === "/Points" && roleName !== "admin") {
                return <ErrorPage Code='401' ErrorMessage={"unauthorized access error"} title={"Error"} RedirectPage='/Dashboard' />; //TODO  not authorized page
            } else if (props.match.path === "/PointRequests" && roleName === "admin") {
                return <PointRequests />;
            } else if (props.match.path === "/PointRequests" && roleName !== "admin") {
                return <ErrorPage Code='401' ErrorMessage={"unauthorized access error"} title={"Error"} RedirectPage='/Dashboard' />; //TODO  not authorized page
            } else if (props.match.path === "/Districts" && roleName === "admin") {
                return <Districts />;
            } else if (props.match.path === "/Districts" && roleName !== "admin") {
                return <ErrorPage Code='401' ErrorMessage={"unauthorized access error"} title={"Error"} RedirectPage='/Dashboard' />; //TODO  not authorized page
            } else if (props.match.path === "/Visits" && roleName === "admin") {
                return <Visits />;
            } else if (props.match.path === "/Visits" && roleName !== "admin") {
                return <ErrorPage Code='401' ErrorMessage={"unauthorized access error"} title={"Error"} RedirectPage='/Dashboard' />; //TODO  not authorized page
            } else if (props.match.path === "/Settlements" && roleName === "admin") {
                return <Settlements />;
            } else if (props.match.path === "/Settlements" && roleName !== "admin") {
                return <ErrorPage Code='401' ErrorMessage={"unauthorized access error"} title={"Error"} RedirectPage='/Dashboard' />; //TODO  not authorized page
            } else if (props.match.path === "/Collections" && roleName === "admin") {
                return <Collections />;
            } else if (props.match.path === "/Collections" && roleName !== "admin") {
                return <ErrorPage Code='401' ErrorMessage={"unauthorized access error"} title={"Error"} RedirectPage='/Dashboard' />; //TODO  not authorized page
            } else if (props.match.path === "/Regions" && roleName === "admin") {
                return <Regions />;
            } else if (props.match.path === "/Regions" && roleName !== "admin") {
                return <ErrorPage Code='401' ErrorMessage={"unauthorized access error"} title={"Error"} RedirectPage='/Dashboard' />; //TODO  not authorized page
            } else if (props.match.path === "/Surveys" && roleName === "admin") {
                return <Surveys />;
            } else if (props.match.path === "/Surveys" && roleName !== "admin") {
                return <ErrorPage Code='401' ErrorMessage={"unauthorized access error"} title={"Error"} RedirectPage='/Dashboard' />; //TODO  not authorized page
            } else if (props.match.path === "/Journeys" && roleName === "admin") {
                return <Journeys />;
            } else if (props.match.path === "/Journeys" && roleName !== "admin") {
                return <ErrorPage Code='401' ErrorMessage={"unauthorized access error"} title={"Error"} RedirectPage='/Dashboard' />; //TODO  not authorized page
            } else if (props.match.path === "/Roles" && roleName === "admin") {
                return <Roles />;
            } else if (props.match.path === "/Roles" && roleName !== "admin") {
                return <ErrorPage Code='401' ErrorMessage={"unauthorized access error"} title={"Error"} RedirectPage='/Dashboard' />; //TODO  not authorized page
            } else if (props.match.path === "/Dashboard" && roleName === "admin") {
                return <Dashboard />;
            } else if (props.match.path === "/Dashboard" && roleName !== "admin") {
                return <ErrorPage Code='401' ErrorMessage={"unauthorized access error"} title={"Error"} RedirectPage='/Dashboard' />; //TODO  not authorized page
            } else if (props.match.path === "/field_lists" && roleName === "admin") {
                return <FieldList />;
            } else if (props.match.path === "/field_lists" && roleName !== "admin") {
                return <ErrorPage Code='401' ErrorMessage={"unauthorized access error"} title={"Error"} RedirectPage='/Dashboard' />; //TODO  not authorized page
            } else if (props.match.path === "/field_list_values" && roleName === "admin") {
                return <FieldListValue />;
            } else if (props.match.path === "/field_list_values" && roleName !== "admin") {
                return <ErrorPage Code='401' ErrorMessage={"unauthorized access error"} title={"Error"} RedirectPage='/Dashboard' />; //TODO  not authorized page
            } else if (props.match.path === "/follow_up_type" && roleName === "admin") {
                return <FollowUpType />;
            } else if (props.match.path === "/follow_up_type" && roleName !== "admin") {
                return <ErrorPage Code='401' ErrorMessage={"unauthorized access error"} title={"Error"} RedirectPage='/Dashboard' />; //TODO  not authorized page
            } else if (props.match.path === "/follow_up_method" && roleName === "admin") {
                return <FollowUpMethod />;
            } else if (props.match.path === "/follow_up_method" && roleName !== "admin") {
                return <ErrorPage Code='401' ErrorMessage={"unauthorized access error"} title={"Error"} RedirectPage='/Dashboard' />; //TODO  not authorized page
            } else if (props.match.path === "/invoices" && (roleName === "admin" || roleName === "user")) {
                return <Invoices />;
            } else if (props.match.path === "/invoices" && (roleName !== "admin" || roleName !== "user")) {
                return <ErrorPage Code='401' ErrorMessage={"unauthorized access error"} title={"Error"} RedirectPage='/Dashboard' />; //TODO  not authorized page
            } else if (props.match.path === "/point_follow_ups" && (roleName === "admin" || roleName === "user")) {
                return <PointFollowUp />;
            } else if (props.match.path === "/point_follow_ups" && (roleName !== "admin" || roleName !== "user")) {
                return <ErrorPage Code='401' ErrorMessage={"unauthorized access error"} title={"Error"} RedirectPage='/Dashboard' />; //TODO  not authorized page
            } else if (props.match.path === "/point_scedule_follow_ups" && (roleName === "admin" || roleName === "user")) {
                return <SchedulePointFollowUp />;
            } else if (props.match.path === "/point_scedule_follow_ups" && (roleName !== "admin" || roleName !== "user")) {
                return <ErrorPage Code='401' ErrorMessage={"unauthorized access error"} title={"Error"} RedirectPage='/Dashboard' />; //TODO  not authorized page
            } else {
                return <ErrorPage Code='404' ErrorMessage={"Not Found Page"} title={"Error"} RedirectPage='/Dashboard' />; //TODO  not authorized page
            }
        },
        [userRole]
    );
    // const renderPage = (props) => {
    //   let roles = []

    //   try {

    //     roles = cookies.get("roles")
    //     roles.push(4000)//profile id ^-^.
    //     // roles=roles?.map(role=>role?.id)
    //   } catch (error) {

    //   }
    //   let page = null
    //    pagesLinks.map((pageLink)=>{

    //     if(window.location.pathname=== pageLink?.to && roles?.includes(pageLink?.id))
    //     {

    //       page =  pageLink?.component
    //     }
    //     else if(window.location.pathname=== pageLink?.to )
    //     {

    //     page = <ErrorPage Code="401" ErrorMessage={t('ERROR_PAGE.ERROR_TITLE_401_ERROR')} title={t('ERROR_PAGE.ERROR_TITLE_401')}RedirectPage="/Dashboard" />//TODO  not authorized page
    //     }

    //   })
    //   if(!page)
    //   {
    //       page = <ErrorPage Code="404" ErrorMessage={t('ERROR_PAGE.ERROR_TITLE_404_ERROR')} title={t('ERROR_PAGE.ERROR_TITLE_404')}RedirectPage="/Dashboard" />//TODO  not found page

    //   }
    //   return page

    // }
    function handleMastersMenuClick() {
        setopenMastersMenu(!openMastersMenu);
        setOpen(true);
    }

    function handleSettlementsMenuClick() {
        setopenSettlementssMenu(!openSettlementssMenu);
        setOpen(true);
    }
    function handleOpenPoitnMenuClick() {
        setOpenPoint(!openPoint);
        setOpen(true);
    }
    const getRolesForUser = async () => {
        try {
            var resourcesResult = await axios({
                url: Host + "user_roles",
                method: "get",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "X-localization": language,
                },
            });
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                    const resources = resourcesResult.data;
                    setUserRoles(resources);
                    console.log("userwerwe", resources);
                } else if (resourcesResult.data.status === false) {
                    toast.error("somethings want wrong");
                }
            } else {
                toast.error("Error owner");
            }
        } catch (error) {
            toast.error("Network Error");
        }
    };
    useEffect(() => {
        getRolesForUser();
        return () => {
            clearTimeout(timer);
        };
    }, []);

    const renderContentOfPage = React.useCallback(() => {
        let roleName = false;

        userRole &&
            userRole?.length > 0 &&
            userRole?.map((itm) => {
                if (itm?.id === 4) {
                    roleName = "user";
                } else if (itm?.id === 5) {
                    roleName = "admin";
                }
            });
        let Array = [];
        if (roleName === "admin") {
            Array.push(
                <NavLink to='/Dashboard' activeClassName='active'>
                    <List className='sidefect' style={{ paddingBottom: 0, paddingTop: 0 }}>
                        <ListItem className={classes.item}>
                            <Tooltip arrow title={open ? "" : "Dashboard"} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement='right'>
                                <div
                                    style={{
                                        width: "30px",
                                        height: "25px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                    <AiIcons.AiOutlineDashboard size={25} color='darkgray' />
                                </div>
                            </Tooltip>
                            <ListItemText>
                                <span
                                    className='sspan'
                                    style={{
                                        fontWeight: "600",
                                        fontSize: "15px",
                                        color: "darkgray",
                                    }}>
                                    Dashboard
                                </span>
                            </ListItemText>
                        </ListItem>
                    </List>
                </NavLink>
            );
        }

        if (roleName == "admin")
            Array.push(
                <NavLink to='/Users' activeClassName='active'>
                    <List className='sidefect' style={{ paddingBottom: 0, paddingTop: 0 }}>
                        <ListItem className={classes.item}>
                            <Tooltip arrow title={open ? "" : "Users"} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement='right'>
                                <div
                                    style={{
                                        width: "30px",
                                        height: "25px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                    <AiIcons.AiOutlineUserSwitch size={25} color='darkgray' />
                                </div>
                            </Tooltip>
                            <ListItemText>
                                <span
                                    className='sspan'
                                    style={{
                                        fontWeight: "600",
                                        fontSize: "15px",
                                        color: "darkgray",
                                    }}>
                                    Users
                                </span>
                            </ListItemText>
                        </ListItem>
                    </List>
                </NavLink>
            );

        if (roleName == "admin")
            Array.push(
                <NavLink to='/Visits' activeClassName='active'>
                    <List className='sidefect' style={{ paddingBottom: 0, paddingTop: 0 }}>
                        <ListItem className={classes.item}>
                            <Tooltip arrow title={open ? "" : "Visits"} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement='right'>
                                <div
                                    style={{
                                        width: "30px",
                                        height: "25px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                    <FaIcons.FaRegHandshake size={25} color='darkgray' />
                                </div>
                            </Tooltip>
                            <ListItemText>
                                <span
                                    className='sspan'
                                    style={{
                                        fontWeight: "600",
                                        fontSize: "15px",
                                        color: "darkgray",
                                    }}>
                                    Visits{" "}
                                </span>
                            </ListItemText>
                        </ListItem>
                    </List>
                </NavLink>
            );

        if (roleName == "admin")
            Array.push(
                <NavLink to='/PointRequests' activeClassName='active'>
                    <List className='sidefect' style={{ paddingBottom: 0, paddingTop: 0 }}>
                        <ListItem className={classes.item}>
                            <Tooltip arrow title={open ? "" : "PointRequests"} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement='right'>
                                <div
                                    style={{
                                        width: "30px",
                                        height: "25px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                    <BiIcons.BiGitPullRequest size={25} color='darkgray' />
                                </div>
                            </Tooltip>
                            <ListItemText>
                                <span
                                    className='sspan'
                                    style={{
                                        fontWeight: "600",
                                        fontSize: "15px",
                                        color: "darkgray",
                                    }}>
                                    Point Requests
                                </span>
                            </ListItemText>
                        </ListItem>
                    </List>
                </NavLink>
            );

        if (roleName == "admin")
            Array.push(
                <NavLink to='/Regions' activeClassName='active'>
                    <List className='sidefect' style={{ paddingBottom: 0, paddingTop: 0 }}>
                        <ListItem className={classes.item}>
                            <Tooltip arrow title={open ? "" : "Regions"} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement='right'>
                                <div
                                    style={{
                                        width: "30px",
                                        height: "25px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                    <RiIcons.RiTreasureMapLine size={25} color='darkgray' />
                                </div>
                            </Tooltip>
                            <ListItemText>
                                <span
                                    className='sspan'
                                    style={{
                                        fontWeight: "600",
                                        fontSize: "15px",
                                        color: "darkgray",
                                    }}>
                                    Regions
                                </span>
                            </ListItemText>
                        </ListItem>
                    </List>
                </NavLink>
            );

        if (roleName == "admin")
            Array.push(
                <NavLink to='/Surveys' activeClassName='active'>
                    <List className='sidefect' style={{ paddingBottom: 0, paddingTop: 0 }}>
                        <ListItem className={classes.item}>
                            <Tooltip arrow title={open ? "" : "Surveys"} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement='right'>
                                <div
                                    style={{
                                        width: "30px",
                                        height: "25px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                    <RiIcons.RiSurveyLine size={25} color='darkgray' />
                                </div>
                            </Tooltip>
                            <ListItemText>
                                <span
                                    className='sspan'
                                    style={{
                                        fontWeight: "600",
                                        fontSize: "15px",
                                        color: "darkgray",
                                    }}>
                                    Surveys
                                </span>
                            </ListItemText>
                        </ListItem>
                    </List>
                </NavLink>
            );

        if (roleName == "admin")
            Array.push(
                <NavLink to='/Journeys' activeClassName='active'>
                    <List className='sidefect' style={{ paddingBottom: 0, paddingTop: 0 }}>
                        <ListItem className={classes.item}>
                            <Tooltip arrow title={open ? "" : "Journeys"} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement='right'>
                                <div
                                    style={{
                                        width: "30px",
                                        height: "25px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                    <GiIcons.GiJourney size={25} color='darkgray' />
                                </div>
                            </Tooltip>
                            <ListItemText>
                                <span
                                    className='sspan'
                                    style={{
                                        fontWeight: "600",
                                        fontSize: "15px",
                                        color: "darkgray",
                                    }}>
                                    Journeys
                                </span>
                            </ListItemText>
                        </ListItem>
                    </List>
                </NavLink>
            );

        {
            /* STARTING points routes */
        }
        if (roleName === "admin" || roleName === "user")
            Array.push(
                <List className='sidefect' style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <ListItem className={classes.item} button onClick={handleOpenPoitnMenuClick}>
                        <Tooltip arrow title={open ? "" : "Settlements"} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement='right'>
                            <div
                                style={{
                                    width: "30px",
                                    height: "25px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                <GiIcons.GiTakeMyMoney size={25} color='darkgray' />
                            </div>
                        </Tooltip>
                        <ListItemText>
                            <span
                                className='sspan'
                                style={{
                                    fontWeight: "600",
                                    fontSize: "15px",
                                    color: "darkgray",
                                }}>
                                Points
                            </span>
                        </ListItemText>
                        {openPoint ? <MdIcons.MdOutlineExpandLess size={25} color='darkgray' /> : <MdIcons.MdOutlineExpandMore size={25} color='darkgray' />}
                    </ListItem>

                    <Collapse in={openPoint} timeout='auto' unmountOnExit>
                        <List component='div' style={{ paddingLeft: "20px" }}>
                            {roleName === "admin" ? (
                                <NavLink to='/Points' activeClassName='active'>
                                    <List className='sidefect' style={{ paddingBottom: 0, paddingTop: 0 }}>
                                        <ListItem className={classes.item}>
                                            <Tooltip
                                                arrow
                                                title={open ? "" : "Points"}
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 600 }}
                                                placement='right'>
                                                <div
                                                    style={{
                                                        width: "30px",
                                                        height: "25px",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}>
                                                    <BiIcons.BiCurrentLocation size={25} color='darkgray' />
                                                </div>
                                            </Tooltip>
                                            <ListItemText>
                                                <span
                                                    className='sspan'
                                                    style={{
                                                        fontWeight: "600",
                                                        fontSize: "15px",
                                                        color: "darkgray",
                                                    }}>
                                                    Points
                                                </span>
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </NavLink>
                            ) : null}
                            {roleName == "admin" || roleName == "user" ? (
                                <NavLink to='/point_follow_ups' activeClassName='active'>
                                    <List className='sidefect' style={{ paddingBottom: 0, paddingTop: 0 }}>
                                        <ListItem className={classes.item}>
                                            <Tooltip
                                                arrow
                                                title={open ? "" : "Point FollowUps"}
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 600 }}
                                                placement='right'>
                                                <div
                                                    style={{
                                                        width: "30px",
                                                        height: "25px",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}>
                                                    <SiIcone.SiSitepoint size={25} color='darkgray' />
                                                </div>
                                            </Tooltip>
                                            <ListItemText>
                                                <span
                                                    className='sspan'
                                                    style={{
                                                        fontWeight: "600",
                                                        fontSize: "15px",
                                                        color: "darkgray",
                                                    }}>
                                                    FollowUps
                                                </span>
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </NavLink>
                            ) : null}
                            {roleName == "admin" || roleName == "user" ? (
                                <NavLink to='/point_scedule_follow_ups' activeClassName='active'>
                                    <List className='sidefect' style={{ paddingBottom: 0, paddingTop: 0 }}>
                                        <ListItem className={classes.item}>
                                            <Tooltip
                                                arrow
                                                title={open ? "" : "Point FollowUps"}
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 600 }}
                                                placement='right'>
                                                <div
                                                    style={{
                                                        width: "30px",
                                                        height: "25px",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}>
                                                    <PendingActionsIcon size={25} sx={{ color: "darkgray" }} />
                                                </div>
                                            </Tooltip>
                                            <ListItemText>
                                                <span
                                                    className='sspan'
                                                    style={{
                                                        fontWeight: "600",
                                                        fontSize: "15px",
                                                        color: "darkgray",
                                                    }}>
                                                    Schedule FollowUps
                                                </span>
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </NavLink>
                            ) : null}
                            {roleName == "admin" || roleName == "user" ? (
                                <NavLink to='/invoices' activeClassName='active'>
                                    <List className='sidefect' style={{ paddingBottom: 0, paddingTop: 0 }}>
                                        <ListItem className={classes.item}>
                                            <Tooltip
                                                arrow
                                                title={open ? "" : "Invoices"}
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 600 }}
                                                placement='right'>
                                                <div
                                                    style={{
                                                        width: "30px",
                                                        height: "25px",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}>
                                                    <FaIcons.FaFileInvoiceDollar size={25} color='darkgray' />
                                                </div>
                                            </Tooltip>
                                            <ListItemText>
                                                <span
                                                    className='sspan'
                                                    style={{
                                                        fontWeight: "600",
                                                        fontSize: "15px",
                                                        color: "darkgray",
                                                    }}>
                                                    Invoices
                                                </span>
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </NavLink>
                            ) : null}
                        </List>
                    </Collapse>
                </List>
            );

        {
            /* STARTING Settlements routes */
        }
        if (roleName === "admin")
            Array.push(
                <List className='sidefect' style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <ListItem className={classes.item} button onClick={handleSettlementsMenuClick}>
                        <Tooltip arrow title={open ? "" : "Settlements"} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement='right'>
                            <div
                                style={{
                                    width: "30px",
                                    height: "25px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                <GiIcons.GiTakeMyMoney size={25} color='darkgray' />
                            </div>
                        </Tooltip>
                        <ListItemText>
                            <span
                                className='sspan'
                                style={{
                                    fontWeight: "600",
                                    fontSize: "15px",
                                    color: "darkgray",
                                }}>
                                Settlements
                            </span>
                        </ListItemText>
                        {openSettlementssMenu ? (
                            <MdIcons.MdOutlineExpandLess size={25} color='darkgray' />
                        ) : (
                            <MdIcons.MdOutlineExpandMore size={25} color='darkgray' />
                        )}
                    </ListItem>

                    <Collapse in={openSettlementssMenu} timeout='auto' unmountOnExit>
                        <List component='div' style={{ paddingLeft: "20px" }}>
                            {roleName === "admin" ? (
                                <NavLink to='/Settlements' activeClassName='active'>
                                    <List className='sidefect' style={{ paddingBottom: 0, paddingTop: 0 }}>
                                        <ListItem className={classes.item}>
                                            <Tooltip
                                                arrow
                                                title={open ? "" : "Settlements"}
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 600 }}
                                                placement='right'>
                                                <div
                                                    style={{
                                                        width: "30px",
                                                        height: "25px",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}>
                                                    <GiIcons.GiReceiveMoney size={25} color='darkgray' />
                                                </div>
                                            </Tooltip>
                                            <ListItemText>
                                                <span
                                                    className='sspan'
                                                    style={{
                                                        fontWeight: "600",
                                                        fontSize: "15px",
                                                        color: "darkgray",
                                                    }}>
                                                    Settlements
                                                </span>
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </NavLink>
                            ) : null}

                            {roleName === "admin" ? (
                                <NavLink to='/Collections' activeClassName='active'>
                                    <List className='sidefect' style={{ paddingBottom: 0, paddingTop: 0 }}>
                                        <ListItem className={classes.item}>
                                            <Tooltip
                                                arrow
                                                title={open ? "" : "Collections"}
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 600 }}
                                                placement='right'>
                                                <div
                                                    style={{
                                                        width: "30px",
                                                        height: "25px",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}>
                                                    <GiIcons.GiPayMoney size={25} color='darkgray' />
                                                </div>
                                            </Tooltip>
                                            <ListItemText>
                                                <span
                                                    className='sspan'
                                                    style={{
                                                        fontWeight: "600",
                                                        fontSize: "15px",
                                                        color: "darkgray",
                                                    }}>
                                                    Collections
                                                </span>
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </NavLink>
                            ) : null}
                        </List>
                    </Collapse>
                </List>
            );

        {
            /* STARTING SUB LISTS OPTIONS */
        }

        if (roleName === "admin")
            Array.push(
                <List className='sidefect' style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <ListItem className={classes.item} button onClick={handleMastersMenuClick}>
                        <Tooltip arrow title={open ? "" : "Tasks"} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement='right'>
                            <div
                                style={{
                                    width: "30px",
                                    height: "25px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                <IoIcons.IoAlbumsOutline size={25} color='darkgray' />
                            </div>
                        </Tooltip>
                        <ListItemText>
                            <span
                                className='sspan'
                                style={{
                                    fontWeight: "600",
                                    fontSize: "15px",
                                    color: "darkgray",
                                }}>
                                Masters
                            </span>
                        </ListItemText>
                        {openMastersMenu ? (
                            <MdIcons.MdOutlineExpandLess size={25} color='darkgray' />
                        ) : (
                            <MdIcons.MdOutlineExpandMore size={25} color='darkgray' />
                        )}
                    </ListItem>

                    <Collapse in={openMastersMenu} timeout='auto' unmountOnExit>
                        <List component='div' style={{ paddingLeft: "20px" }}>
                            {roleName === "admin" ? (
                                <NavLink to='/Roles' activeClassName='active'>
                                    <List className='sidefect' style={{ paddingBottom: 0, paddingTop: 0 }}>
                                        <ListItem className={classes.item}>
                                            <Tooltip
                                                arrow
                                                title={open ? "" : "Roles"}
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 600 }}
                                                placement='right'>
                                                <div
                                                    style={{
                                                        width: "30px",
                                                        height: "25px",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}>
                                                    <RiIcons.RiShieldUserLine size={25} color='darkgray' />
                                                </div>
                                            </Tooltip>
                                            <ListItemText>
                                                <span
                                                    className='sspan'
                                                    style={{
                                                        fontWeight: "600",
                                                        fontSize: "15px",
                                                        color: "darkgray",
                                                    }}>
                                                    Roles
                                                </span>
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </NavLink>
                            ) : null}

                            {roleName === "admin" ? (
                                <NavLink to='/Departments' activeClassName='active'>
                                    <List className='sidefect' style={{ paddingBottom: 0, paddingTop: 0 }}>
                                        <ListItem className={classes.item}>
                                            <Tooltip
                                                arrow
                                                title={open ? "" : "Departments"}
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 600 }}
                                                placement='right'>
                                                <div
                                                    style={{
                                                        width: "30px",
                                                        height: "25px",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}>
                                                    <MdIcons.MdOutlineCategory size={25} color='darkgray' />
                                                </div>
                                            </Tooltip>
                                            <ListItemText>
                                                <span
                                                    className='sspan'
                                                    style={{
                                                        fontWeight: "600",
                                                        fontSize: "15px",
                                                        color: "darkgray",
                                                    }}>
                                                    Departments
                                                </span>
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </NavLink>
                            ) : null}
                            {roleName === "admin" ? (
                                <NavLink to='/Districts' activeClassName='active'>
                                    <List className='sidefect' style={{ paddingBottom: 0, paddingTop: 0 }}>
                                        <ListItem className={classes.item}>
                                            <Tooltip
                                                arrow
                                                title={open ? "" : "Districts"}
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 600 }}
                                                placement='right'>
                                                <div
                                                    style={{
                                                        width: "30px",
                                                        height: "25px",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}>
                                                    <BiIcons.BiWorld size={25} color='darkgray' />
                                                </div>
                                            </Tooltip>
                                            <ListItemText>
                                                <span
                                                    className='sspan'
                                                    style={{
                                                        fontWeight: "600",
                                                        fontSize: "15px",
                                                        color: "darkgray",
                                                    }}>
                                                    Districts
                                                </span>
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </NavLink>
                            ) : null}
                            {roleName === "admin" ? (
                                <NavLink to='/field_lists' activeClassName='active'>
                                    <List className='sidefect' style={{ paddingBottom: 0, paddingTop: 0 }}>
                                        <ListItem className={classes.item}>
                                            <Tooltip
                                                arrow
                                                title={open ? "" : "field_lists"}
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 600 }}
                                                placement='right'>
                                                <div
                                                    style={{
                                                        width: "30px",
                                                        height: "25px",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}>
                                                    <BiIcons.BiWorld size={25} color='darkgray' />
                                                </div>
                                            </Tooltip>
                                            <ListItemText>
                                                <span
                                                    className='sspan'
                                                    style={{
                                                        fontWeight: "600",
                                                        fontSize: "15px",
                                                        color: "darkgray",
                                                    }}>
                                                    Field lists
                                                </span>
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </NavLink>
                            ) : null}
                            {roleName === "admin" ? (
                                <NavLink to='/field_list_values' activeClassName='active'>
                                    <List className='sidefect' style={{ paddingBottom: 0, paddingTop: 0 }}>
                                        <ListItem className={classes.item}>
                                            <Tooltip
                                                arrow
                                                title={open ? "" : "field_list_values"}
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 600 }}
                                                placement='right'>
                                                <div
                                                    style={{
                                                        width: "30px",
                                                        height: "25px",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}>
                                                    <BiIcons.BiWorld size={25} color='darkgray' />
                                                </div>
                                            </Tooltip>
                                            <ListItemText>
                                                <span
                                                    className='sspan'
                                                    style={{
                                                        fontWeight: "600",
                                                        fontSize: "15px",
                                                        color: "darkgray",
                                                    }}>
                                                    Field list values
                                                </span>
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </NavLink>
                            ) : null}

                            {roleName === "admin" ? (
                                <NavLink to='/PointStatus' activeClassName='active'>
                                    <List className='sidefect' style={{ paddingBottom: 0, paddingTop: 0 }}>
                                        <ListItem className={classes.item}>
                                            <Tooltip
                                                arrow
                                                title={open ? "" : "Point Status"}
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 600 }}
                                                placement='right'>
                                                <div
                                                    style={{
                                                        width: "30px",
                                                        height: "25px",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}>
                                                    <AiIcons.AiOutlineTag size={25} color='darkgray' />
                                                </div>
                                            </Tooltip>
                                            <ListItemText>
                                                <span
                                                    className='sspan'
                                                    style={{
                                                        fontWeight: "600",
                                                        fontSize: "15px",
                                                        color: "darkgray",
                                                    }}>
                                                    Point Types
                                                </span>
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </NavLink>
                            ) : null}
                            {roleName === "admin" ? (
                                <NavLink to='/follow_up_type' activeClassName='active'>
                                    <List className='sidefect' style={{ paddingBottom: 0, paddingTop: 0 }}>
                                        <ListItem className={classes.item}>
                                            <Tooltip
                                                arrow
                                                title={open ? "" : "Follow Up Type"}
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 600 }}
                                                placement='right'>
                                                <div
                                                    style={{
                                                        width: "30px",
                                                        height: "25px",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}>
                                                    <FollowTheSignsIcon size={25} sx={{ color: "darkgray" }} />
                                                </div>
                                            </Tooltip>
                                            <ListItemText>
                                                <span
                                                    className='sspan'
                                                    style={{
                                                        fontWeight: "600",
                                                        fontSize: "15px",
                                                        color: "darkgray",
                                                    }}>
                                                    Follow Up Type
                                                </span>
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </NavLink>
                            ) : null}
                            {roleName === "admin" ? (
                                <NavLink to='/follow_up_method' activeClassName='active'>
                                    <List className='sidefect' style={{ paddingBottom: 0, paddingTop: 0 }}>
                                        <ListItem className={classes.item}>
                                            <Tooltip
                                                arrow
                                                title={open ? "" : "Follow Up Method"}
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 600 }}
                                                placement='right'>
                                                <div
                                                    style={{
                                                        width: "30px",
                                                        height: "25px",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}>
                                                    <AiIcons.AiOutlineGateway size={25} color='darkgray' />
                                                </div>
                                            </Tooltip>
                                            <ListItemText>
                                                <span
                                                    className='sspan'
                                                    style={{
                                                        fontWeight: "600",
                                                        fontSize: "15px",
                                                        color: "darkgray",
                                                    }}>
                                                    Follow Up Method
                                                </span>
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </NavLink>
                            ) : null}
                        </List>
                    </Collapse>
                </List>
            );
        return Array;
    }, [userRole, openPoint, openMastersMenu, openSettlementssMenu]);

    return (
        <Context.Consumer>
            {(ctx) => {
                if (ctx.value.che === "notlogin") {
                    return <Redirect to='/'></Redirect>;
                } else if (ctx.value.che === "login") {
                    return (
                        <div className={classes.root}>
                            <CssBaseline />
                            <AppBar
                                id='abr'
                                position='fixed'
                                className={clsx(classes.appBar, {
                                    [classes.appBarShift]: open,
                                })}>
                                <Navbar expand='lg' id='navmain' style={{ backgroundColor: "#132a3b" }}>
                                    <IconButton
                                        color='inherit'
                                        aria-label='open drawer'
                                        onClick={handleDrawerOpen}
                                        edge='start'
                                        className={clsx(classes.menuButton, open && classes.hide)}>
                                        <BsIcons.BsFillArrowRightSquareFill
                                            size={25}
                                            color='darkgray'
                                            style={{ cursor: "pointer" }}
                                            onClick={handleDrawerOpen}
                                        />
                                    </IconButton>

                                    <div id='sarnavimg44'>
                                        <div id='nav_title' style={{ fontWeight: "700", fontSize: 20 }}>
                                            {" "}
                                            {rendertitile(props)}{" "}
                                        </div>

                                        <div
                                            id='ss'
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}>
                                            <div>{cookies.get("full_name")}</div>
                                        </div>
                                    </div>
                                </Navbar>
                            </AppBar>

                            <Drawer
                                // className={classes.drawer}
                                variant='permanent'
                                anchor='left'
                                open={open}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}>
                                <div>
                                    <div>
                                        {open ? (
                                            <div
                                                style={{
                                                    width: "auto",
                                                    height: "65px",
                                                    display: "flex",
                                                    flexDirection: "row-reverse",
                                                }}>
                                                <div style={{ width: "auto", textAlign: "right" }}>
                                                    <BsIcons.BsFillArrowLeftSquareFill
                                                        size={25}
                                                        color='darkgray'
                                                        style={{
                                                            margin: 5,
                                                            marginTop: 20,
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={handleDrawerClose}
                                                    />
                                                </div>
                                                <div
                                                    className='logo-large'
                                                    style={{
                                                        width: "75%",
                                                        height: "55px",
                                                        marginTop: 6,
                                                        paddingTop: 15,
                                                    }}>
                                                    <img src={require("../../assets/img/logo_full.png")} alt='img' style={{ height: 65, borderRadius: 10 }} />
                                                </div>
                                            </div>
                                        ) : (
                                            <div
                                                style={{
                                                    width: "auto",
                                                    height: "65px",
                                                    display: "flex",
                                                    flexDirection: "row-reverse",
                                                }}>
                                                <div
                                                    className='logo-large'
                                                    style={{
                                                        width: "100%",
                                                        height: "55px",
                                                        marginTop: 6,
                                                    }}>
                                                    <img src={require("../../assets/img/logosmall.png")} alt='img' style={{ height: 55, borderRadius: 10 }} />
                                                </div>
                                            </div>
                                        )}

                                        <Divider
                                            style={{
                                                marginBottom: 1,
                                                marginTop: 2,
                                                backgroundColor: "darkgray",
                                            }}
                                        />

                                        {renderContentOfPage() && renderContentOfPage()?.length > 0 && renderContentOfPage()?.map((itm) => itm)}

                                        <Divider
                                            style={{
                                                marginBottom: 10,
                                                marginTop: 10,
                                                backgroundColor: "darkgray",
                                            }}
                                        />

                                        <NavLink
                                            onClick={() => {
                                                cookies.remove("token");
                                            }}
                                            to='/'>
                                            <List style={{ paddingBottom: 0, paddingTop: 0 }}>
                                                <ListItem className={classes.item}>
                                                    <Tooltip
                                                        arrow
                                                        title={open ? "" : "Sign Out"}
                                                        TransitionComponent={Fade}
                                                        TransitionProps={{ timeout: 600 }}
                                                        placement='right'>
                                                        <div
                                                            style={{
                                                                width: "30px",
                                                                height: "25px",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}>
                                                            <IoIcons.IoLogOutOutline size={25} color='darkgray' />
                                                        </div>
                                                    </Tooltip>
                                                    <ListItemText>
                                                        <span
                                                            className='sspan'
                                                            style={{
                                                                fontWeight: "600",
                                                                fontSize: "15px",
                                                                color: "darkgray",
                                                            }}>
                                                            Sign Out
                                                        </span>
                                                    </ListItemText>
                                                </ListItem>
                                            </List>
                                        </NavLink>
                                    </div>
                                </div>
                            </Drawer>

                            <main
                                className={clsx(classes.content, {
                                    [classes.contentShift]: open,
                                })}>
                                <div className={classes.drawerHeader} />

                                {renderPage(props)}
                            </main>
                        </div>
                    );
                } else if (ctx.value.che === "") {
                    return (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                            }}>
                            {/* <Lottie
                 options={{
                   animationData: animation,
                 }}
                width={300}
                height={300}
               /> */}
                        </div>
                    );
                }
            }}
        </Context.Consumer>
    );
}
