import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Pane, Dialog, Textarea, FilePicker } from 'evergreen-ui';
import axios from "axios";
import Select from "react-select";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import { Button, Table } from "react-bootstrap";
import { Avatar } from "evergreen-ui"
import helpers from "../../../assets/js/helper";
import { Divider } from "@material-ui/core";
import APP_CONSTANTS from "../../../assets/js/constants";
const cookies = new Cookies();
const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
export const SettlementPreview = (props) => {

   
    const [loading, setloading] = useState(true);





    useEffect(() => {
        setloading(false)
        return () => {
            console.log("props.settlement",props.settlement);
        };
    }, [loading, props.showSettlementPreview]);


    return (
        
            <Dialog
                isShown={props.showSettlementPreview}
                onCloseComplete={() => props.setshowSettlementPreview(false)}
                hasHeader={true}
                title="View Settlement info"
                shouldCloseOnOverlayClick={false}
                hasFooter={false}
                width={'80%'}

                onConfirm={() => { }}
            >
                {loading ? <></> :
                    <>
                        <Table striped bordered hover>
                           
                            <tbody>

                            <tr>
                                <td style={{fontWeight: 'bold'}}>Settlement ID </td>
                                <td >{props?.settlement?.id}</td>
                                <td style={{fontWeight: 'bold'}}>Settlement Date </td>
                                <td >{props?.settlement?.created_at}</td>
                            </tr>
                            <tr>
                                <td style={{fontWeight: 'bold'}}>Settlement for user </td>
                                <td >{props?.settlement?.settlement_for_user?.full_name}</td>
                                <td style={{fontWeight: 'bold'}}>Settlement made By </td>
                                <td >{props?.settlement?.settlement_by_user?.full_name}</td>
                            </tr>
                            
                                
                            </tbody>
                        </Table>
                        {props && props.settlement && props.settlement.settlement_amounts && props.settlement.settlement_amounts.length>0?
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Currency</th>
                                    <th>Total Amount</th>
                                </tr>
                            </thead>
                            <tbody>

                                
                                {props.settlement.settlement_amounts.map((settlement_amount) => {
                                    
                                        return (
                                            <tr>
                                                <td>{settlement_amount?.currency?.name} </td>
                                                <td >{settlement_amount?.amount}</td>
                                            </tr>)
                                })}
                                
                            
                            </tbody>
                        </Table>
                        :<p>Amount details not available</p>}
                        {props && props.settlement && props.settlement.settlement_details && props.settlement.settlement_details.length>0?
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Customer Name</th>
                                    <th>Type</th>
                                    <th>USD</th>
                                    <th>IQD</th>
                                    <th>Note</th>

                                </tr>
                            </thead>
                            <tbody>
                                {props.settlement.settlement_details.map((settlement_detail) => {
                                    return (
                                        <tr>
                                            <td>{settlement_detail?.visit?.end_date} </td>
                                            <td>{settlement_detail?.visit?.point?.name}</td>
                                            <td>{settlement_detail?.visit?.visit_survey_values?.length>0?settlement_detail?.visit?.visit_survey_values[0].value:null}</td>
                                            <td>{settlement_detail?.visit?.visit_survey_values?.length>1?settlement_detail?.visit?.visit_survey_values[1].value:null}</td>
                                            <td>{settlement_detail?.visit?.visit_survey_values?.length>2?settlement_detail?.visit?.visit_survey_values[2].value:null}</td>
                                            <td>{settlement_detail?.visit?.visit_survey_values?.length>3?settlement_detail?.visit?.visit_survey_values[3].value:null}</td>
                                        </tr>
                                    )
                                })}
                                
                            
                            </tbody>
                        </Table>
                        :<p>The settlement details are not available</p>}
                    </>

                }

                <br />
                <br />
                <br />
                <br />


            </Dialog>

    );
};
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',

    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: '100%',
        border: '1px solid #ababab',
        borderRadius: 5,
        padding: 0,
        display: 'flex'
    }),
    container: () => ({

        width: '90%',
        position: 'relative',
        boxSizing: 'border-box',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

export default SettlementPreview;
