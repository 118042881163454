import React, { useEffect, useState } from "react";
// import Pagination from "react-js-pagination";
import Cookies from "universal-cookie";
import axios from "axios";
import { Avatar } from "evergreen-ui";
import Host from "../../../assets/js/Host";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import SchedulePointFollowUpForm from "./SchedulePointFollowUpForm";
// import VisitMapDialog from './VisitMapDialog';
import host from "../../../assets/js/Host";
import SchedulePointFollowUpPreivew from "./SchedulePointFollowUpPreivew";
// import VisitMapLocation from './VisitMapLocation';
import IconButton from "@material-ui/core/IconButton";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import BasicDateRangePicker from "../GeneralComponents/DateFromTo";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import CircularIndeterminate from "../GeneralComponents/CirularProgress";
import StyledDataGrid from "../GeneralComponents/StyledDataGrid";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import { useGlobalContext } from "../../../context";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import EditIcon from "@mui/icons-material/Edit";

import "../../../assets/css/DG.css";
import "../../../assets/css/AccordionStyle.css";
import SearchField1 from "../GeneralComponents/SearchField1";
import FollowUpMethod from "../FollowUpMethod/FollowUpMethod";
import AddIcon from "@mui/icons-material/Add";

const cookies = new Cookies();
const token = cookies.get("token");
const language = cookies.get("language") ? cookies.get("language") : "ar";
// const cookies = new Cookies();
// const token = cookies.get("token")
// const language = cookies.get("language")?cookies.get("language"):"ar"
function SchedulePointFollowUp() {
    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setpageNumber] = useState(1);
    const [pointFollowUpTotal, setpointFollowUpTotal] = useState(0);
    const [pointsFollowUpNumberOfPage, setpointsFollowUpNumberOfPage] = useState(0);

    const {
        loading,
        exportedPointFollowUps,
        followUpTypes,
        followUpMethods,
        setLoading,
        pointFollowUps,
        fromToDate,
        pointsStatuses,
        pointStatus,
        setPointStatus,
        exportToCSV,
        allPoints,
        user,
        users,
        setUser,
        points,
        district,
        region,
        districts,
        regions,
        ownerName,
        shopName,
        clearPointsAdvanceSearchFields,
        setRegion,
        setDistrict,
        setShopName,
        setOwnerName,
        fetchData,
    } = useGlobalContext();

    const [selectedFollowUpType, setSelectedFollowUpType] = useState(null);
    const [selectedFollowUpMethod, setSelectedFollowUpMethod] = useState(null);
    const [selectedPoint, setSelectedPoint] = useState(null);
    const [details, setDetails] = useState("");
    const [scheduleDate, setScheduleDate] = useState(null);
    const [createdBy, setCreatedBy] = useState(null);
    const [fromDate, setFormDate] = useState(null);
    const [toDate, setTODate] = useState(null);

    const formatDate = (date) => {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    };

    // const [loading, setLoading] = useState(false);
    // const [state, setData] = useState({
    //     visits: ''
    // });
    const [showPointFollowUpForm, setshowPointFollowUpForm] = useState(false);
    const [showPointFollowUpDialog, setshowPointFollowUpDialog] = useState(false);
    const [showPointFollowUpPreview, setshowPointFollowUpPreview] = useState(false);
    const [selectedPointFollowUp, setselectedPointFollowUp] = useState(null);
    const [accExpansion, setAccExpansion] = useState(false);

    const handleExpansion = (expansion) => setAccExpansion(expansion);

    const handlePageChange = (newPage) => {
        setpageNumber(newPage + 1);
    };

    const advanceSearch = async (type = "BROWSING") => {
        let data = {
            page: type === "SEARCH" ? 1 : pageNumber,
            page_size: pageSize,
            follow_up_type_id: selectedFollowUpType?.id,
            created_by_id: user.id,
            follow_up_method_id: selectedFollowUpMethod?.id,
            point_id: selectedPoint?.id,
            details: details,
            schedule_Active: "active",
            // schedule_date:moment(scheduleDate)?.format('YYYY-MM-DD'),
            // creation_date_from:moment(fromDate)?.format('YYYY-MM-DD'),
            // creation_date_to:moment(toDate)?.format('YYYY-MM-DD'),
        };
        if (scheduleDate) {
            data = {
                ...data,
                schedule_date: formatDate(scheduleDate),
            };
        }
        if (fromDate) {
            data = {
                ...data,
                creation_date_from: formatDate(fromDate),
            };
        }
        if (toDate) {
            data = {
                ...data,
                creation_date_to: formatDate(toDate),
            };
        }
        if (details) {
            data = {
                ...data,
                details: details,
            };
        }
        //if(selectedFollowUpType){
        //     data={
        //         ...data,
        //         follow_up_type_id:selectedFollowUpType?.id
        //     }
        // } if(selectedFollowUpMethod&&selectedFollowUpMethod?.id){
        //     data={
        //         ...data,
        //         follow_up_method_id:selectedFollowUpMethod?.id,

        //     }
        // } if(selectedPoint&&selectedPoint?.id){
        //     data={
        //         ...data,
        //         point_id:selectedPoint?.id,

        //     }
        // } if(user&&user?.id){
        //     data={
        //         ...data,
        //         follow_up_type_id:user?.id,

        //     }
        // }

        const fetchedData = await fetchData("POINT_FOLLOW_UPS", "point_follow_ups", {
            ...data,
        });
        return fetchedData;
    };

    // const fetchData = async (pageNumber = 1) => {
    //     setLoading(true)
    //     try {
    //         const result=await axios({
    //             url: Host + "visits?page="+pageNumber,
    //             method: "get",
    //             headers: {
    //             "Authorization" : `Bearer ${token}`,
    //                 Accept:"application/json",
    //                 'X-localization':language,
    //             }

    //         })
    //         if(result && result.status == 200)
    //         {
    //             var visits=result.data ;

    //             if(visits &&visits.length>0)
    //             {
    //                 visits=visits.map((visit)=>{
    //                     const thumbnail_image_url=getImageUrl(visit?.profile_image[0]?.thumbnail_image_url)
    //                     const image_url=getImageUrl(visit?.profile_image[0]?.image_url)
    //                     return {...visit,
    //                         profile_image:[{...visit?.profile_image[0],
    //                             thumbnail_image_url:thumbnail_image_url,
    //                             image_url:image_url}]}

    //                 })
    //             }

    //             setData({
    //                 visits:visits
    //             });
    //         } else if (result.data.status === false) {
    //             toast.error("Error")
    //         }
    //     } catch (error) {
    //         const responseResult=error.response
    //         if (responseResult && responseResult.status === 400) {
    //             if(typeof responseResult.data.data === "object")
    //             {
    //                 const errorMessagesKeys = Object.keys(responseResult.data.data);
    //                 errorMessagesKeys.map((key)=>{

    //                     responseResult.data.data[key].map((message)=>{
    //                         toast.error(message)
    //                     })
    //                 })
    //             }
    //             else{
    //                 toast.error(responseResult.data.data)
    //             }

    //         }
    //         else if(responseResult && responseResult.status === 401) {
    //             toast.error(responseResult.data.data)
    //             cookies.remove("token");
    //             window.location.href = "/"
    //         }
    //         else
    //         {
    //             toast.error("network error")
    //         }
    //     }
    //     setLoading(false)

    // };

    const updateObject = async (object) => {
        setselectedPointFollowUp(object);
        setshowPointFollowUpForm(true);
    };
    const previewObject = async (object) => {
        setselectedPointFollowUp(object);
        setshowPointFollowUpPreview(true);
    };
    // const deleteObject = async (object) => {
    //     if (!window.confirm("Delete the item?")) {
    //         return
    //     }
    //     setLoading(true)
    //     try {
    //         const result=await axios({
    //             url: Host + "visits/"+object.id,
    //             method: "delete",
    //             headers: {
    //             "Authorization" : `Bearer ${token}`,
    //                 Accept:"application/json",
    //                 'X-localization':language,
    //             }

    //         })
    //         if(result && result.status == 200)
    //         {
    //             toast.error(result.data.data)
    //             fetchData();
    //         } else if (result.data.status === false) {
    //             toast.error("Error")
    //         }
    //     } catch (error) {
    //         const responseResult=error.response
    //         if (responseResult && responseResult.status === 400) {
    //             if(typeof responseResult.data.data === "object")
    //             {
    //                 const errorMessagesKeys = Object.keys(responseResult.data.data);
    //                 errorMessagesKeys.map((key)=>{

    //                     responseResult.data.data[key].map((message)=>{
    //                         toast.error(message)
    //                     })
    //                 })
    //             }
    //             else{
    //                 toast.error(responseResult.data.data)
    //             }

    //         }
    //         else if(responseResult && responseResult.status === 401) {
    //             toast.error(responseResult.data.data)
    //             cookies.remove("token");
    //             window.location.href = "/"
    //         }
    //         else
    //         {
    //             toast.error("network error")
    //         }
    //     }
    //     setLoading(false)
    // };

    const createObject = async () => {
        setselectedPointFollowUp(null);
        setshowPointFollowUpForm(true);
    };

    const handleSetshowPointFollowUpForm = (openStatus, needsReload) => {
        if (needsReload)
            // fetchData();
            advanceSearch();
        setshowPointFollowUpForm(openStatus);
    };

    const handleSetshowPointFollowUpPreview = (openStatus, needsReload) => {
        if (needsReload)
            // fetchData();
            advanceSearch();
        setshowPointFollowUpPreview(openStatus);
    };

    const mapObject = async (object) => {
        setselectedPointFollowUp(object);
        setshowPointFollowUpDialog(true);
    };
    const handleSetshowPointFollowUpDialog = (openStatus, needsReload) => {
        if (needsReload)
            // fetchData();
            advanceSearch();
        setshowPointFollowUpDialog(openStatus);
    };

    const getImageUrl = (image_url) => {
        var hostURLParts = host.split("/");
        var hostURL = hostURLParts[0] + "//" + hostURLParts[2] + "/";
        const fullImageURL = hostURL + image_url;

        return fullImageURL;
    };

    useEffect(() => {
        fetchData("FOLLOW_UP_TYPES", "follow_up_type_list", {
            schedule_Active: "active",
        });
        fetchData("FOLLOW_UP_METHODS", "follow_up_method_list");
        fetchData("USERS", "users_list");
        fetchData("POINTS", "points_list");

        advanceSearch();
    }, [pageSize, pageNumber]);

    //Reformatting points object once points are loaded
    useEffect(() => {
        if (pointFollowUps && pointFollowUps.data && pointFollowUps.data.length > 0) {
            const reformatVisits = pointFollowUps.data.map((rowVisit) => {
                // console.log('row visits')
                // console.log(rowVisit)
                const thumbnail_image_url = null; //getImageUrl(rowVisit?.profile_image[0]?.thumbnail_image_url)
                const image_url = null; //getImageUrl(rowVisit?.profile_image[0]?.image_url)
                return {
                    ...rowVisit,
                    // profile_image:[{...rowVisit?.profile_image[0],
                    //     thumbnail_image_url:thumbnail_image_url,
                    //     image_url:image_url}]
                };
            });
            setRow(reformatVisits);
            setpointFollowUpTotal(pointFollowUps.total);
            setpointsFollowUpNumberOfPage(pointFollowUps.last_page);
        } else {
            setRow([]);
            setpointFollowUpTotal(0);
            setpointsFollowUpNumberOfPage(0);
        }
    }, [pointFollowUps]);

    // useEffect(() => {
    //     fetchData();
    // }, [])
    const [rows, setRow] = useState([]);
    const [columns] = useState([
        { field: "id", headerName: "ID", minWidth: 50 },
        {
            field: "name",
            headerName: "Point and User Name",
            minWidth: 230,
            renderCell: (rows) => (
                <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                    <span>{rows.row.point.name}</span>
                    <span>{rows.row.created_by.full_name}</span>
                </div>
            ),
        },
        // { field: 'full_name', headerName: 'User' , flex: 1.5,
        // renderCell: (rows) => (
        //     <div style={{ width: '100%',}} >
        //           {rows.row.created_by.full_name}
        //     </div>
        // )},
        {
            field: "follow_up_type",
            headerName: "Follow Up Type and Method",
            minWidth: 230,
            renderCell: (rows) => (
                <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                    <span>{rows.row.follow_up_type?.name}</span>
                    <span>{rows.row.follow_up_method?.name}</span>
                </div>
            ),
        },
        // { field: 'follow_up_method', headerName: 'Follow Up Method' , flex: 1.5,
        // renderCell: (rows) => (
        //     <div style={{ width: '100%' }} >
        //         {rows.row.follow_up_method?.name}<br/>
        //     </div>)
        // },
        {
            field: "details",
            headerName: "Details",
            minWidth: 350,
            renderCell: (rows) => (
                <div
                    style={{
                        width: "100%",
                        overflowWrap: "break-word",
                        wordWrap: "break-word",
                        hyphens: "auto",
                        whiteSpace: "normal", //this is the one that gets you all the time,
                        display: "flex",
                        flexWrap: "wrap",
                        // inlineSize: '299px',
                    }}>
                    {rows.row.details}
                    <br />
                </div>
            ),
        },
        {
            field: "Schedule_date",
            headerName: "Schedule Date",
            minWidth: 150,
            renderCell: (rows) => (
                <div style={{ width: "100%" }}>
                    {rows.row.schedule_date ? rows.row.schedule_date : "لاتوجد"}
                    <br />
                </div>
            ),
        },
        {
            field: "creation_date",
            headerName: "Creation Date",
            minWidth: 150,
            renderCell: (rows) => (
                <div style={{ width: "100%" }}>
                    {rows.row.created_at
                        ? moment(rows.row.created_at).format("YYYY-MM-DD HH:mm:ss")
                            ? moment(rows.row.created_at).format("YYYY-MM-DD HH:mm:ss")?.slice(0, 10)
                            : null
                        : null}
                    <br />
                    {rows.row.created_at
                        ? moment(rows.row.created_at).format("YYYY-MM-DD HH:mm:ss")
                            ? moment(rows.row.created_at).format("YYYY-MM-DD HH:mm:ss")?.slice(10, 18)
                            : null
                        : null}
                </div>
            ),
        },

        {
            field: "actions",
            minWidth: 200,
            headerName: "Actions",
            renderCell: (rows) => (
                <strong>
                    {/* <IconButton                  
                    size="small"
                    style={{ marginLeft: 0 }}                  
                    onClick={() => {                               
                        deleteObject(rows.row)                    
                    }}>
                  <Tooltip title="Locate">                    
                        <DeleteIcon  style={{fill: "red"}}/>
                    </Tooltip>
                </IconButton>*/}

                    <IconButton
                        size='small'
                        style={{ marginLeft: 0 }}
                        onClick={() => {
                            updateObject(rows.row);
                        }}>
                        <Tooltip title='Add FollowUp'>
                            <Button
                                sx={{
                                    color: "white",
                                    backgroundColor: "green",
                                    "&:hover": {
                                        backgroundColor: "green",
                                    },
                                    outline: "none !important",
                                    border: "none !important",
                                }}>
                                Add FollowUp
                            </Button>
                        </Tooltip>
                    </IconButton>

                    <IconButton
                        style={{ marginLeft: 15 }}
                        aria-label='view'
                        size='small'
                        onClick={() => {
                            previewObject(rows.row);
                            // previewObject(rows.row.object1)
                        }}>
                        <Tooltip title='View'>
                            <RemoveRedEyeRoundedIcon color='primary' />
                        </Tooltip>
                    </IconButton>
                </strong>
            ),
        },
    ]);
    const deleteObject = async (object) => {
        if (!window.confirm("Delete the item?")) {
            return;
        }
        setLoading(true);
        try {
            const result = await axios({
                url: Host + "point_follow_ups/" + object.id,
                method: "delete",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "X-localization": language,
                },
            });
            if (result && result.status == 200) {
                toast.error(result.data.data);
                advanceSearch();
            } else if (result.data.status === false) {
                toast.error("Error");
            }
        } catch (error) {
            const responseResult = error.response;
            if (responseResult && responseResult.status === 400) {
                if (typeof responseResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key) => {
                        responseResult.data.data[key].map((message) => {
                            toast.error(message);
                        });
                    });
                } else {
                    toast.error(responseResult.data.data);
                }
            } else if (responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data);
                cookies.remove("token");
                window.location.href = "/";
            } else {
                toast.error("network error");
            }
        }
        setLoading(false);
    };
    const handelChangeDate = (date, number) => {
        if (number == 1) {
            setFormDate(date);
        } else if (number == 2) {
            setTODate(date);
        } else {
            setScheduleDate(date);
        }
    };
    const getDataForExported = async () => {
        let data = {
            page_size: pointFollowUpTotal,
            follow_up_type_id: selectedFollowUpType?.id,
            created_by_id: user.id,
            follow_up_method_id: selectedFollowUpMethod?.id,
            point_id: selectedPoint?.id,
            details: details,
            schedule_Active: "active",
            // schedule_date:moment(scheduleDate)?.format('YYYY-MM-DD'),
            // creation_date_from:moment(fromDate)?.format('YYYY-MM-DD'),
            // creation_date_to:moment(toDate)?.format('YYYY-MM-DD'),
        };
        if (scheduleDate) {
            data = {
                ...data,
                schedule_date: formatDate(scheduleDate),
            };
        }
        if (fromDate) {
            data = {
                ...data,
                creation_date_from: formatDate(fromDate),
            };
        }
        if (toDate) {
            data = {
                ...data,
                creation_date_to: formatDate(toDate),
            };
        }
        if (details) {
            data = {
                ...data,
                details: details,
            };
        }
        const fetchedData = await fetchData("EXPORTED_POINT_FOLLOW_UPS", "point_follow_ups", {
            ...data,
        });
        return fetchedData;
    };
    const exportDataTOExcel = async () => {
        let data = await getDataForExported();
        var arr = [];
        if (data) {
            data?.data &&
                data?.data?.length > 0 &&
                data?.data?.map((itm) => {
                    arr.push({
                        Id: itm?.id,
                        User_Name: itm?.created_by?.full_name,
                        Customer_Name: itm?.point?.name,
                        FollowUp_Type: itm?.follow_up_type?.name,
                        FollowUp_Method: itm?.follow_up_method?.name,
                        Details: itm?.details,
                        Schedule_Date: itm?.schedule_date,
                        Creation_Date: itm?.created_at ? moment(itm.created_at).format("YYYY-MM-DD HH:mm:ss") : null,
                    });
                });
        }
        exportToCSV(arr, "ScheduleFollowUpPoints");
    };

    return (
        <>
            {showPointFollowUpForm ? (
                <SchedulePointFollowUpForm
                    pointFollowUp={selectedPointFollowUp}
                    advanceSearch={advanceSearch}
                    showPointFollowUpForm={showPointFollowUpForm}
                    setshowPointFollowUpForm={handleSetshowPointFollowUpForm}
                />
            ) : null}
            {showPointFollowUpPreview ? (
                <SchedulePointFollowUpPreivew
                    pointFollowUp={selectedPointFollowUp}
                    showPointFollowUpPreview={showPointFollowUpPreview}
                    setshowPointFollowUpPreview={handleSetshowPointFollowUpPreview}
                />
            ) : null}
            {/* {showPointFollowUpDialog?
            // <VisitMapLocation visit={selectedPointFollowUp}  />
                <VisitMapDialog pointFollowUp={selectedPointFollowUp} showPointFollowUpDialog={showPointFollowUpDialog} setshowPointFollowUpDialog={handleSetshowPointFollowUpDialog} />
            :null} */}

            <Box display='flex'>
                <Button
                    display='flex'
                    startIcon={<FileDownloadIcon />}
                    variant='contained'
                    sx={{ marginRight: 1, marginBottom: accExpansion ? "0" : "20px" }}
                    onClick={exportDataTOExcel}>
                    Export
                </Button>
                {/* <Button display = "flex"
                    startIcon={<AddIcon />}
                    variant="contained" 
                    sx={{marginRight: 1, marginBottom: accExpansion ? "0":"20px" }}
                    onClick={createObject}
                    >
                    Create
                </Button>           */}
                {/* <ExportCSV csvData={points.data} fileName={"test"} /> */}
                <Accordion display='flex' sx={{ flexGrow: 4 }} onChange={(event, expansion) => handleExpansion(expansion)}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />} aria-controls='panel1a-content' id='panel1a-header'>
                        <Typography>Advance Search</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box component='span' display='flex' bgcolor='white' flexDirection='column' sx={{ borderRadius: 1 }}>
                            <Box sx={{ m: 1 }} component='span' display='flex' bgcolor='white' flexDirection='row'>
                                <Box display='flex' sx={{ flexGrow: 2 }}>
                                    <Autocomplete
                                        sx={{ ml: 1 }}
                                        fullWidth
                                        options={followUpTypes}
                                        getOptionLabel={(option) => option.name || ""}
                                        id='disable-close-on-select'
                                        value={selectedFollowUpType}
                                        onChange={(e, v, r) => {
                                            setSelectedFollowUpType(v ? v : "");
                                        }}
                                        renderInput={(params) => <TextField {...params} label='FollowUp Type' variant='outlined' />}
                                    />
                                </Box>

                                {/* <SearchField1 state={shopName} setState = {setShopName} fieldLabel='Shop Name'/>                    
                                <SearchField1 state={ownerName} setState = {setOwnerName} fieldLabel='Owner Name'/> */}
                                {/* <BasicDateRangePicker/> */}

                                <Box display='flex' sx={{ flexGrow: 1 }}>
                                    <Button variant='contained' spacing={2} sx={{ flexGrow: 1, margin: 1 }} onClick={() => advanceSearch("SEARCH")}>
                                        Search
                                    </Button>
                                    <Button
                                        variant='contained'
                                        spacing={2}
                                        sx={{ flexGrow: 1, margin: 1 }}
                                        color='secondary'
                                        onClick={clearPointsAdvanceSearchFields}>
                                        Clear
                                    </Button>
                                </Box>
                            </Box>
                            <Box sx={{ m: 1 }} component='span' display='flex' bgcolor='white' flexDirection='row'>
                                <Autocomplete
                                    sx={{ ml: 1 }}
                                    fullWidth
                                    options={followUpMethods}
                                    getOptionLabel={(option) => option.name || ""}
                                    id='disable-close-on-select'
                                    value={selectedFollowUpMethod}
                                    onChange={(e, v, r) => {
                                        setSelectedFollowUpMethod(v ? v : "");
                                    }}
                                    renderInput={(params) => <TextField {...params} label='FollowUp methods' variant='outlined' />}
                                />
                                <Autocomplete
                                    sx={{ ml: 1 }}
                                    fullWidth
                                    options={users}
                                    getOptionLabel={(option) => option.full_name || ""}
                                    id='disable-close-on-select'
                                    value={user}
                                    onChange={(e, v, r) => {
                                        setUser(v ? v : "");
                                    }}
                                    renderInput={(params) => <TextField {...params} label='Users' variant='outlined' />}
                                />
                            </Box>
                            <Box sx={{ m: 1 }} component='span' display='flex' bgcolor='white' flexDirection='row'>
                                <Box sx={{ width: "50%", marginRight: 1 }}>
                                    <SearchField1 state={details} setState={setDetails} fieldLabel='Details' />
                                </Box>
                                <Autocomplete
                                    sx={{ ml: 1, width: "50%" }}
                                    fullWidth
                                    options={points}
                                    getOptionLabel={(option) => option.name || ""}
                                    id='disable-close-on-select'
                                    value={selectedPoint}
                                    onChange={(e, v, r) => {
                                        setSelectedPoint(v ? v : "");
                                    }}
                                    renderInput={(params) => <TextField {...params} label='Customer' variant='outlined' />}
                                />

                                {/* <BasicDateRangePicker/> */}
                            </Box>
                            <Box sx={{ ml: 1, width: "100%", mb: 2 }} component='div' display='flex' flexDirection='row'>
                                <Box sx={{ ml: 1, width: "50%", marginRight: 0, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ width: "100%" }}>
                                        <DesktopDatePicker
                                            label='schedule Date'
                                            inputFormat='MM/DD/YYYY'
                                            value={scheduleDate}
                                            onChange={(date) => handelChangeDate(date, 3)}
                                            renderInput={(params) => <TextField {...params} sx={{ width: "100%" }} />}
                                        />
                                    </LocalizationProvider>
                                </Box>
                                <Box sx={{ width: "50%", ml: 1, mr: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            label='From Date'
                                            inputFormat='MM/DD/YYYY'
                                            value={fromDate}
                                            onChange={(date) => handelChangeDate(date, 1)}
                                            renderInput={(params) => <TextField {...params} style={{ width: "50%" }} />}
                                        />
                                        <Box style={{ width: "10%" }}></Box>
                                        <DesktopDatePicker
                                            label='to Date'
                                            inputFormat='MM/DD/YYYY'
                                            value={toDate}
                                            onChange={(date) => handelChangeDate(date, 2)}
                                            renderInput={(params) => <TextField {...params} style={{ width: "50%" }} />}
                                        />
                                    </LocalizationProvider>
                                </Box>
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>

            <Box
                sx={{
                    height: "calc(100% - 9em)",
                    "& .MuiDataGrid-menuIcon,& .MuiDataGrid-iconButtonContainer,& .MuiDataGrid-columnSeparator": {
                        display: "none !important",
                    },
                }}>
                <StyledDataGrid
                    rows={rows}
                    columns={columns}
                    rowCount={pointFollowUpTotal}
                    pageSize={pageSize}
                    page={pageNumber - 1}
                    onPageSizeChange={(newPageSize) => {
                        setPageSize(newPageSize);
                    }}
                    rowsPerPageOptions={[5, 10, 20]}
                    paginationMode='server'
                    onPageChange={(params) => {
                        handlePageChange(params);
                    }}
                    hideIconeInHeader={true}
                />
                <Box width='45%' m='auto'>
                    <Pagination
                        color='primary'
                        size='large'
                        count={pointsFollowUpNumberOfPage}
                        page={pageNumber}
                        showFirstButton
                        showLastButton
                        onChange={(e, p) => {
                            setpageNumber(p);
                        }}
                    />
                </Box>
                <div style={{ width: "50%", position: "absolute", top: "100px", margin: "0 auto", left: "50%", top: "50%" }}>
                    {loading && <CircularIndeterminate />}
                </div>
            </Box>

            {/* <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="card">
                           
                            <div className="card-header">Visit List </div>
                            <div className="card-body">
                            <table>
                                <thead>
                                    <tr>
                                        <th>1</th>
                                        <th>Point name</th>
                                        <th>Visited by</th>
                                        <th>Visited started at</th>
                                        <th>Visited ended at</th>
                                        <th>is canceled</th>
                                        <th>Cancelation reason</th>
                                        <th>Note</th>
                                        <th>Preview</th>
                                        <th>Map it</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {   
                                    state?.visits?.data ? 
                                        state?.visits?.data?.map((object) => (
                                                <tr key={object?.id}>
                                                    <td>{object?.id}</td>
                                                    <td>
                                                    {object?.point?.name}
                                                    </td>
                                                    <td>{object?.created_by?.full_name}</td>
                                                    <td>{object?.start_date}</td>
                                                    <td>{object?.end_date}</td>
                                                    <td>{object?.canceled?<span style={{padding:5,borderRadius:5,color:'white', backgroundColor:"red"}}>canceled</span>:<span style={{padding:5,borderRadius:5,color:'white', backgroundColor:"green"}}>valid</span>}</td>
                                                    <td>{object.cancelation_reason}</td>
                                                    <td>{object.note}</td>
                                                    <td><Button onClick={()=>{previewObject(object)}}>Preview</Button></td>
                                                    <td><Button style={{backgroundColor:'green',color:'white'}} onClick={()=>{mapObject(object)}}>Map it</Button></td>
                                                    
                                                </tr>
                                        )) : "Loading..."
                                }
                                </tbody>
                            </table>
                                <div>
                                    <Pagination
                                        activePage={state?.visits?.current_page ? state?.visits?.current_page : 0}
                                        itemsCountPerPage={state?.visits?.per_page ? state?.visits?.per_page : 0 }
                                        totalItemsCount={state?.visits?.total ? state?.visits?.total : 0}
                                        onChange={(pageNumber) => {
                                            fetchData(pageNumber)
                                        }}
                                        pageRangeDisplayed={8}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        firstPageText="First Page"
                                        lastPageText="Last Lage"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default SchedulePointFollowUp;
