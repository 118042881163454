import React from 'react'
import { DataGrid,
 } from '@mui/x-data-grid';
          import Pagination from '@mui/material/Pagination';
import { withStyles } from "@material-ui/core/styles";

const StyledDataGrid = withStyles({
    root: {
      "& .MuiDataGrid-renderingZone": {
        
        maxHeight: "none !important"
      },
      "& .MuiDataGrid-cell": {
        lineHeight: "unset !important",
        maxHeight: "none !important",
        whiteSpace: "normal"
      },
      "& .MuiDataGrid-row": {
        backgroundColor:'#FFFFFF',
        maxHeight: "none !important"
      },
      '& .MuiDataGrid-columnHeaderTitle':{
        fontSize:'14.5px !important'
      },
      '& .MuiDataGrid-cell div':{
        fontSize:'13px !important'
      },
      

    
    }
  })(DataGrid);

  

export default StyledDataGrid

