import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Pane, Dialog } from 'evergreen-ui';
import axios from "axios";
import Select from "react-select";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import JourneyMap from "./JourneyMap";
const cookies = new Cookies();
const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
export const JourneyMapDialog = (props) => {
    const [loading, setloading] = useState(true);
   
    const [coordinates,setcoordinates]=useState([])
    const [object, setobject] = useState({
        id: null,
        name: null,
        
    });
   

    const submitForm = async () => {
        props.setshowJourneyMapDialog(false)
       
        
    };


    useEffect(() => {
        if(loading)
        {

            
            if(props&& props.journey&& props.journey.id>0)
            {
                setobject({...object,...props.journey});
                var tempCoordinates=[]
                props.journey.journey_geofences.forEach(geofence => {
                    tempCoordinates.push([geofence.longitude,geofence.latitude])
                    
                });
                setcoordinates(tempCoordinates)
                setloading(false)
            }
            else{
                setobject(
                    {
                        id: null,
                        name: null,
                    }
                )
            }
        }

        
        return () => {

        };
    }, [props.showJourneyMapDialog,loading]);


    return (
        
        <Dialog
                isShown={props.showJourneyMapDialog}
                onCloseComplete={() => props.setshowJourneyMapDialog(false)}
                hasHeader={true}
                hasFooter={false}
                title={props.journey.name}
                shouldCloseOnOverlayClick={false}
                confirmLabel="Save"
                cancelLabel="Cancel"
                width={"80%"}
                minHeightContent={"70%"}
                onConfirm={() => {submitForm()}}
                >
                    {loading?"loading...":
                    <JourneyMap journey={props.journey} coordinates={coordinates} />
                    }
            
        </Dialog>

    );
};
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',

    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: '100%',
        border: '1px solid #ababab',
        borderRadius: 5,
        padding: 0,
        display: 'flex'
    }),
    container: () => ({

        width: '80%',
        position: 'relative',
        boxSizing: 'border-box',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

export default JourneyMapDialog;
