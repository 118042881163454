import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Pane, Dialog } from 'evergreen-ui';
import axios from "axios";
import Select from "react-select";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import VisitMap from "./VisitMap";
const cookies = new Cookies();
const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
export const VisitMapDialog = (props) => {
    const [loading, setloading] = useState(true);
   
    const [object, setobject] = useState({
        id: null,
        name: null,
        
    });
   

    const submitForm = async () => {
        props.setshowVisitMapDialog(false)
       
        
    };


    useEffect(() => {
        if(props&& props.visit&& props.visit.id>0)
        {
            setobject({...object,...props.visit});
        }
        else{
            setobject(
                {
                    id: null,
                    name: null,
                }
            )
        }

        
        return () => {

        };
    }, [props.showVisitMapDialog]);


    return (
        
        <Dialog
                isShown={props.showVisitMapDialog}
                onCloseComplete={() => props.setshowVisitMapDialog(false)}
                hasHeader={false}
                shouldCloseOnOverlayClick={false}
                confirmLabel="Save"
                cancelLabel="Cancel"
                width={"80%"}
                minHeightContent={"70%"}
                onConfirm={() => {submitForm()}}
                >
                    <VisitMap visit={props.visit} />
            
        </Dialog>

    );
};
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',

    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: '100%',
        border: '1px solid #ababab',
        borderRadius: 5,
        padding: 0,
        display: 'flex'
    }),
    container: () => ({

        width: '80%',
        position: 'relative',
        boxSizing: 'border-box',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

export default VisitMapDialog;
