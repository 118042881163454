const reducer = (state, action) => {
    switch (action.type) {
        case "LOADING":
            return { ...state, loading: action.payload };
        case "POINTS":
            return { ...state, points: action.payload, loading: false };
        case "VISITS":
            return { ...state, visits: action.payload, loading: false };
        case "SETTLEMENTS":
            return { ...state, settlements: action.payload, loading: false };
        case "DATA":
            return { ...state, data: action.payload, loading: false };
        case "ALL_POINTS":
            return { ...state, allPoints: action.payload, loading: false };
        case "DISTRICTS":
            return { ...state, districts: action.payload, loading: false };
        case "REGIONS":
            return { ...state, regions: action.payload, loading: false };
        case "DISTRICT":
            return { ...state, district: action.payload };
        case "REGION":
            return { ...state, region: action.payload };
        case "SHOP_NAME":
            return { ...state, shopName: action.payload };
        case "OWNER_NAME":
            return { ...state, ownerName: action.payload };
        case "CLEAR_ADVANCE_FILTER":
            return { ...state, district: [], region: [], shopName: "", ownerName: "", user: [], pointRequestStatus: [], fromToDate: [null, null] };
        case "POINTS_REQUESTS":
            return { ...state, pointsRequests: action.payload, loading: false };
        case "POINT_UPDATE":
            return { ...state, pointsRequestsUpdate: action.payload, loading: false };
        case "USERS":
            return { ...state, users: action.payload };
        case "COLLECTION_TYPES":
            return { ...state, collectionTypes: action.payload };
        case "COLLECTION_TYPE":
            return { ...state, collectionType: action.payload };
        case "USER":
            return { ...state, user: action.payload };
        case "POINTS_REQUESTS_STATUSES":
            return { ...state, pointsRequestsStatuses: action.payload };
        case "POINT_REQUEST_STATUSE":
            return { ...state, pointRequestStatus: action.payload };
        case "FROM_TO_DATE":
            return { ...state, fromToDate: action.payload };
        case "SELECTED_POINT_REQUEST_STATUS":
            return { ...state, selectedPointRequestStatus: action.payload };
        case "POINT_STATUSE":
            return { ...state, pointStatus: action.payload };
        case "POINT_STATUSES":
            return { ...state, pointsStatuses: action.payload };
        case "JOURNIES":
            return { ...state, journies: action.payload, loading: false };
        case "USER_DAILY_PLAN":
            return { ...state, userDailyPlans: action.payload, loading: false };
        case "COLLECTIONS":
            return { ...state, collections: action.payload, loading: false };
        case "POINT_FOLLOW_UPS":
            return { ...state, pointFollowUps: action.payload, loading: false };
        case "FOLLOW_UP_TYPES":
            return { ...state, followUpTypes: action.payload, loading: false };
        case "FOLLOW_UP_METHODS":
            return { ...state, followUpMethods: action.payload, loading: false };
        case "EXPORTED_POINT_FOLLOW_UPS":
            return { ...state, exportedPointFollowUps: action.payload, loading: false };
        case "INVOICES":
            return { ...state, invoices: action.payload, loading: false };
        case "USERS":
            return { ...state, users: action.payload, loading: false };
        default:
            break;
    }
    return state;
};

export default reducer;
