import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Pane, Dialog } from 'evergreen-ui';
import axios from "axios";
import Select from "react-select";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
const cookies = new Cookies();
const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
export const RegionForm = (props) => {
    const [loading, setloading] = useState(true);
    const [districts, setdistricts] = useState([]);
    const [object, setobject] = useState({
        id: null,
        name: null,
        
        district: null,
        district:{
            id:null,
            name:null
        }
    });
    const getResources = async () => {
        try {
            setloading(true)
            const resourcesResult = await axios({
                url: Host + "districts_list",
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                }
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                    const resources = resourcesResult.data;

                    // console.log(resources);
                    setdistricts(resources.map((resource)=>{
                        return {
                            value:resource.id,
                            label:resource.name
                        }
                        
                    }));
                    if(props&& props.region&& props.region.id>0)
                    {
                        setobject({...object,...props.region});
                    }
                    else{
                        console.log("hi");
                        setobject(
                            {
                                id: null,
                                name: null,
                                
                                district: null,
                                district:{
                                    id:null,
                                    name:null
                                }
                            }
                        )
                    }
                } else if (resourcesResult.data.status === false) {

                }
            }
            else {
                toast.error("Error")
            }
            setloading(false)
        } catch (error) {
            setloading(false)
            toast.error("Network Error")
        }
    };

    const submitForm = async () => {
        
        try {
            
        
            if (object.id) {

                const resourcesResult = await axios({
                    url: Host + "regions/"+object.id,
                    method: "put",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: object
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("updated ")
                        props.setshowRegionForm(false,true)
                    } else if (resourcesResult.data.status === false) {
                        props.setshowRegionForm(true,false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setshowRegionForm(true,false)
                }
                setloading(false)
            }
            else {
                const resourcesResult = await axios({
                    url: Host + "regions",
                    method: "post",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: object
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("saved ")
                        props.setshowRegionForm(false,true)
                    } else if (resourcesResult.status === 400) {
                        toast.error(resourcesResult.data)
                        props.setshowRegionForm(true,false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setshowRegionForm(true,false)
                }
                setloading(false)
            }
        } catch (error) {
            props.setshowRegionForm(true,false)
            const resourcesResult=error.response            
            if (resourcesResult && resourcesResult.status === 400) {
                if(typeof resourcesResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        resourcesResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    
                    toast.error(resourcesResult.data.data)
                }
                
                
            }
            else if(resourcesResult && resourcesResult.status === 401) {
             
                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
              
                toast.error("network error")
            }
        }
        
    };


    useEffect(() => {
        
        
        if(loading)
        getResources()

        
        return () => {

        };
    }, [loading,props.showRegionForm]);


    return (
        loading ? <></> :
        <Dialog
                isShown={props.showRegionForm}
                onCloseComplete={() => props.setshowRegionForm(false)}
                hasHeader={false}
                shouldCloseOnOverlayClick={false}
                confirmLabel="Save"
                cancelLabel="Cancel"
                onConfirm={() => {submitForm()}}
                >
            {object &&  object.id>0?
            <h4>Update region</h4>:
            <h4>Create region</h4>}
            <form >
                <div className="form-group">
                    <label htmlFor="name"> Name</label>
                    <input className="form-control" 
                        id="name"
                        value={object.name}
                        onChange={event => { setobject({ ...object, name: event.target.value }); }}
                     />
                </div>
                
               
                <div className="form-group">
                    <label htmlFor="district">district</label>
                    <Select onChange={e => {console.log(e); setobject({ ...object, district:{
                        name:e.label,
                        id:e.value
                    }, district_id: e.value }); }}
                        defaultValue={{value:object.district.id,label:object.district.name}}
                        value={{value:object.district.id,label:object.district.name}}
                        styles={customStyles}
                        options={districts}
                    />
                </div>
                
                
                
            </form>
        </Dialog>

    );
};
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',

    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: '100%',
        border: '1px solid #ababab',
        borderRadius: 5,
        padding: 0,
        display: 'flex'
    }),
    container: () => ({

        width: '80%',
        position: 'relative',
        boxSizing: 'border-box',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

export default RegionForm;
