
import React, {useEffect,useState} from 'react';
// import Pagination from "react-js-pagination";
import Cookies from "universal-cookie";
import axios from "axios";
import {Avatar} from "evergreen-ui"
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import VisitForm from './VisitForm';
import VisitMapDialog from './VisitMapDialog';
import host from '../../../assets/js/Host';
import VisitPreview from './VisitPreview';
// import VisitMapLocation from './VisitMapLocation';
import IconButton from "@material-ui/core/IconButton";
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import BasicDateRangePicker from '../GeneralComponents/DateFromTo'
import moment from 'moment';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import Tooltip from '@mui/material/Tooltip';
import CircularIndeterminate from  '../GeneralComponents/CirularProgress'
import StyledDataGrid from '../GeneralComponents/StyledDataGrid'
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { useGlobalContext } from '../../../context'

import "../../../assets/css/DG.css"
import "../../../assets/css/AccordionStyle.css"
import SearchField1 from  '../GeneralComponents/SearchField1'

// const cookies = new Cookies();
// const token = cookies.get("token")
// const language = cookies.get("language")?cookies.get("language"):"ar"
function Visit() {
    const currentDate = new Date();
    const options = { timeZone: "Asia/Baghdad" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(currentDate);

    const [pageSize, setPageSize] = useState(20);    
    const [pageNumber, setpageNumber] = useState(1);
    const [pointsTotal, setPointsTotal] = useState(0)
    const [pointsNumberOfPages, setPointsNumberOfPages] = useState(0)

    const {loading,fromToDate,visits,pointsStatuses,pointStatus,setPointStatus,exportToCSV,allPoints,user,users,setUser,points,district,region,districts,regions,ownerName,shopName,clearPointsAdvanceSearchFields,setRegion,setDistrict,setShopName,setOwnerName,fetchData,deleteObject} = useGlobalContext()
    
    // const [loading, setloading] = useState(false);
    // const [state, setData] = useState({
    //     visits: ''
    // });
    const [showVisitForm, setshowVisitForm] = useState(false);
    const [showVisitMapDialog,setshowVisitMapDialog] = useState(false)
    const [showVisitPreview,setshowVisitPreview] = useState(false)
    const [selectedVisit, setselectedVisit] = useState(null);
    const [accExpansion,setAccExpansion] = useState(false)
    
    const [fromDate, setFromDate] = useState(formattedDate);
    const [toDate, setTODate] = useState(formattedDate);
    
    const handelChangeDate = (date, number) => {
        if (number == 1) {
            setFromDate(date);
        } else if (number == 2) {
            setTODate(date);
        }
    };


    const handleExpansion = (expansion) => setAccExpansion(expansion)

    const handlePageChange = (newPage) => {        
        setpageNumber(newPage+1)          
    }

    const advanceSearch = async(type = "BROWSING")=>{

        const fetchedData = await fetchData('VISITS','visits',{
            page:type==="SEARCH" ? 1: pageNumber,            
            page_size:pageSize,            
            region:region.id,
            created_by:user.id,  
            shop_name:shopName,          
            creation_date_from:moment(fromDate)?.format('YYYY-MM-DD'),
            creation_date_to:moment(toDate)?.format('YYYY-MM-DD'),            
        })
        return fetchedData
    }

    // const fetchData = async (pageNumber = 1) => {
    //     setloading(true)
    //     try {
    //         const result=await axios({
    //             url: Host + "visits?page="+pageNumber,
    //             method: "get",
    //             headers: {
    //             "Authorization" : `Bearer ${token}`,
    //                 Accept:"application/json",
    //                 'X-localization':language,
    //             }
        
    //         })
    //         if(result && result.status == 200)
    //         {
    //             var visits=result.data ;

    //             if(visits &&visits.length>0)
    //             {
    //                 visits=visits.map((visit)=>{
    //                     const thumbnail_image_url=getImageUrl(visit?.profile_image[0]?.thumbnail_image_url)
    //                     const image_url=getImageUrl(visit?.profile_image[0]?.image_url)
    //                     return {...visit,
    //                         profile_image:[{...visit?.profile_image[0],
    //                             thumbnail_image_url:thumbnail_image_url,
    //                             image_url:image_url}]}
                       
    //                 })
    //             }
                
    //             setData({
    //                 visits:visits 
    //             });
    //         } else if (result.data.status === false) {
    //             toast.error("Error")
    //         }
    //     } catch (error) {
    //         const responseResult=error.response            
    //         if (responseResult && responseResult.status === 400) {
    //             if(typeof responseResult.data.data === "object")
    //             {
    //                 const errorMessagesKeys = Object.keys(responseResult.data.data);
    //                 errorMessagesKeys.map((key)=>{
                    
    //                     responseResult.data.data[key].map((message)=>{
    //                         toast.error(message)
    //                     })
    //                 })
    //             }
    //             else{
    //                 toast.error(responseResult.data.data)
    //             }
                
    //         }
    //         else if(responseResult && responseResult.status === 401) {
    //             toast.error(responseResult.data.data)
    //             cookies.remove("token");
    //             window.location.href = "/"
    //         }
    //         else
    //         {
    //             toast.error("network error")
    //         }
    //     }
    //     setloading(false)
                   
    // };

    const updateObject = async (object) => {
        setselectedVisit(object)
        setshowVisitForm(true)
    };
    const previewObject = async (object) => {
        setselectedVisit(object)
        setshowVisitPreview(true)
    }
    // const deleteObject = async (object) => {
    //     if (!window.confirm("Delete the item?")) {
    //         return
    //     }
    //     setloading(true)
    //     try {
    //         const result=await axios({
    //             url: Host + "visits/"+object.id,
    //             method: "delete",
    //             headers: {
    //             "Authorization" : `Bearer ${token}`,
    //                 Accept:"application/json",
    //                 'X-localization':language,
    //             }
        
    //         })
    //         if(result && result.status == 200)
    //         {
    //             toast.error(result.data.data)
    //             fetchData();
    //         } else if (result.data.status === false) {
    //             toast.error("Error")
    //         }
    //     } catch (error) {
    //         const responseResult=error.response            
    //         if (responseResult && responseResult.status === 400) {
    //             if(typeof responseResult.data.data === "object")
    //             {
    //                 const errorMessagesKeys = Object.keys(responseResult.data.data);
    //                 errorMessagesKeys.map((key)=>{
                    
    //                     responseResult.data.data[key].map((message)=>{
    //                         toast.error(message)
    //                     })
    //                 })
    //             }
    //             else{
    //                 toast.error(responseResult.data.data)
    //             }
                
    //         }
    //         else if(responseResult && responseResult.status === 401) {
    //             toast.error(responseResult.data.data)
    //             cookies.remove("token");
    //             window.location.href = "/"
    //         }
    //         else
    //         {
    //             toast.error("network error")
    //         }
    //     }
    //     setloading(false)
    // };
    
    const createObject = async (object) => {
        setselectedVisit(null)
        setshowVisitForm(true)
    };

    const handleSetshowVisitForm = (openStatus,needsReload) => {
      if(needsReload)
        // fetchData();
        advanceSearch()
      setshowVisitForm(openStatus)
    };

    const handleSetshowVisitPreview = (openStatus,needsReload)=>{
        if(needsReload)
            // fetchData();
            advanceSearch()
        setshowVisitPreview(openStatus)
    }

    const mapObject= async (object)=>{
        setselectedVisit(object)
        setshowVisitMapDialog(true)
    }
    const handleSetshowVisitMapDialog = (openStatus,needsReload)=>{
        if(needsReload)
            // fetchData();
            advanceSearch()
        setshowVisitMapDialog(openStatus)
    }

    const getImageUrl =  (image_url)=>{
        var hostURLParts=host.split("/")
        var hostURL=hostURLParts[0]+"//"+hostURLParts[2]+"/"
        const fullImageURL=hostURL+image_url
      
        return fullImageURL;
    }
    
    useEffect( () => {    
        fetchData('REGIONS','regions_list') 
        fetchData('USERS','users_list')                             
        advanceSearch()    
    }, [pageSize,pageNumber])
    
    //Reformatting points object once points are loaded
    useEffect(() => {        
        
        if (visits && visits.data && visits.data.length > 0) {
            const reformatVisits  = visits.data.map((rowVisit) =>{                    
                // console.log('row visits')
                // console.log(rowVisit)
            const thumbnail_image_url=null //getImageUrl(rowVisit?.profile_image[0]?.thumbnail_image_url)
            const image_url=null //getImageUrl(rowVisit?.profile_image[0]?.image_url)
                return {   ...rowVisit,
                    // profile_image:[{...rowVisit?.profile_image[0],
                    //     thumbnail_image_url:thumbnail_image_url,
                    //     image_url:image_url}]                    
                }                
            })                             
            setRow(reformatVisits);                
            setPointsTotal(visits.total)    
            setPointsNumberOfPages(visits.last_page)                     
        }else{
            setRow([]);                
            setPointsTotal(0)    
            setPointsNumberOfPages(0) 
        }

    }, [visits])
    
    // useEffect(() => {
    //     fetchData();
    // }, [])
    const [rows,setRow] = useState([])
    const [columns] = useState([
        { field: 'id', headerName: 'ID' , flex: .1},        
        { field: 'name', headerName: 'Point Name' , flex: 2,
        renderCell: (rows) => (
            <div style={{ width: '100%' }} >                               
                  {rows.row.point.name}
            </div>
        )},
        { field: 'full_name', headerName: 'User' , flex: 1.5,
        renderCell: (rows) => (
            <div style={{ width: '100%',}} >                               
                  {rows.row.created_by.full_name}                  
            </div>
        )},        
        { field: 'start_date', headerName: 'Start End Date' , flex: 1.5,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} >                               
                    {rows.row.start_date}<br/>
                    {rows.row.end_date}                  
                </div>)
        },

        { field: 'canceled', headerName: 'Is Cancelled' , flex: .8,
        renderCell: (rows) => (
            <div style={{ width: '100%' }} >                               
                {rows.row.canceled ?
                    <span style={{padding:5,borderRadius:5,color:'white', backgroundColor:"red"}}>canceled</span>
                :
                    <span style={{padding:5,borderRadius:5,color:'white', backgroundColor:"green"}}>valid</span>                
                }                 
            </div>
        )},   
        { field: 'cancelation_reason', headerName: 'Reason' , flex: 1},    
        { field: 'note', headerName: 'Note' , flex: 2},    
        
        { field: 'actions',flex: 1,
            headerName: 'Actions',                
            renderCell: (rows) => (
              <strong>                
                <IconButton                  
                    size="small"
                    style={{ marginLeft: 0 }}                  
                    onClick={() => {                               
                    mapObject(rows.row)                    
                    }}>
                  <Tooltip title="Locate">                    
                        <LocationOnRoundedIcon  style={{fill: "#5cb85c"}}/>
                    </Tooltip>
                </IconButton>

                <IconButton  
                    style={{ marginLeft: 15 }}
                    aria-label="view" 
                    size="small" 
                    onClick={() => {                                   
                    previewObject(rows.row)
                    // previewObject(rows.row.object1)
                    }}>
                    <Tooltip title="View">
                        <RemoveRedEyeRoundedIcon color="primary" />
                    </Tooltip>
                </IconButton>                                                
              </strong>
            ),
        },        
    ]);

    
    return (
        <>
            {showVisitForm?
                <VisitForm visit={selectedVisit} showVisitForm={showVisitForm} setshowVisitForm={handleSetshowVisitForm} />
            :null}
            {showVisitPreview?
                <VisitPreview visit={selectedVisit} showVisitPreview={showVisitPreview} setshowVisitPreview={handleSetshowVisitPreview} />
            :null}
            {showVisitMapDialog?
            // <VisitMapLocation visit={selectedVisit}  />
                <VisitMapDialog visit={selectedVisit} showVisitMapDialog={showVisitMapDialog} setshowVisitMapDialog={handleSetshowVisitMapDialog} />
            :null}
           
           <Box display="flex" >
                <Button display = "flex"
                    startIcon={<FileDownloadIcon />}
                    variant="contained" 
                    sx={{marginRight: 1, marginBottom: accExpansion ? "0":"20px" }}
                    onClick = { async () => {
                                        // const fetchedData = await   fetchData('POINTS_REQUESTS','point_requests',{
                                        //     page:pageNumber,
                                        //     page_size:pointsTotal,
                                        //     shop_name:shopName,
                                        //     owner_name:ownerName,
                                        //     district:district.id,
                                        //     region:region.id
                                        // });
                                        // advanceSearch()
                                        const fetchedData = await fetchData('VISITS','visits',{
                                            page_size:pointsTotal,            
                                            region:region.id,
                                            created_by:user.id,  
                                            shop_name:shopName,          
                                            creation_date_from:moment(fromDate)?.format('YYYY-MM-DD'),
                                            creation_date_to:moment(toDate)?.format('YYYY-MM-DD'),            
                                        })
                                        exportToCSV(fetchedData.data,'visits')
                                        
                                        // exportToCSV((await advanceSearch("SEARCH")).data,'visits')
                                    } 
                             }>
                    Export
                </Button>           
                {/* <ExportCSV csvData={points.data} fileName={"test"} /> */}
                <Accordion display = "flex" sx={{ flexGrow: 4}} 
                    onChange={(event,expansion) => handleExpansion(expansion)}
                > 
                    <AccordionSummary 
                        expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography>Advance Search</Typography>
                    </AccordionSummary>
                    <AccordionDetails >
                        <Box component="span" display="flex" bgcolor = "white" flexDirection="column"  sx={{ borderRadius: 1 }} >
                            <Box sx={{m:1}} component="span" display="flex" bgcolor = "white" flexDirection="row" >
                                <Box display="flex" sx={{ flexGrow: 2}}>                                    
                                    <Autocomplete
                                        sx={{ml:1}}
                                        fullWidth                            
                                        options = {regions}                            
                                        getOptionLabel = {(option) => option.name || ""}
                                        id="disable-close-on-select"                        
                                        value = {region}
                                        onChange={(e,v,r) => {setRegion(v ? v:'')}}
                                        renderInput={(params) => (
                                        <TextField {...params} label="Regions" variant="outlined" />
                                        )}
                                    />
                                </Box>

                                
                                {/* <SearchField1 state={shopName} setState = {setShopName} fieldLabel='Shop Name'/>                    
                                <SearchField1 state={ownerName} setState = {setOwnerName} fieldLabel='Owner Name'/> */}
                                <BasicDateRangePicker/>

                                <Box display="flex" sx={{ flexGrow: 1 }}>
                                    <Button 
                                        variant="contained" 
                                        spacing={2} sx={{ flexGrow: 1,margin: 1 }}
                                        onClick = {() => advanceSearch("SEARCH")} >
                                        Search
                                    </Button>
                                    <Button variant="contained" 
                                        spacing={2} 
                                        sx={{ flexGrow: 1,margin: 1 }} 
                                        color="secondary"
                                        onClick = {() => {
                                            clearPointsAdvanceSearchFields();
                                            setFromDate(null);
                                            setTODate(null);
                                        }} >
                                        Clear
                                    </Button>                       
                                </Box>                
                            </Box>
                            <Box sx={{m:1}} component="span" display="flex" bgcolor = "white" flexDirection="row" >                
                                <Autocomplete                                                 
                                    sx={{ml:1}}
                                    fullWidth                            
                                    options = {users}                            
                                    getOptionLabel = {(option) => option.full_name || ""}
                                    id="disable-close-on-select"                        
                                    value = {user}
                                    onChange={(e,v,r) => {setUser(v ? v:'');}}
                                    renderInput={(params) => (
                                    <TextField {...params} label="Users" variant="outlined" />
                                    )}
                                />

                                <SearchField1 state={shopName} setState = {setShopName} fieldLabel='Shop Name'/>

                                {/* <BasicDateRangePicker/> */}
                            </Box>
                            <Box sx={{ m: 1 }} component='span' display='flex' bgcolor='white' flexDirection='row'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        label='From Date'
                                        inputFormat='YYYY-MM-DD'
                                        value={fromDate}
                                        onChange={(date) => {
                                            handelChangeDate(new Date(date), 1);
                                        }}
                                        renderInput={(params) => <TextField {...params} sx={{ ml: 1, width: "100%" }} />}
                                    />
                                    <DesktopDatePicker
                                        label='to Date'
                                        inputFormat='YYYY-MM-DD'
                                        value={toDate}
                                        onChange={(date) => {
                                            handelChangeDate(new Date(date), 2);
                                        }}
                                        renderInput={(params) => <TextField {...params} sx={{ ml: 1, width: "100%" }} />}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>


           <div style={{height:'calc(100% - 9em)'}}>
                
                <StyledDataGrid                                                                          
                    rows={rows}
                    columns={columns}                                    
                    rowCount={pointsTotal}
                    pageSize={pageSize}
                    page = {pageNumber-1}
                    onPageSizeChange={(newPageSize) =>{                    
                        setPageSize(newPageSize)                      
                    }}                
                    rowsPerPageOptions={[5, 10, 20]}                  
                    paginationMode="server"
                    onPageChange={(params) =>{
                        handlePageChange(params)
                    }}                         
                />
                <Box width="45%" m="auto">
                    <Pagination                    
                        color="primary"
                        size="large"                
                        count={pointsNumberOfPages}                         
                        page={pageNumber}                
                        showFirstButton
                        showLastButton 
                        onChange ={(e,p) =>{                             
                            setpageNumber(p)                             
                        }}
                    />
                </Box>
                <div style={{width:'50%', position:'absolute', top:'100px',margin:'0 auto', left:'50%', top:'50%'}}>
                    {loading && (
                        <CircularIndeterminate />
                    )}
                </div>
            </div>

            {/* <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="card">
                           
                            <div className="card-header">Visit List </div>
                            <div className="card-body">
                            <table>
                                <thead>
                                    <tr>
                                        <th>1</th>
                                        <th>Point name</th>
                                        <th>Visited by</th>
                                        <th>Visited started at</th>
                                        <th>Visited ended at</th>
                                        <th>is canceled</th>
                                        <th>Cancelation reason</th>
                                        <th>Note</th>
                                        <th>Preview</th>
                                        <th>Map it</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {   
                                    state?.visits?.data ? 
                                        state?.visits?.data?.map((object) => (
                                                <tr key={object?.id}>
                                                    <td>{object?.id}</td>
                                                    <td>
                                                    {object?.point?.name}
                                                    </td>
                                                    <td>{object?.created_by?.full_name}</td>
                                                    <td>{object?.start_date}</td>
                                                    <td>{object?.end_date}</td>
                                                    <td>{object?.canceled?<span style={{padding:5,borderRadius:5,color:'white', backgroundColor:"red"}}>canceled</span>:<span style={{padding:5,borderRadius:5,color:'white', backgroundColor:"green"}}>valid</span>}</td>
                                                    <td>{object.cancelation_reason}</td>
                                                    <td>{object.note}</td>
                                                    <td><Button onClick={()=>{previewObject(object)}}>Preview</Button></td>
                                                    <td><Button style={{backgroundColor:'green',color:'white'}} onClick={()=>{mapObject(object)}}>Map it</Button></td>
                                                    
                                                </tr>
                                        )) : "Loading..."
                                }
                                </tbody>
                            </table>
                                <div>
                                    <Pagination
                                        activePage={state?.visits?.current_page ? state?.visits?.current_page : 0}
                                        itemsCountPerPage={state?.visits?.per_page ? state?.visits?.per_page : 0 }
                                        totalItemsCount={state?.visits?.total ? state?.visits?.total : 0}
                                        onChange={(pageNumber) => {
                                            fetchData(pageNumber)
                                        }}
                                        pageRangeDisplayed={8}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        firstPageText="First Page"
                                        lastPageText="Last Lage"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default Visit;


