import React, { useState, useContext, useReducer, useEffect } from "react";
import Cookies from "universal-cookie";
import Host from "../src/assets/js/Host";
import { toast } from "react-toastify";
import axios from "axios";
import reducer from "./reducer";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
const cookies = new Cookies();
const token = cookies.get("token");
const language = cookies.get("language") ? cookies.get("language") : "ar";

const AppContext = React.createContext();

let now = new Date();

const initialState = {
    loading: false,
    districts: [],
    regions: [],
    points: [],
    allPoints: [],
    pointsRequests: [],
    pointsRequestsUpdate: [],
    users: [],
    visits: [],
    settlements: [],
    pointsRequestsStatuses: [],
    district: [],
    region: [],
    user: [],
    collectionTypes: [
        {
            id: 1,
            name: "Settled collection",
        },
        {
            id: 2,
            name: "Not Settled collection",
        },
    ],
    collectionType: [],
    data: [],
    pointRequestStatus: [],
    pointsStatuses: [],
    pointStatus: [],
    journies: [],
    userDailyPlans: [],
    shopName: "",
    ownerName: "",
    pointFollowUps: [],
    invoices: [],
    followUpTypes: [],
    followUpMethods: [],
    exportedPointFollowUps: [],
    selectedPointRequestStatus: "",
    fromToDate: [moment(now).format("YYYY-MM-DD"), moment(now).format("YYYY-MM-DD")],
};

const AppProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const setShopName = (value) => {
        dispatch({ type: "SHOP_NAME", payload: value });
    };
    const setOwnerName = (value) => {
        dispatch({ type: "OWNER_NAME", payload: value });
    };
    const clearPointsAdvanceSearchFields = () => {
        dispatch({ type: "CLEAR_ADVANCE_FILTER" });
    };
    const setRegion = (value) => {
        dispatch({ type: "REGION", payload: value });
    };

    const setDistrict = (value) => {
        dispatch({ type: "DISTRICT", payload: value });
    };

    const setPointsRequestsUpdate = (value) => {
        dispatch({ type: "POINT_UPDATE", payload: value });
    };

    const setRegions = (value) => {
        dispatch({ type: "REGIONS", payload: value });
    };

    const setData = (value) => {
        dispatch({ type: "DATA", payload: value });
    };

    const setVisits = (value) => {
        dispatch({ type: "VISITS", payload: value });
    };

    const setSettlements = (value) => {
        dispatch({ type: "SETTLEMENTS", payload: value });
    };

    const setDistricts = (value) => {
        dispatch({ type: "DISTRICTS", payload: value });
    };
    const setUser = (value) => {
        dispatch({ type: "USER", payload: value });
    };
    const setCollectionType = (value) => {
        dispatch({ type: "COLLECTION_TYPE", payload: value });
    };
    const setLoading = (value) => {
        dispatch({ type: "LOADING", payload: value });
    };

    const setPoints = (value) => {
        dispatch({ type: "POINTS", payload: value });
    };

    const setPointRequestStatus = (value) => {
        dispatch({ type: "POINT_REQUEST_STATUSE", payload: value });
    };

    const setPointStatus = (value) => {
        dispatch({ type: "POINT_STATUSE", payload: value });
    };

    const setJournies = (value) => {
        dispatch({ type: "JOURNIES", payload: value });
    };

    const setFromToDate = (value) => {
        dispatch({ type: "FROM_TO_DATE", payload: value });
    };

    const setSelectedPointRequestStatus = (value) => {
        dispatch({ type: "SELECTED_POINT_REQUEST_STATUS", payload: value });
    };

    const setUserDailyPlans = (value) => {
        dispatch({ type: "USER_DAILY_PLAN", payload: value });
    };

    const deleteObject = async (dispatchType, link, object) => {
        if (!window.confirm("Delete the item?")) {
            return;
        }
        dispatch({ type: "LOADING", payload: true });
        try {
            const result = await axios({
                url: Host + link + "/" + object.id,
                method: "delete",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "X-localization": language,
                },
            });
            dispatch({ type: "LOADING", payload: false });

            if (result && result.status === 200) {
                toast.success(result.data.data);
                return result.status;
            } else if (result.data.status === false) {
                toast.error("Error");
                return "Error";
            }
        } catch (error) {
            const responseResult = error.response;
            if (responseResult && responseResult.status === 400) {
                if (typeof responseResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key) => {
                        responseResult.data.data[key].map((message) => {
                            toast.error(message);
                        });
                    });
                    return "Error";
                } else {
                    toast.error(responseResult.data.data);
                    return "Error";
                }
            } else if (responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data);
                cookies.remove("token");
                window.location.href = "/";
                return "Error";
            } else {
                toast.error("network error");
                return "Error";
            }
        }
    };

    const fetchData = async (dispatchType, link, params) => {
        dispatch({ type: "LOADING", payload: true });
        try {
            let result = "";

            result = await axios({
                url: Host + link,
                method: "get",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "X-localization": language,
                },
                params: params,
            });

            if (result && result.status === 200) {
                var fetchedData = result.data;

                dispatch({ type: dispatchType, payload: fetchedData });
                return fetchedData;
            } else if (fetchedData.status === false) {
                toast.error("Error");
                dispatch({ type: dispatchType, payload: [] });
            }
        } catch (error) {
            const responseResult = error.response;
            if (responseResult && responseResult.status === 400) {
                if (typeof responseResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key) => {
                        responseResult.data.data[key].map((message) => {
                            toast.error(message);
                        });
                    });
                } else {
                    toast.error(responseResult.data.data);
                }
            } else if (responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data);
                cookies.remove("token");
                window.location.href = "/";
            } else {
                console.log(error);
                toast.error("network error");
            }
            dispatch({ type: dispatchType, payload: [] });
        }
    };

    const sendRequest = async (point_order_id, to_user_id) => {
        try {
            let result = await axios({
                url: Host + "point_order_transfer/send_request",
                method: "post",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "X-localization": language,
                },
                data: {
                    point_order_id: point_order_id,
                    to_user_id: to_user_id,
                },
            });

            if (result && result.status === 200) {
                var responseResult = result.data;
                toast.success("تم ارسال الطلب");
                window.location.reload();

                return responseResult;
            } else if (responseResult.status === false) {
                console.log("request sending error: ", responseResult.data);
            }
        } catch (error) {
            const responseResult = error.response;
            if (responseResult && responseResult.status === 400) {
                if (typeof responseResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key) => {
                        responseResult.data.data[key].map((message) => {
                            toast.error(message);
                        });
                    });
                    return "Error";
                } else {
                    toast.error(responseResult.data.data);
                    return "Error";
                }
            } else if (responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data);
                cookies.remove("token");
                window.location.href = "/";
                return "Error";
            } else {
                toast.error("network error");
                return "Error";
            }
        }
    };

    const sendAccepted = async (point_order_id) => {
        try {
            let result = await axios({
                url: Host + "point_order_transfer/send_accepted/" + point_order_id,
                method: "put",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "X-localization": language,
                },
            });

            if (result && result.status === 200) {
                var responseResult = result.data;
                toast.success("تم قبول الطلب");
                window.location.reload();

                return responseResult;
            } else if (responseResult.status === false) {
                console.log("accept error: ", responseResult.data);
            }
        } catch (error) {
            const responseResult = error.response;
            if (responseResult && responseResult.status === 400) {
                if (typeof responseResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key) => {
                        responseResult.data.data[key].map((message) => {
                            toast.error(message);
                        });
                    });
                    return "Error";
                } else {
                    toast.error(responseResult.data.data);
                    return "Error";
                }
            } else if (responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data);
                cookies.remove("token");
                window.location.href = "/";
                return "Error";
            } else {
                toast.error("network error");
                return "Error";
            }
        }
    };

    const sendRejected = async (point_order_id) => {
        try {
            let result = await axios({
                url: Host + "point_order_transfer/send_rejected/" + point_order_id,
                method: "put",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "X-localization": language,
                },
            });

            if (result && result.status === 200) {
                var responseResult = result.data;
                toast.success("تم رفض الطلب");
                window.location.reload();

                return responseResult;
            } else if (responseResult.status === false) {
                console.log("reject error: ", responseResult.data);
            }
        } catch (error) {
            const responseResult = error.response;
            if (responseResult && responseResult.status === 400) {
                if (typeof responseResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key) => {
                        responseResult.data.data[key].map((message) => {
                            toast.error(message);
                        });
                    });
                    return "Error";
                } else {
                    toast.error(responseResult.data.data);
                    return "Error";
                }
            } else if (responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data);
                cookies.remove("token");
                window.location.href = "/";
                return "Error";
            } else {
                toast.error("network error");
                return "Error";
            }
        }
    };

    const cancelVisit = async (id, reason) => {
        try {
            let result = await axios({
                url: Host + "visits/change_status/" + id,
                method: "patch",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "X-localization": language,
                },
                params: {
                    is_cancel: 1,
                    cancelation_reason: reason
                }
            });

            if (result && result.status === 200) {
                var responseResult = result.data;
                toast.success("تم الالغاء");

                return responseResult;
            } else if (responseResult.status === false) {
                console.log("reject error: ", responseResult.data);
            }
        } catch (error) {
            const responseResult = error.response;
            if (responseResult && responseResult.status === 400) {
                if (typeof responseResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key) => {
                        responseResult.data.data[key].map((message) => {
                            toast.error(message);
                        });
                    });
                    return "Error";
                } else {
                    toast.error(responseResult.data.data);
                    return "Error";
                }
            } else if (responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data);
                cookies.remove("token");
                window.location.href = "/";
                return "Error";
            } else {
                toast.error("network error");
                return "Error";
            }
        }
    };

    const getUsers = async () => {
        try {
            let result = await axios({
                url: Host + "users",
                method: "get",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "X-localization": language,
                },
            });

            if (result && result.status === 200) {
                var responseResult = result.data;

                return responseResult;
            } else if (responseResult.status === false) {
                console.log("error: ", responseResult.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const exportToCSV = (csvData, fileName) => {
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        if (csvData && csvData.length > 0) {
            const ws = XLSX.utils.json_to_sheet(csvData);
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + "_" + Math.floor(Date.now() / 1000) + fileExtension);
        }
    };

    const setCollections = (value) => {
        dispatch({ type: "COLLECTIONS", payload: value });
    };

    return (
        <AppContext.Provider
            value={{
                ...state,
                clearPointsAdvanceSearchFields,
                setRegion,
                setRegions,
                setDistrict,
                setOwnerName,
                setShopName,
                setUser,
                setCollectionType,
                setLoading,
                setPointRequestStatus,
                setFromToDate,
                setSelectedPointRequestStatus,
                setPointStatus,
                setJournies,
                setUserDailyPlans,
                setDistricts,
                setPoints,
                setVisits,
                setSettlements,
                fetchData,
                deleteObject,
                setPointsRequestsUpdate,
                exportToCSV,
                setCollections,
                sendRequest,
                sendAccepted,
                sendRejected,
                getUsers,
                cancelVisit,
            }}>
            {children}
        </AppContext.Provider>
    );
};
export const useGlobalContext = () => {
    return useContext(AppContext);
};

export { AppContext, AppProvider };
