import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Pane, Dialog } from 'evergreen-ui';
import axios from "axios";
import Select from "react-select";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
const cookies = new Cookies();
const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
export const SurveyBuildForm = (props) => {
    const [loading, setloading] = useState(true);
    const [refreshList,setrefreshList] = useState(false);
    
    const [selectedSurveyField,setselectedSurveyField] = useState(props.surveyFieldsData?.length>0?{
        value:props.surveyFieldsData[0].id,
        label:props.surveyFieldsData[0].name,
        has_list:props.surveyFieldsData[0].field_list_id?1:0,
        field_list_id:props.surveyFieldsData[0].field_list_id
    }:{
        value:null,
        label:"لايوجد",
        has_list:0,
        field_list_id:null
    })
    
    const [fieldListValues, setfieldListValues] = useState([
        {
            value:null,
            label:"لايوجد"
        }
    ]);
    const [selectedFieldListValue, setselectedFieldListValue] = useState({
        value:null,
        label:"لايوجد"
    });
    
    const [object, setobject] = useState(
        {
            "id": props.createId,
            "name": null,
            "survey_id": props.surveyId,
            "field_type_id": props.fieldTypes?.length>0?props.fieldTypes[0].id:null,
            "field_list_id": null,
            "default_value": null,
            "is_mandatory": 1,
            "is_multiple_select": 0,
            "has_condition": 1,
            "survey_field_id": props?.surveyFieldsData?.length>0?props?.surveyFieldsData[0].id:null,
            "condition_value": "نعم",
            "children":[],
            "field_type": {
                "id": 1,
                "name": "check box",
                "has_list": 1
            },
            "field_list": {
                "id": null,
                "name": "لايوجد",
                
            }
        }
    );
    const getResources = async (field_list_id) => {
        if(field_list_id==null)
        {
            
            return;
        }
        
        try {
            
            const resourcesResult = await axios({
                url: Host + "field_list_values_list",
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                },
                params:{
                    field_list_id:field_list_id
                }
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                    const resources = resourcesResult.data;

                    // console.log(resources);
                    setfieldListValues([{
                        value:null,
                        label:"لايوجد"
                    },...resources.map((resource)=>{
                        return {
                            value:resource.id,
                            label:resource.value
                        }
                        
                    })]);
                    
                } else if (resourcesResult.data.status === false) {

                }
            }
            else {
                toast.error("Error")
            }
            
        } catch (error) {
            
            toast.error("Network Error")
        }
    };

    const submitForm = async () => {

        if(object.name == null || (object.name !=null && object.name.trim().length<=0))
        {
            toast.error("Name field is required")
            return
        }
        console.log(object.name +"&&");
        
        props.setsurveyFieldsData([...props.surveyFieldsData,object])
        props.setshowSurveyBuildForm(false)
    };

    useEffect( () => {
        (async()=>{
            if(loading)
            {
                if(props.surveyFieldsData?.length>0)
                {
                    await getResources(props.surveyFieldsData[0].field_list_id)
                }
                setloading(false)
            }
        })();
        
            
        return () => {

        };
    }, [loading,props.showSurveyBuildForm]);

    console.log(props.surveyId);
    useEffect( () => {
        
        
            
        return () => {

        };
    }, [refreshList]);
   

    return (
        
        <Dialog
                isShown={props.showSurveyBuildForm}
                onCloseComplete={() => props.setshowSurveyBuildForm(false)}
                hasHeader={false}
                shouldCloseOnOverlayClick={false}
                confirmLabel="Save"
                cancelLabel="Cancel"
                onConfirm={() => {submitForm()}}
                >
          
            <h4>Create survey field</h4>
            {loading ? <div>loading</div> :
            <form >
                <div className="form-group">
                    <label htmlFor="name"> Name</label>
                    <input className="form-control" 
                        id="name"
                        value={object.name}
                        onChange={event => { setobject({ ...object, name: event.target.value }); }}
                     />
                </div>
                <div className="form-group">
                    <label htmlFor="default"> Default value</label>
                    <input className="form-control" 
                        id="name"
                        value={object.default_value}
                        onChange={event => { setobject({ ...object, default_value: event.target.value }); }}
                     />
                </div>
                
                <div className="form-group">
                    <label htmlFor="field_type">Field Type</label>
                    <Select onChange={e => {setobject({ ...object, field_type:{
                        name:e.label,
                        id:e.value
                    }, field_type_id: e.value }); }}
                        defaultValue={{value:object.field_type?.id,label:object.field_type?.name}}
                        value={{value:object.field_type?.id,label:object.field_type?.name}}
                        styles={customStyles}
                        options={props.fieldTypes}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="field_list">Field List</label>
                    <Select onChange={e => {setobject({ ...object, field_list:{
                        name:e.label,
                        id:e.value
                    }, field_list_id: e.value }); }}
                        defaultValue={{value:object.field_list?.id,label:object.field_list?.name}}
                        value={{value:object.field_list?.id,label:object.field_list?.name}}
                        styles={customStyles}
                        options={props.fieldLists}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="survey_field_id">Parent survey field</label>
                    <Select onChange={async (e) => {
                            
                            await getResources(e.field_list_id)
                            setrefreshList(true)
                            setselectedSurveyField({
                                ...selectedSurveyField,
                                ...e
                            }) 
                            setobject({ ...object, survey_field_id: e.value});
                        }}
                        defaultValue={props.surveyFieldsData.length>0?selectedSurveyField:{
                            value:null,
                            label:"لايوجد",
                            has_list:0,
                            field_list_id:null
                    }}
                        value={props.surveyFieldsData.length>0?selectedSurveyField:{
                                value:null,
                                label:"لايوجد",
                                has_list:0,
                                field_list_id:null
                        }}
                        styles={customStyles}
                        options={props.surveyFieldsData.map((surveyField)=>{
                            return {
                                value:surveyField.id,
                                label:surveyField.name,
                                has_list:surveyField?.field_list_id?1:0,
                                field_list_id:surveyField?.field_list_id
                            }
                        })}
                    />
                </div>
                {selectedSurveyField.has_list?
                    <div className="form-group">
                        <label htmlFor="field_list">Select condition value</label>
                        <Select onChange={e => {
                            setselectedFieldListValue(e)
                            setobject({ ...object,condition_value: e.label }); }}
                            defaultValue={selectedFieldListValue}
                            value={selectedFieldListValue}
                            styles={customStyles}
                            options={fieldListValues}
                        />
                    </div>
                :
                    <div className="form-group">
                        <label htmlFor="default"> condition value</label>
                        <input className="form-control" 
                            id="name"
                            value={object.condition_value}
                            onChange={event => { setobject({ ...object, condition_value: event.target.value }); }}
                        />
                    </div>
                    
                }
                <div className="form-check" >
                    <input className="form-check-input" 
                        type="checkbox"
                        checked={object.is_mandatory==1}
                        value={"is mandatory"} 
                        name="surveyFields[]"
                        onChange={(event)=>setobject({...object,is_mandatory:event.target.checked?1:0})}
                        id={"is_mandatory"}/>
                    <label className="form-check-label" htmlFor={"is_mandatory"}>
                        Is mandatory
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" 
                        type="checkbox"
                        checked={object.has_condition==1}
                        value={"has condition"}  
                        name="surveyFields[]"
                        onChange={(event)=>setobject({...object,has_condition:event.target.checked?1:0})}
                        id={"has_condition"}/>
                    <label className="form-check-label" htmlFor={"has_condition"}>
                        Has condition
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" 
                        type="checkbox"
                        checked={object.is_multiple_select==1}
                        value={"is_multiple_select"} 
                        name="approvedFields[]"
                        onChange={(event)=>setobject({...object,is_multiple_select:event.target.checked?1:0})}
                        id={"is_multiple_select"}/>
                    <label className="form-check-label" htmlFor={"is_multiple_select"}>
                        Is Multiple Select
                    </label>
                </div>
            </form>
            }
        </Dialog>

    );
};
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',

    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: '100%',
        border: '1px solid #ababab',
        borderRadius: 5,
        padding: 0,
        display: 'flex'
    }),
    container: () => ({

        width: '80%',
        position: 'relative',
        boxSizing: 'border-box',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

export default SurveyBuildForm;
