import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Pane, Dialog, Textarea, FilePicker } from 'evergreen-ui';
import axios from "axios";
import Select from "react-select";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import { Button, Table } from "react-bootstrap";
import { Avatar } from "evergreen-ui"
import helpers from "../../../assets/js/helper";
import { Divider } from "@material-ui/core";
import APP_CONSTANTS from "../../../assets/js/constants";
const cookies = new Cookies();
const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
export const PointPreview = (props) => {

    const [selectedFields, setSelectedFields] = useState([
        {
            id: 1,
            name: "name",
            selected: false,
            has_sub_name: false,
            value_name: "name",
            has_changes: false
        },
        {
            id: 2,
            name: "owner_name",
            selected: false,
            has_sub_name: false,
            value_name: "owner_name",
            has_changes: false
        },
        {
            id: 3,
            name: "owner_type",
            sub_name: "name",
            has_sub_name: true,
            selected: false,
            value_name: "owner_type_id"
        },
        {
            id: 4,
            name: "phone",
            has_sub_name: false,
            selected: false,
            value_name: "phone"
        },
        {
            id: 5,
            name: "near_by",
            has_sub_name: false,
            selected: false,
            value_name: "near_by"
        },
        {
            id: 6,
            name: "location_category",
            sub_name: "name",
            has_sub_name: true,
            selected: false,
            value_name: "location_category_id"
        },
        {
            id: 7,
            name: "classification",
            sub_name: "name",
            has_sub_name: true,
            selected: false,
            value_name: "classification_id"
        },
        {
            id: 8,
            name: "category",
            sub_name: "name",
            has_sub_name: true,
            selected: false,
            value_name: "category_id"
        },
        {
            id: 9,
            name: "point_type",
            sub_name: "name",
            has_sub_name: true,
            selected: false,
            value_name: "point_type_id"
        },
        {
            id: 10,
            name: "region",
            sub_name: "name",
            has_sub_name: true,
            selected: false,
            value_name: "region_id"
        },
        {
            id: 11,
            name: "latitude",
            has_sub_name: false,
            selected: false,
            value_name: "latitude"
        },
        {
            id: 11,
            name: "longitude",
            has_sub_name: false,
            selected: false,
            value_name: "longitude"
        },
        {
            id: 12,
            name: "has_delivery",
            has_sub_name: false,
            selected: false,
            value_name: "has_delivery"
        },
        {
            id: 13,
            name: "image",
            has_sub_name: false,
            selected: false,
            value_name: "image"
        },
        {
            id: 14,
            name: "description",
            has_sub_name: false,
            selected: false,
            value_name: "description"
        },
        {
            id: 15,
            name: "address",
            has_sub_name: false,
            selected: false,
            value_name: "address"
        },
        {
            id: 16,
            name: "covered_by",
            has_sub_name: true,
            sub_name: "full_name",
            selected: false,
            value_name: "covered_by"
        },
        {
            id: 17,
            name: "preferred_visit_time",
            has_sub_name: false,
            selected: false,
            value_name: "preferred_visit_time"
        }
    ]);
    const [loading, setloading] = useState(true);





    useEffect(() => {
        setloading(false)




        return () => {

        };
    }, [loading, props.showPointPreview]);


    return (
        loading ? <></> :
            <Dialog
                isShown={props.showPointPreview}
                onCloseComplete={() => props.setshowPointPreview(false)}
                hasHeader={true}
                title="View info"
                shouldCloseOnOverlayClick={false}
                hasFooter={false}
                width={'80%'}

                onConfirm={() => { }}
            >

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Field name</th>
                            <th>Point info</th>
                        </tr>
                    </thead>
                    <tbody>

                        {selectedFields.map((field) => {
                            if (field.value_name != "image" && field.value_name != "images" && field.value_name != "phones") {
                                try {
                                    var pointRequestTitle = field.has_sub_name && props.pointRequest[field.name] ? props.pointRequest[field.name][field.sub_name] : props.pointRequest[field.name]

                                }
                                catch (error) {
                                    console.log(field);
                                    console.log(error);
                                }
                                return (
                                    <tr>
                                        <td>{field.name} </td>
                                        <td >{pointRequestTitle}</td>
                                    </tr>)
                            }
                            else {
                                return null
                            }
                        })}

                        <tr>
                            <td>image</td>
                            <td>
                                <Avatar
                                    src={helpers.getImageUrl(props.pointRequest?.profile_image[0]?.thumbnail_image_url)}
                                    name={props.pointRequest?.name}
                                    size={40}
                                />
                            </td>

                        </tr>
                        {props.pointRequest?.other_images && props.pointRequest?.other_images.length>0?
                        <tr>
                            <td>Other images</td>
                            <td>
                            {props.pointRequest?.other_images.map((image)=>{
                                return <Avatar
                                src={helpers.getImageUrl(image?.thumbnail_image_url)}
                                name={props.pointRequest?.name}
                                size={40}
                            />
                            })}
                                
                            </td>

                        </tr>
                        :null}
                        {props.pointRequest?.phones && props.pointRequest?.phones.length>0?
                        <tr>
                            <td>Phones</td>
                            <td>
                            {props.pointRequest?.phones.map((phone)=>{
                                return <p>{"Name:"+phone.name+"- Phone:"+phone.phone+"- Job Title:"+phone.job_title}</p>
                            })}
                                
                            </td>

                        </tr>
                        :null}


                    </tbody>
                </Table>

                {props?.pointRequest?.point_orders?.length>0?
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Order ID</th>
                            <th>Bill Number</th>
                            <th>Bill Type</th>
                            <th>Balance</th>
                            <th>Customer Balance</th>
                            <th>Currency</th>
                            <th>Date</th>
                            <th>Bayan</th>
                        </tr>
                    </thead>
                    <tbody>

                        {props?.pointRequest?.point_orders.map((pointOrder) => {
                            return (
                                <tr>
                                    <td>{pointOrder.id}</td>
                                    <td>{pointOrder.bill_number}</td>
                                    <td>{pointOrder.bill_type}</td>
                                    <td>{pointOrder.balance}</td>
                                    <td>{pointOrder.customer_balance}</td>
                                    <td>{pointOrder.currency?.name}</td>
                                    <td>{pointOrder.created_at}</td>
                                    <td>{pointOrder.bayan}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                :null
                }


                <br />
                <br />
                <br />
                <br />


            </Dialog>

    );
};
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',

    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: '100%',
        border: '1px solid #ababab',
        borderRadius: 5,
        padding: 0,
        display: 'flex'
    }),
    container: () => ({

        width: '90%',
        position: 'relative',
        boxSizing: 'border-box',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

export default PointPreview;
