import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Pane, Dialog, Textarea,FilePicker } from 'evergreen-ui';
import axios from "axios";
import Select from "react-select";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import { useGlobalContext } from '../../../context'
import Autocomplete from '@mui/material/Autocomplete';
import {Box,TextField,IconButton} from '@mui/material';
import moment from "moment";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import CloseIcon from '@mui/icons-material/Close';

const cookies = new Cookies();
const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
export const PointFollowUpForm = (props) => {
    const [loading, setloading] = useState(false);
    const {followUpTypes,followUpMethods,users,user,setUser,points} = useGlobalContext()

    const [selectedFollowUpType,setSelectedFollowUpType]=useState(null)
    const [selectedFollowUpMethod,setSelectedFollowUpMethod]=useState(null)
    const [selectedPoint,setSelectedPoint]=useState(null)
    const [details,setDetails]=useState('')
    const [scheduleDate,setScheduleDate]=useState(null)
    
    const [object, setobject] = useState( {
        id: null,
        name: null,
        owner_name:null,
        owner_type_id:null,
        owner_type:{
            id:null,
            name:null,
        },
        phone:null,
        image:null,
        near_by:null,
        location_category_id:null,
        location_category:{
            id:null,
            name:null
        },
        classification_id:null,
        classifications:{
            id:null,
            name:null
        },
        category_id:null,
        category:{
            id:null,
            name:null
        },
        has_delivery:false,
        external_customer_id:null,
        visit_type_id:null,
        visit_type:{
            id:null,
            name:null
        },
        longitude:null,
        latitude:null,
        region_id:null,
        region:{
            id:null,
            name:null,
        },

    });
    
    const formatDate = (date) => {
        var d = new Date(date),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();
    
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return [year, month, day].join("-");
    };

    const submitForm = async () => {
        
        if(!details){
          toast.warn('You Must Entered Details')
          return
        }
        try {

            let formdata = new FormData();

            console.log(object);
            if(object.name)
            formdata.append("name", object.name)
            
            if (selectedFollowUpType?.id)
            formdata.append("follow_up_type_id", selectedFollowUpType?.id)
            if(selectedFollowUpMethod?.id)formdata.append("follow_up_method_id", selectedFollowUpMethod?.id)
            if(selectedPoint?.id)formdata.append("point_id", selectedPoint?.id)
            if(details)formdata.append("details", details)
            if(scheduleDate)formdata.append("schedule_date", formatDate(scheduleDate))

        
            if (props?.pointFollowUp&&props?.pointFollowUp?.id) {

                formdata.append("_method", "PUT");
                const resourcesResult = await axios({
                    url: Host + "point_follow_ups/"+props?.pointFollowUp.id,
                    method: "post",
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: formdata
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("updated ")
                        props.setshowPointFollowUpForm(false,true)
                    } else if (resourcesResult.data.status === false) {
                        props.setshowPointFollowUpForm(true,false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setshowPointFollowUpForm(true,false)
                }
                setloading(false)
            }
            else {
                const resourcesResult = await axios({
                    url: Host + "point_follow_ups",
                    method: "post",
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: formdata
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("saved ")
                        props.setshowPointFollowUpForm(false,true)
                        props.advanceSearch()
                    } else if (resourcesResult.status === 400) {
                        toast.error(resourcesResult.data)
                        props.setshowPointFollowUpForm(true,false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setshowPointFollowUpForm(true,false)
                }
                setloading(false)
            }
        } catch (error) {
            props.setshowPointFollowUpForm(true,false)
            const resourcesResult=error.response            
            if (resourcesResult && resourcesResult.status === 400) {
                if(typeof resourcesResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        resourcesResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    
                    toast.error(resourcesResult.data.data)
                }
                
                
            }
            else if(resourcesResult && resourcesResult.status === 401) {
             
                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
              
                toast.error("network error")
            }
        }
        
    };

    const handleInputChange = (event,has_delivery) => {
        const target = event.target;
        
        if(target.checked)
        {
            setobject({ ...object, has_delivery: 1 });
        }
        else
        {
            setobject({ ...object, has_delivery: 0 });
        }
        

    };

    const onImageChange = (fileList)=>{
        console.log(fileList);
        if(fileList && fileList.length>0)
        {
            setobject({
                ...object,
                image:fileList[0]
            })
        }

    }

    useEffect(() => {
        if(loading)
        // getResources()
        

        
        return () => {

        };
    }, [loading,props.showPointFollowUpForm]);

    const handelChangeDate=(date,number)=>{
        if(number==3){
         setScheduleDate(date)
        }
    }
    useEffect(()=>{
        if(props?.pointFollowUp){
          setSelectedFollowUpMethod(props?.pointFollowUp?.follow_up_method)
          setSelectedFollowUpType(props?.pointFollowUp?.follow_up_type)
          setSelectedPoint(props?.pointFollowUp?.point)
          setDetails(props?.pointFollowUp?.details)
          setScheduleDate(props?.pointFollowUp?.schedule_date)
        }

    },[props?.pointFollowUp])

    const clearScheduleDate=()=>{
        setScheduleDate(null)
    }

    return (
        loading ? <></> :
        <Dialog
                isShown={props.showPointFollowUpForm}
                onCloseComplete={() => props.setshowPointFollowUpForm(false)}
                hasHeader={true}
                shouldCloseOnOverlayClick={false}
                confirmLabel="Save"
                cancelLabel="Cancel"
                onConfirm={() => {submitForm()}}
                title="Add FollowUp for Customer"
                >
            {/* <p style={{fontSize:'30px',margin:'5px 10px 30px 10px'}}>Add FollowUp for Customer</p> */}
            <form >
                <div className="form-group">
                    <Box display="flex" sx={{ flexGrow: 2}}>                                    
                        <Autocomplete
                            sx={{
                                '& p,& span,& .MuiInputBase-input':{
                                    fontFamily:'Cairo-Bold',
                                    fontSize:14,
                                }
                            }}
                            fullWidth                            
                            options = {followUpTypes?followUpTypes:null}                    
                            getOptionLabel = {(option) => option.name || ""}
                            id="disable-close-on-select"                        
                            value = {selectedFollowUpType}
                            onChange={(e,v,r) => {setSelectedFollowUpType(v ? v:'')}}
                            renderInput={(params) => (
                            <TextField {...params} label="FollowUp Type" variant="outlined" sx={{
                                fontFamily:'Cairo-Bold',
                                '& p,& span':{
                                    fontFamily:'Cairo-Bold',
                                    fontSize:14,
                                }
                            }}/>
                            )}
                        />
                    </Box>
                </div>
                <div className="form-group">
                       <Autocomplete                                                 
                            sx={{
                                '& p,& span, & .MuiInputBase-input':{
                                    fontFamily:'Cairo-Bold',
                                    fontSize:14,
                                }
                            }}
                            fullWidth                            
                            options = {followUpMethods?followUpMethods:null}                            
                            getOptionLabel = {(option) => option.name || ""}
                            id="disable-close-on-select"                        
                            value = {selectedFollowUpMethod}
                            onChange={(e,v,r) => {setSelectedFollowUpMethod(v ? v:'');}}
                            renderInput={(params) => (
                            <TextField {...params} label="FollowUp methods" variant="outlined" sx={{
                                fontFamily:'Cairo-Bold',
                                '& p,& span':{
                                    fontFamily:'Cairo-Bold',
                                    fontSize:14,
                                }
                            }} />
                            )}
                        />
                </div>
                <div className="form-group">
                    <Autocomplete                                                 
                        sx={{
                            '& p,& span,& .MuiInputBase-input':{
                                fontFamily:'Cairo-Bold',
                                fontSize:14,
                            }
                        }}
                        fullWidth                            
                        options = {points?points:null}                            
                        getOptionLabel = {(option) => option?.name || ""}
                        id="disable-close-on-select"                        
                        value = {selectedPoint}
                        onChange={(e,v,r) => {setSelectedPoint(v ? v:'');}}
                        renderInput={(params) => (
                        <TextField {...params} label="Customer" variant="outlined" sx={{
                            fontFamily:'Cairo-Bold',
                            '& p,& span':{
                                fontFamily:'Cairo-Bold',
                            }
                         }}
                        />
                        )}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="name">Details</label>  
                    
                    <textarea className="form-control" 
                        id="near_by"
                        value={details}
                        onChange={event => { setDetails(event.target.value) }}
                     ></textarea>
                </div>
                <div className="form-group">
                    <LocalizationProvider dateAdapter={AdapterDayjs} sx={{width:'100%',position:'relative'}}>
                            <DesktopDatePicker
                            label="schedule Date"
                            inputFormat="MM/DD/YYYY"
                            value={scheduleDate}
                            minDate={new Date()}
                            onChange={(date)=>handelChangeDate(date,3)}
                            renderInput={(params) =>{
                            return(
                                <Box style={{position:'relative'}}>
                                   <TextField {...params}  sx={{width:'100%'}}/>
                                {
                                    scheduleDate?<CloseIcon size={16} 
                                     onClick={clearScheduleDate}
                                    sx={{
                                        color:'#000',
                                        position:'absolute',
                                        top:'30%',
                                        right:50,
                                        cursor:'pointer',
                                    }}/>:null
                                 }
                               </Box>
                             )
                        }}
                            />
                         

                    </LocalizationProvider>
                </div>

               
                
                
            </form>
        </Dialog>

    );
};
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',

    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: '100%',
        border: '1px solid #ababab',
        borderRadius: 5,
        padding: 0,
        display: 'flex'
    }),
    container: () => ({

        width: '100%',
        position: 'relative',
        boxSizing: 'border-box',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

export default PointFollowUpForm;
