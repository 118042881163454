import React, { useState, useEffect }  from 'react'
import PointsMap from './PointsMap'
import PointsMapTest from './PointsMapTest'

import { Dialog } from 'evergreen-ui';


export default function PointsMapDialog(props) {
    const submitForm = async () => {
        props.setshowPointsMapDialog(false)
    };

    // useEffect(() => {
    //     console.log('points')
    //     console.log(props.points)          
    // }, [])
    
  return (
      <> 
          {/* <div>PointsMapDialog</div> */}
      <Dialog
        isShown={props.showPointsMapDialog}
        onCloseComplete={() => props.setshowPointsMapDialog(false)}
        hasHeader={false}
        shouldCloseOnOverlayClick={false}
        confirmLabel="Save"
        cancelLabel="Cancel"
        width={"90%"}
        minHeightContent={"90%"}
        onConfirm={() => {submitForm()}}
      >          
          {/* <PointsMap points={props.points}/> */}
          <PointsMapTest/>
        </Dialog>
</>

  )
}
