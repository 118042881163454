const // host="http://iraq-gis.com:3001/"
    // host = "https://www.iraq-gis.com/ticket/";
    // host="http://localhost:3001/"
    // host="http://192.168.43.225:8000/api/"
    // host="http://192.168.80.41:8000/api/"
    // host="http://192.168.80.150:8000/api/"
    // host = "http://127.0.0.1:8000/api/";
    // host = "http://192.168.0.199:8000/api/"
    // host="http://127.0.0.1:8000/api/"
    host = "https://collections.iraq-gps.com/api/public/api/";
// host= "http://demo.jahizni.com/api/"
// host= "http://demo.data-crew.com/api/public/api/"
// const FrontEndDomain="http://dev.ticket.is/";
const FrontEndDomain = "http://collections.iraq-gps.com/";
// const FrontEndDomain="http://localhost:3000/";
//  const FrontEndDomain="http://192.168.80.176:3000/"
//  const FrontEndDomain="http://192.168.0.181:3000/"

export default host;
export { FrontEndDomain };
