import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";
import moment from "moment";
import CircularIndeterminate from "../GeneralComponents/CirularProgress";
import StyledDataGrid from "../GeneralComponents/StyledDataGrid";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import { useGlobalContext } from "../../../context";
import swal from "sweetalert";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import "../../../assets/css/DG.css";
import "../../../assets/css/AccordionStyle.css";
import * as Io from "react-icons/io";
import { Checkbox, FormControlLabel, FormGroup, Modal } from "@mui/material";
import Dropdown from "./DropDown";
import Cookies from "universal-cookie";

function Invoices() {
    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setpageNumber] = useState(1);
    const [inoviceTotal, setInoviceTotal] = useState(0);
    const [inoviceNumberOfPages, setInoviceNumberOfPages] = useState(0);

    const { loading, invoices, exportToCSV, clearPointsAdvanceSearchFields, fetchData, sendAccepted, sendRejected, sendRequest, getUsers } = useGlobalContext();

    const [scheduleDate, setScheduleDate] = useState(null);
    const [fromDate, setFormDate] = useState(null);
    const [toDate, setTODate] = useState(null);
    const [request, setRequested] = useState(null);
    const [accepted, setAccepted] = useState(null);
    const [rejected, setRejected] = useState(null);
    const [open, setOpen] = useState(false);
    const [usersData, setUsersData] = useState([]);
    const [selected, setSelected] = useState("");
    const [selectedInvoice, setSelectedInvoice] = useState([]);
    const cookies = new Cookies();
    const userId = cookies.get("user_id");

    const formatDate = (date) => {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    };

    const [accExpansion, setAccExpansion] = useState(false);

    const handleExpansion = (expansion) => setAccExpansion(expansion);

    const handlePageChange = (newPage) => {
        setpageNumber(newPage + 1);
    };

    const advanceSearch = async (type = "BROWSING") => {
        let params = {
            page: type === "SEARCH" ? 1 : pageNumber,
            page_size: pageSize,
        };
        if (fromDate) {
            params = {
                ...params,
                creation_date_from: formatDate(fromDate),
            };
        }
        if (toDate) {
            params = {
                ...params,
                creation_date_to: formatDate(toDate),
            };
        }
        if (request) {
            params = {
                ...params,
                request: request,
            };
        }
        if (accepted) {
            params = {
                ...params,
                accepted: accepted,
            };
        }
        if (rejected) {
            params = {
                ...params,
                rejected: rejected,
            };
        }
        const fetchedData = await fetchData("INVOICES", "my_all_invoices_for_user", {
            ...params,
        });
        return fetchedData;
    };

    const handleClose = () => {
        setOpen(false);
    };

    const gettingUsers = async () => {
        let users = await getUsers();
        let filteredDate = await users?.data?.filter((user) => user?.id !== parseInt(userId) && user?.department.id === 2);
        setUsersData(users.data);
        // setUsersData(filteredDate);
    };

    useEffect(() => {
        fetchData("INVOICES", "my_all_invoices_for_user");
        gettingUsers();
        advanceSearch();
    }, [pageSize, pageNumber]);

    //Reformatting points object once points are loaded
    useEffect(() => {
        if (invoices && invoices.data && invoices.data.length > 0) {
            const reformatVisits = invoices.data.map((rowVisit) => {
                return {
                    ...rowVisit,
                    userId: userId,
                };
            });
            setRow(reformatVisits);
            setInoviceTotal(invoices.total);
            setInoviceNumberOfPages(invoices.last_page);
        } else {
            setRow([]);
            setInoviceTotal(0);
            setInoviceNumberOfPages(0);
        }
    }, [invoices]);

    const [rows, setRow] = useState([]);
    const [columns] = useState([
        { field: "id", headerName: "ID", minWidth: 50 },
        {
            field: "name",
            headerName: "Invoice",
            minWidth: 350,
            renderCell: (rows) => (
                <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                    <span>{rows.row.point.name}</span>
                </div>
            ),
        },
        {
            field: "bayan",
            headerName: "Bill No.",
            minWidth: 100,
            renderCell: (rows) => (
                <div
                    style={{
                        width: "100%",
                        overflowWrap: "break-word",
                        wordWrap: "break-word",
                        hyphens: "auto",
                        whiteSpace: "normal", //this is the one that gets you all the time,
                        display: "flex",
                        flexWrap: "wrap",
                        // inlineSize: '299px',
                    }}>
                    {rows.row.bill_number}
                    <br />
                </div>
            ),
        },
        {
            field: "bill_type",
            headerName: "Bill Type",
            minWidth: 230,
            renderCell: (rows) => (
                <div style={{ width: "100%" }}>
                    {rows.row.bill_type}
                    <br />
                </div>
            ),
        },
        {
            field: "amount",
            headerName: "Amount",
            minWidth: 150,
            renderCell: (rows) => (
                <div style={{ width: "100%", display: "flex" }}>
                    <span>{rows.row.currency_id === 3 ? "$" : "دينار"} </span>
                    <span> {rows.row.amount}</span>
                </div>
            ),
        },
        {
            field: "creation_date",
            headerName: "Creation Date",
            minWidth: 150,
            renderCell: (rows) => (
                <div style={{ width: "100%" }}>
                    {rows.row.created_at
                        ? moment(rows.row.created_at).format("YYYY-MM-DD HH:mm:ss")
                            ? moment(rows.row.created_at).format("YYYY-MM-DD HH:mm:ss")?.slice(0, 10)
                            : null
                        : null}
                    <br />
                    {rows.row.created_at
                        ? moment(rows.row.created_at).format("YYYY-MM-DD HH:mm:ss")
                            ? moment(rows.row.created_at).format("YYYY-MM-DD HH:mm:ss")?.slice(10, 18)
                            : null
                        : null}
                </div>
            ),
        },
        {
            field: "actions",
            width: 300,
            headerName: "Actions",
            renderCell: (rows) => (
                <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                    {rows.row?.lastpoint_order_transfer?.last_transfer === 1 && rows.row?.lastpoint_order_transfer?.accepted !== 1 ? null : (
                        <Button
                            onClick={() => {
                                setSelectedInvoice(rows.row.id);
                                setOpen(true);
                            }}
                            style={{
                                backgroundColor: "#2C74B3",
                                height: 37,
                                width: 70,
                            }}>
                            <Io.IoIosRepeat size={30} color={"#fff"} />
                        </Button>
                    )}
                    {rows.row?.lastpoint_order_transfer?.to_user?.id !== parseInt(userId) ? null : rows.row?.lastpoint_order_transfer?.accepted === 0 &&
                      rows.row?.lastpoint_order_transfer?.rejected === 0 ? (
                        <div
                            style={{
                                display: "block",
                                flex: 1,
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}>
                            <Button
                                style={{
                                    backgroundColor: "#06a151",
                                    color: "white",
                                    marginLeft: 10,
                                    marginLeft: 0,
                                }}
                                onClick={() => {
                                    sendAccepted(rows.row?.lastpoint_order_transfer?.id);
                                }}>
                                قبول الطلب
                            </Button>
                            <Button
                                style={{
                                    backgroundColor: "#ff3744",
                                    color: "white",
                                    margin: 10,
                                }}
                                onClick={() => {
                                    sendRejected(rows.row?.lastpoint_order_transfer?.id);
                                }}>
                                رفض الطلب
                            </Button>
                        </div>
                    ) : null}
                    {rows.row?.lastpoint_order_transfer?.from_user?.id !== parseInt(userId) ? null : (rows.row?.lastpoint_order_transfer?.accepted ||
                          rows.row?.lastpoint_order_transfer?.rejected) === 1 ? (
                        rows.row?.lastpoint_order_transfer?.accepted === 1 ? null : (
                            <p
                                style={{
                                    display: "flex",
                                    marginLeft: 20,
                                    marginTop: 15,
                                }}>
                                تم رفض تحويل الطلب بوساطة: {rows.row?.lastpoint_order_transfer?.to_user?.full_name}
                            </p>
                        )
                    ) : (rows.row?.lastpoint_order_transfer?.accepted && rows.row?.lastpoint_order_transfer?.rejected) === 0 ? (
                        <p
                            style={{
                                display: "flex",
                                marginTop: 15,
                            }}>
                            يتم انتظار الرد من: {rows.row?.lastpoint_order_transfer?.to_user?.full_name}
                        </p>
                    ) : null}
                </div>
            ),
        },
    ]);

    const handelChangeDate = (date, number) => {
        if (number == 1) {
            setFormDate(date);
        } else if (number == 2) {
            setTODate(date);
        } else {
            setScheduleDate(date);
        }
    };

    const getDataForExported = async () => {
        let data = {
            page_size: inoviceTotal,
        };

        if (scheduleDate) {
            data = {
                ...data,
                schedule_date: formatDate(scheduleDate),
            };
        }
        if (fromDate) {
            data = {
                ...data,
                creation_date_from: formatDate(fromDate),
            };
        }
        if (toDate) {
            data = {
                ...data,
                creation_date_to: formatDate(toDate),
            };
        }
        const fetchedData = await fetchData("INVOICES", "my_all_invoices_for_user", {
            ...data,
        });
        return fetchedData;
    };

    const exportDataTOExcel = async () => {
        let data = await getDataForExported();
        var arr = [];
        if (data) {
            data?.data &&
                data?.data?.length > 0 &&
                data?.data?.map((itm) => {
                    arr.push({
                        Id: itm?.id,
                        User_Name: itm?.created_by?.full_name,
                        Customer_Name: itm?.point?.name,
                        Bill_type: itm?.bill_type,
                        Bill_number: itm?.bill_number,
                        Bayan: itm?.bayan,
                        Creation_Date: itm?.created_at ? moment(itm.created_at).format("YYYY-MM-DD HH:mm:ss") : null,
                    });
                });
        }
        exportToCSV(arr, "Invoices");
    };

    return (
        <>
            <Box display='flex'>
                <Button
                    display='flex'
                    startIcon={<FileDownloadIcon />}
                    variant='contained'
                    sx={{ marginRight: 1, marginBottom: accExpansion ? "0" : "20px" }}
                    onClick={exportDataTOExcel}>
                    Export
                </Button>
                <Accordion display='flex' sx={{ flexGrow: 4 }} onChange={(event, expansion) => handleExpansion(expansion)}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />} aria-controls='panel1a-content' id='panel1a-header'>
                        <Typography>Advance Search</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box component='span' display='flex' bgcolor='white' flexDirection='column' sx={{ borderRadius: 1 }}>
                            <Box sx={{ m: 1 }} component='span' display='flex' bgcolor='white' flexDirection='row'>
                                <Box display='flex' sx={{ flexGrow: 2, alignItems: "center" }}>
                                    <Box
                                        sx={{
                                            width: "50%",
                                            ml: 1,
                                            mr: 2,
                                            pr: 2,
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DesktopDatePicker
                                                label='From Date'
                                                inputFormat='MM/DD/YYYY'
                                                value={fromDate}
                                                onChange={(date) => handelChangeDate(date, 1)}
                                                renderInput={(params) => <TextField {...params} style={{ width: "50%" }} />}
                                            />
                                            <Box style={{ width: "10%" }}></Box>
                                            <DesktopDatePicker
                                                label='to Date'
                                                inputFormat='MM/DD/YYYY'
                                                value={toDate}
                                                onChange={(date) => handelChangeDate(date, 2)}
                                                renderInput={(params) => <TextField {...params} style={{ width: "50%" }} />}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                    <FormGroup sx={{ flexDirection: "row", gap: 2 }}>
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            label={<Typography style={{ color: "black" }}>حسب الطلب</Typography>}
                                            onChange={() => {
                                                if (!request) {
                                                    setRequested(1);
                                                    return;
                                                }
                                                setRequested(null);
                                            }}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            label={<Typography style={{ color: "black" }}>المقبولة</Typography>}
                                            onChange={() => {
                                                if (!accepted) {
                                                    setAccepted(1);
                                                    return;
                                                }
                                                setAccepted(null);
                                            }}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            label={<Typography style={{ color: "black" }}>المرفوضة</Typography>}
                                            onChange={() => {
                                                if (!rejected) {
                                                    setRejected(1);
                                                    return;
                                                }
                                                setRejected(null);
                                            }}
                                        />
                                    </FormGroup>
                                </Box>

                                <Box display='flex' sx={{ flexGrow: 1 }}>
                                    <Button variant='contained' spacing={2} sx={{ flexGrow: 1, margin: 1 }} onClick={() => advanceSearch("SEARCH")}>
                                        Search
                                    </Button>
                                    <Button
                                        variant='contained'
                                        spacing={2}
                                        sx={{ flexGrow: 1, margin: 1 }}
                                        color='secondary'
                                        onClick={clearPointsAdvanceSearchFields}>
                                        Clear
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>

            <Box
                sx={{
                    height: "calc(100% - 9em)",
                    "& .MuiDataGrid-menuIcon,& .MuiDataGrid-iconButtonContainer,& .MuiDataGrid-columnSeparator": {
                        display: "none !important",
                    },
                }}>
                <StyledDataGrid
                    rows={rows}
                    columns={columns}
                    rowCount={inoviceTotal}
                    pageSize={pageSize}
                    page={pageNumber - 1}
                    onPageSizeChange={(newPageSize) => {
                        setPageSize(newPageSize);
                    }}
                    rowsPerPageOptions={[5, 10, 20]}
                    paginationMode='server'
                    onPageChange={(params) => {
                        handlePageChange(params);
                    }}
                    hideIconeInHeader={true}
                />
                <Box width='45%' m='auto'>
                    <Pagination
                        color='primary'
                        size='large'
                        count={inoviceNumberOfPages}
                        page={pageNumber}
                        showFirstButton
                        showLastButton
                        onChange={(e, p) => {
                            setpageNumber(p);
                        }}
                    />
                </Box>
                <div
                    style={{
                        width: "50%",
                        position: "absolute",
                        top: "100px",
                        margin: "0 auto",
                        left: "50%",
                        top: "50%",
                    }}>
                    {loading && <CircularIndeterminate />}
                </div>
                <Modal onClose={handleClose} open={open} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Box
                        sx={{
                            width: 400,
                            height: 100,
                            bgcolor: "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                            borderRadius: "8px",
                        }}>
                        <Button
                            onClick={() => {
                                if (!selected) {
                                    swal("يرجى اختيار مستخدم");
                                } else {
                                    sendRequest(selectedInvoice, selected);
                                    setOpen(false);
                                }
                            }}
                            style={{
                                backgroundColor: "#2C74B3",
                                height: 50,
                            }}>
                            <Io.IoIosRepeat size={24} color={"#fff"} />
                        </Button>
                        <Dropdown users={usersData} selected={selected} setSelected={setSelected} />
                    </Box>
                </Modal>
            </Box>
        </>
    );
}

export default Invoices;
