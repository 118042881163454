
import React, { useEffect, useState } from 'react';
import PointForm from './PointForm';
import PointMapDialog from './PointMapDialog';
import PointsMapDialog from './PointsMapDialog';
import host from '../../../assets/js/Host';
import PointPreview from '../PointRequests/PointPreview';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import IconButton from "@material-ui/core/IconButton";
import PreviewRoundedIcon from '@mui/icons-material/PreviewRounded';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import MapRoundedIcon from '@mui/icons-material/MapRounded';
import PointsMapTest from './PointsMapTest'
import PointImportForm from "./PointImportForm";
import { toast } from 'react-toastify';

import Tooltip from '@mui/material/Tooltip';
import CircularIndeterminate from '../GeneralComponents/CirularProgress'
import SearchField1 from '../GeneralComponents/SearchField1'
// import ExportCSV from  '../GeneralComponents/ExportCSV'
import StyledDataGrid from '../GeneralComponents/StyledDataGrid'

import "../../../assets/css/DG.css"
import "../../../assets/css/AccordionStyle.css"
import red from '@material-ui/core/colors/red';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
// import { withStyles } from "@material-ui/core/styles";

// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
// import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
// import ClearIcon from '@mui/icons-material/Clear';
// import InputAdornment from '@mui/material/InputAdornment';
// import FilledInput from '@mui/material/FilledInput';
import Pagination from '@mui/material/Pagination';

import { useGlobalContext } from '../../../context'

// const cookies = new Cookies();
// const token = cookies.get("token")
// const language = cookies.get("language")?cookies.get("language"):"ar"
// const BlackTextTypography = withStyles({
//     root: {
//       color: "#000000"
//     }
//   })(Typography);

//   const StyledDataGrid = withStyles({
//     root: {
//       "& .MuiDataGrid-renderingZone": {
//         maxHeight: "none !important"
//       },
//       "& .MuiDataGrid-cell": {
//         lineHeight: "unset !important",
//         maxHeight: "none !important",
//         whiteSpace: "normal"
//       },
//       "& .MuiDataGrid-row": {
//         maxHeight: "none !important"
//       }
//     }
//   })(DataGrid);

function Point() {
    // const [loading, setloading] = useState(false);
    // const [state, setData] = useState({
    //     points: ''
    // });
    const [showPointImportForm, setshowPointImportForm] = useState(false);
    const [showPointForm, setshowPointForm] = useState(false);
    const [showPointMapDialog, setshowPointMapDialog] = useState(false)
    const [showPointsMapDialog, setshowPointsMapDialog] = useState(false)

    const [showPointPreview, setshowPointPreview] = useState(false)
    const [selectedPoint, setselectedPoint] = useState(null);
    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setpageNumber] = useState(1);
    const [pointsTotal, setPointsTotal] = useState(0)
    const [pointsNumberOfPages, setPointsNumberOfPages] = useState(0)
    const { loading, pointsStatuses, pointStatus, setPointStatus, exportToCSV, allPoints, points, district, region, districts, regions, ownerName, shopName, clearPointsAdvanceSearchFields, setRegion, setDistrict, setShopName, setOwnerName, fetchData, deleteObject } = useGlobalContext()

    const [accExpansion, setAccExpansion] = useState(false)

    const handlePageChange = (newPage) => {
        setpageNumber(newPage + 1)
    }

    const updateObject = async (object) => {
        setselectedPoint(object)
        setshowPointForm(true)
    };
    const previewObject = async (object) => {
        setselectedPoint(object)
        setshowPointPreview(true)
    }
    const importObjects = async () => {
        setselectedPoint(null)
        setshowPointImportForm(true)
    }
    const handleSetshowPointImportForm = (openStatus, needsReload) => {
        if (needsReload)
            fetchData('POINTS', 'points', {
                page: pageNumber,
                page_size: pageSize,
                shop_name: shopName,
                owner_name: ownerName,
                district: district.id,
                region: region.id,
                point_type_id: pointStatus.map((status) => status.id),
            });
        setshowPointImportForm(openStatus)
    };
    const handleExpansion = (expansion) => setAccExpansion(expansion)
    // const deleteObject = async (object) => {
    //     if (!window.confirm("Delete the item?")) {
    //         return
    //     }
    //     // setloading(true)
    //     try {
    //         const result=await axios({
    //             url: Host + "points/"+object.id,
    //             method: "delete",
    //             headers: {
    //             "Authorization" : `Bearer ${token}`,
    //                 Accept:"application/json",
    //                 'X-localization':language,
    //             }        
    //         })
    //         if(result && result.status === 200)
    //         {
    //             toast.error(result.data.data)
    //             fetchData();
    //         } else if (result.data.status === false) {
    //             toast.error("Error")
    //         }
    //     } catch (error) {
    //         const responseResult=error.response            
    //         if (responseResult && responseResult.status === 400) {
    //             if(typeof responseResult.data.data === "object")
    //             {
    //                 const errorMessagesKeys = Object.keys(responseResult.data.data);
    //                 errorMessagesKeys.map((key)=>{

    //                     responseResult.data.data[key].map((message)=>{
    //                         toast.error(message)
    //                     })
    //                 })
    //             }
    //             else{
    //                 toast.error(responseResult.data.data)
    //             }

    //         }
    //         else if(responseResult && responseResult.status === 401) {
    //             toast.error(responseResult.data.data)
    //             cookies.remove("token");
    //             window.location.href = "/"
    //         }
    //         else
    //         {
    //             toast.error("network error")
    //         }
    //     }
    //     // setloading(false)
    // };

    const createObject = async (object) => {
        setselectedPoint(null)
        setshowPointForm(true)
    };

    const handleSetshowPointForm = (openStatus, needsReload) => {
        if (needsReload)
            // fetchData();       
            fetchData('POINTS', 'points', {
                page: pageNumber,
                page_size: pageSize,
                shop_name: shopName,
                owner_name: ownerName,
                district: district.id,
                region: region.id,
                point_type_id: pointStatus.map((status) => status.id),
            });
        setshowPointForm(openStatus)
    };

    const handleSetshowPointPreview = (openStatus, needsReload) => {
        if (needsReload)
            // fetchData();
            fetchData('POINTS', 'points', {
                page: pageNumber,
                page_size: pageSize,
                shop_name: shopName,
                owner_name: ownerName,
                district: district.id,
                region: region.id
            });
        setshowPointPreview(openStatus)
    }

    const mapObject = async (object) => {
        setselectedPoint(object)
        setshowPointMapDialog(true)
    }
    const [mapPoints, setMapPoints] = useState([])

    const mapObjects = async (object) => {

        if (object.data.length > 3000) {
            return (
                toast.error(`Too many points to display!`)
            );
        }
        setMapPoints(object.data)
        setshowPointsMapDialog(true)
    }

    const handleSetshowPointMapDialog = (openStatus, needsReload) => {
        if (needsReload)
            advanceSearch()
        setshowPointMapDialog(openStatus)
    }

    const handleSetshowPointsMapDialog = (openStatus, needsReload) => {
        if (needsReload)
            advanceSearch()
        setshowPointsMapDialog(openStatus)
    }
    const getImageUrl = (image_url) => {
        var hostURLParts = host.split("/")
        var hostURL = hostURLParts[0] + "//" + hostURLParts[2] + "/"
        const fullImageURL = hostURL + image_url

        return fullImageURL;
    }

    const advanceSearch = () => {

        fetchData('POINTS', 'points', {
            page: pageNumber,
            page_size: pageSize,
            shop_name: shopName,
            owner_name: ownerName,
            district: district.id,
            region: region.id,
            point_type_id: pointStatus.map((status) => status.id),
        });
    }

    //Initial fetching for data
    useEffect(() => {
        fetchData('DISTRICTS', 'districts_list')
        fetchData('REGIONS', 'regions_list')
        fetchData('POINT_STATUSES', 'point_types_list')
        //to fetch points
        advanceSearch()

    }, [pageSize, pageNumber])

    //Reformatting points object once points are loaded
    useEffect(() => {

        if (points && points.data && points.data.length > 0) {
            // console.log(points.data)
            const reformatPoints = points.data.map((rowPoint) => {
                return {
                    id: rowPoint.id,
                    name: rowPoint.name,
                    owner_name: rowPoint.owner_name,
                    owner_type: rowPoint?.owner_type?.name,
                    phone: rowPoint?.phone,
                    region: rowPoint?.region?.name,
                    district: rowPoint?.region?.district_id === 1 ? "كرخ" : "رصافة",
                    object1: rowPoint
                }
            })
            setRow(reformatPoints);
            //  console.log(points.total)
            setPointsTotal(points.total)
            setPointsNumberOfPages(points.last_page)
        }else{
            setRow([]);                
            setPointsTotal(0)    
            setPointsNumberOfPages(0) 
        }
    }, [points])

    const [columns] = useState([
        { field: 'id', headerName: 'ID', flex: .1 },
        { field: 'district', headerName: 'District', flex: .5 },
        { field: 'region', headerName: 'Region', flex: 3 },
        { field: 'name', headerName: 'Name', flex: 3 },
        {
            field: "object1",
            flex: 1.5,
            headerName: "Owner Details",
            renderCell: (rows) => (
                <div style={{ width: '100%' }} >
                    {rows.value.owner_name}<br />
                    {rows.value.phone}
                </div>
            )
        },

        { field: 'owner_type', headerName: 'Owner Type', flex: 1.5 },
        // { field: 'phone', headerName: 'Phone' , flex: 1},        
        {
            field: 'actions', flex: 2,
            headerName: 'Actions',
            renderCell: (rows) => (
                <strong>
                    <IconButton
                        size="small"
                        style={{ marginLeft: 0 }}
                        onClick={() => {
                            mapObject(rows.row.object1)
                        }}>
                        <Tooltip title="Locate">
                            <LocationOnRoundedIcon style={{ fill: "#5cb85c" }} />
                        </Tooltip>
                    </IconButton>

                    <IconButton
                        style={{ marginLeft: 15 }}
                        aria-label="view"
                        size="small"
                        onClick={() => {
                            previewObject(rows.row.object1)
                        }}
                    >
                        <Tooltip title="View">
                            <RemoveRedEyeRoundedIcon color="primary" />
                        </Tooltip>
                    </IconButton>

                    <IconButton
                        style={{ marginLeft: 15 }}
                        aria-label="edit"
                        size="small"
                        onClick={() => {
                            updateObject(rows.row.object1)
                        }}
                    >
                        <Tooltip title="Edit">
                            <ModeEditOutlineRoundedIcon style={{ fill: "#deb12c" }} />
                        </Tooltip>
                    </IconButton>

                    <IconButton
                        style={{ marginLeft: 20 }}
                        aria-label="delete"
                        size="small"
                        onClick={() => {
                            deleteObject('POINTS', 'point', rows.row.object1)
                        }}
                    >
                        <Tooltip title="Delete">
                            <DeleteRoundedIcon sx={{ color: red[500] }} />
                        </Tooltip>
                    </IconButton>
                </strong>
            ),
        },
    ]);

    const [rows, setRow] = useState([])

    return (

        <>
            {showPointImportForm ?

                <PointImportForm showPointImportForm={showPointImportForm} setshowPointImportForm={handleSetshowPointImportForm} />
                : null}
            {showPointForm ?
                <PointForm point={selectedPoint} showPointForm={showPointForm} setshowPointForm={handleSetshowPointForm} />
                : null}
            {showPointMapDialog ?
                <PointMapDialog point={selectedPoint} showPointMapDialog={showPointMapDialog} setshowPointMapDialog={handleSetshowPointMapDialog} />
                : null}
            {showPointPreview ?
                <PointPreview pointRequest={selectedPoint} showPointPreview={showPointPreview} setshowPointPreview={handleSetshowPointPreview} />
                : null}
            {showPointsMapDialog ?
                <PointsMapDialog points={mapPoints} showPointsMapDialog={showPointsMapDialog} setshowPointsMapDialog={handleSetshowPointsMapDialog} />
                : null}

            <Box display="flex" >
                <Button display="flex"
                    startIcon={<FileDownloadIcon />}
                    variant="contained"
                    sx={{ marginRight: 1, marginBottom: accExpansion ? "0" : "20px" }}
                    onClick={async () => {
                        const fetchedData = await fetchData('ALL_POINTS', 'points', {
                            page_size: pointsTotal,
                            shop_name: shopName,
                            owner_name: ownerName,
                            district: district.id,
                            region: region.id
                        })
                        exportToCSV(fetchedData.data, 'points')
                    }
                    }>
                    Export
            </Button>
                {/* <Button display="flex"
                    startIcon={<FileUploadIcon />}
                    variant="contained"
                    sx={{ marginRight: 1, marginBottom: accExpansion ? "0" : "20px" }}
                    onClick={async () => {
                        importObjects()
                    }
                    }>
                    Import
            </Button> */}

                <Button display="flex"
                    startIcon={<AddCircleRoundedIcon />}
                    variant="contained"
                    sx={{ marginRight: 1, marginBottom: accExpansion ? "0" : "20px" }}
                    onClick={() => { createObject() }}>
                    Create
            </Button>

                <Button display="flex"
                    startIcon={<MapRoundedIcon />}
                    variant="contained"
                    sx={{ marginRight: 1, marginBottom: accExpansion ? "0" : "20px" }}
                    onClick={async () => {
                        const fetchedData = await fetchData('ALL_POINTS', 'points', {
                            page_size: pointsTotal,
                            shop_name: shopName,
                            owner_name: ownerName,
                            district: district.id,
                            region: region.id,
                            point_type_id: pointStatus.map((status) => status.id),
                        })
                        mapObjects(fetchedData)
                    }
                    }>
                    Map
            </Button>
                {/* <ExportCSV csvData={points.data} fileName={"test"} /> */}
                <Accordion display="flex" sx={{ flexGrow: 4 }}
                    onChange={(event, expansion) => handleExpansion(expansion)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Advance Search</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box component="span" display="flex" bgcolor="white" flexDirection="column" sx={{ borderRadius: 1 }}>
                            <Box sx={{ m: 1 }} component="span" display="flex" bgcolor="white" flexDirection="row" >
                                <Box display="flex" sx={{ flexGrow: 2 }}>
                                    <Autocomplete
                                        sx={{ ml: 1 }}
                                        fullWidth
                                        options={districts}
                                        getOptionLabel={(option) => option.name || ""}
                                        id="disable-close-on-select"
                                        value={district}
                                        onChange={(e, v, r) => { setDistrict(v ? v : '') }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Districts" variant="outlined" />
                                        )}
                                    />
                                    <Autocomplete
                                        sx={{ ml: 1 }}
                                        fullWidth
                                        options={regions}
                                        getOptionLabel={(option) => option.name || ""}
                                        id="disable-close-on-select"
                                        value={region}
                                        onChange={(e, v, r) => { setRegion(v ? v : '') }}

                                        renderInput={(params) => (
                                            <TextField {...params} label="Regions" variant="outlined" />
                                        )}
                                    />
                                </Box>
                                <Box display="flex" sx={{ flexGrow: 1 }}>
                                    <Button
                                        variant="contained"
                                        spacing={2} sx={{ flexGrow: 1, marginLeft: 1, marginRight: 1, height: 55 }}
                                        onClick={advanceSearch} >
                                        Search
                                </Button>
                                    <Button variant="contained"
                                        spacing={2}
                                        sx={{ flexGrow: 1, margin: 0, height: 55 }}
                                        color="secondary"
                                        onClick={clearPointsAdvanceSearchFields} >
                                        Clear
                                </Button>
                                </Box>
                            </Box>

                            <Box sx={{ m: 1 }} component="span" display="flex" bgcolor="white" flexDirection="row">
                                <SearchField1 state={shopName} setState={setShopName} fieldLabel='Shop Name' />
                                <SearchField1 state={ownerName} setState={setOwnerName} fieldLabel='Owner Name' />
                                <Autocomplete
                                    sx={{ ml: 1 }}
                                    multiple
                                    fullWidth
                                    options={pointsStatuses}
                                    getOptionLabel={(option) => option.name || ""}
                                    id="disable-close-on-select"
                                    value={pointStatus}
                                    onChange={(e, v, r) => { setPointStatus(v ? v : []); }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Point Status" variant="outlined" />
                                    )}
                                />

                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
            <div style={{ height: 'calc(100% - 9em)' }}>
                <StyledDataGrid
                    rows={rows}
                    columns={columns}
                    rowCount={points?.total ? points?.total : 0}
                    pageSize={pageSize}
                    page={pageNumber - 1}
                    onPageSizeChange={(newPageSize) => {
                        setPageSize(newPageSize)
                    }}

                    rowsPerPageOptions={[5, 10, 20]}
                    // pagination
                    paginationMode="server"
                    onPageChange={(params) => {
                        handlePageChange(params)
                    }}
                />
                <Box width="45%" m="auto">
                    <Pagination
                        color="primary"
                        size="large"
                        count={pointsNumberOfPages}
                        page={pageNumber}
                        showFirstButton
                        showLastButton
                        onChange={(e, p) => {
                            setpageNumber(p)
                        }}
                    />
                </Box>

                <div style={{ width: '50%', position: 'absolute', top: '100px', margin: '0 auto', left: '50%', top: '50%' }}>
                    {loading && (
                        <CircularIndeterminate />
                    )}
                </div>
            </div>
        </>
    );
}

export default Point;


