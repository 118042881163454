import React, {useEffect,useState} from 'react';
import Cookies from "universal-cookie";
import axios from "axios";
import {Avatar} from "evergreen-ui"
import host from "../../../assets/js/Host";
import { toast } from 'react-toastify';

import PointRequestForm from './PointRequestForm';
import PointRequestMapDialog from './PointRequestMapDialog';
// import host from '../../../assets/js/Host';
import moment from 'moment';
import helpers from '../../../assets/js/helper';
import APP_CONSTANTS from '../../../assets/js/constants';
import PointPreview from './PointPreview';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from "@material-ui/core/IconButton";
// import PreviewRoundedIcon from '@mui/icons-material/PreviewRounded';
import SettingsApplicationsRoundedIcon from '@mui/icons-material/SettingsApplicationsRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
// import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

import Tooltip from '@mui/material/Tooltip';
import CircularIndeterminate from  '../GeneralComponents/CirularProgress'
import SearchField1 from  '../GeneralComponents/SearchField1'

import "../../../assets/css/DG.css"
import "../../../assets/css/AccordionStyle.css"
import red from '@material-ui/core/colors/red';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
// import { DataGrid,
//     gridPageCountSelector,
//     gridPageSelector,
//     useGridApiContext,
//     useGridSelector,
//     gridPageSizeSelector } from '@mui/x-data-grid';

import Pagination from '@mui/material/Pagination';
import TablePagination from '@mui/material/TablePagination';
import StyledDataGrid from '../GeneralComponents/StyledDataGrid'
import Autocomplete from '@mui/material/Autocomplete';

import BasicDateRangePicker from '../GeneralComponents/DateFromTo'

import { useGlobalContext } from '../../../context'

// const cookies = new Cookies();
// const token = cookies.get("token")
// const language = cookies.get("language")?cookies.get("language"):"ar"

// function CustomPagination() {
//     const apiRef = useGridApiContext();
//     const page = useGridSelector(apiRef, gridPageSelector);
//     const pageCount = useGridSelector(apiRef, gridPageCountSelector);
//     const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

//     return (
//         <Box component="span" display = 'flex' sx={{ width: '100%',justifyContent: 'space-between',alignItems: 'center' }} flexDirection="row">
//             <TablePagination 
//                 component="div"
//                 count={pageCount}
//                 page= {page + 1}
//                 onPageChange={(event, value) => {                                        
//                     apiRef.current.setPage(value - 1);
//                 }}                
//                 rowsPerPage= "5" //{rowsPerPage}
//                 rowsPerPageOptions={[5, 10, 20]}
//                 onRowsPerPageChange={(event, value) => {                    
//                     console.log(pageSize)
//                     console.log(value.props.value)
//                     apiRef.current.setPageSize(10);
//                 }}
//             />
//             <Pagination
//                 color="primary"
//                 count={pageCount}
//                 page={page + 1}
//                 onChange={(event, value) => apiRef.current.setPage(value - 1)}
//                 showFirstButton
//                 showLastButton 
//             />            
//         </Box>
//     );
//   }


function PointRequest() {
    const currentDate = new Date();
    const options = { timeZone: "Asia/Baghdad" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(currentDate);
    
    const [rows,setRow] = useState([])
    const [comparePoint,setcomparePoint] = useState(null)
    
    const [showPointRequestForm, setshowPointRequestForm] = useState(false);
    const [showPointPreview, setshowPointPreview] = useState(false);
    const [showPointRequestMapDialog,setshowPointRequestMapDialog] = useState(false)
    const [selectedPointRequest, setselectedPointRequest] = useState(null);

    const [pageSize, setPageSize] = useState(20);    
    const [pageNumber, setpageNumber] = useState(1);
    const [pointsTotal, setPointsTotal] = useState(0)
    const [pointsNumberOfPages, setPointsNumberOfPages] = useState(0)

    const {loading,exportToCSV,setPointsRequestsUpdate,selectedPointRequestStatus,pointRequestStatus,pointsRequestsUpdate,fromToDate,user,district,region,users,pointsRequestsStatuses,regions,ownerName,shopName,clearPointsAdvanceSearchFields,setSelectedPointRequestStatus,setPointRequestStatus,setRegion,setUser,pointsRequests,fetchData,deleteObject} = useGlobalContext()        

    const [accExpansion,setAccExpansion] = useState(false)

    const [fromDate, setFromDate] = useState(formattedDate);
    const [toDate, setTODate] = useState(formattedDate);
    
    const handelChangeDate = (date, number) => {
        if (number == 1) {
            setFromDate(date);
        } else if (number == 2) {
            setTODate(date);
        }
    };


    // const fetchDataById = async (object) => {        
    //     try {
    //         const result=await axios({
    //             url: host + "points/" + object.point_id,
    //             method: "get",
    //             headers: {
    //             "Authorization" : `Bearer ${token}`,
    //                 Accept:"application/json",
    //                 'X-localization':language,
    //             }    
    //         })
    //         if(result && result.status === 200)
    //         {
    //             var point=result.data ;
    //             setcomparePoint(point)
    //             setselectedPointRequest(object)
    //             setshowPointRequestForm(true)
                
    //         } else if (result.data.status === false) {
    //             toast.error("Error")
    //         }
    //     } catch (error) {
    //         const responseResult=error.response            
    //         if (responseResult && responseResult.status === 400) {
    //             if(typeof responseResult.data.data === "object")
    //             {
    //                 const errorMessagesKeys = Object.keys(responseResult.data.data);
    //                 errorMessagesKeys.map((key)=>{
                    
    //                     responseResult.data.data[key].map((message)=>{
    //                         toast.error(message)
    //                     })
    //                 })
    //             }
    //             else{
    //                 toast.error(responseResult.data.data)
    //             }                
    //         }
    //         else if(responseResult && responseResult.status === 401) {
    //             toast.error(responseResult.data.data)
    //             cookies.remove("token");
    //             window.location.href = "/"
    //         }
    //         else
    //         {
    //             toast.error("network error")
    //         }
    //     }                           
    // };
    const updateObject = async (object) => {
        // console.log("saif saif")
        await fetchData('POINT_UPDATE',"points/" + object.point_id);
        setselectedPointRequest(object)
    };
    
    // const createObject = async (object) => {
    //     setselectedPointRequest(object)
    //     setshowPointPreview(true)
    // };
    const handleExpansion = (expansion) => setAccExpansion(expansion)
    const handleSetshowPointRequestForm = (openStatus,needsReload) => { 
                   if (!openStatus)
                   {
                    setPointsRequestsUpdate({})
                   }
      setshowPointRequestForm(openStatus)
    };
    const handleSetshowPointPreview = (openStatus,needsReload) => {
        if(needsReload)
        advanceSearch()
        setshowPointPreview(openStatus)
    };

    const mapObject= async (object)=>{        
        setselectedPointRequest(object)
        setshowPointRequestMapDialog(true)
    }
    const handleSetshowPointRequestMapDialog = (openStatus,needsReload)=>{
        if(needsReload)
            advanceSearch()
        setshowPointRequestMapDialog(openStatus)
    }
    const advanceSearch = (type = "BROWSING")=>{  
        // console.log(type)
        // console.log(pageNumber)
        fetchData('POINTS_REQUESTS','point_requests',{
            page:type==="SEARCH" ? 1: pageNumber,            
            page_size:pageSize,            
            region:region.id,
            created_by:user.id,
            status:pointRequestStatus.map((status)=>status.id),
            creation_date_from:moment(fromDate)?.format('YYYY-MM-DD'),
            creation_date_to:moment(toDate)?.format('YYYY-MM-DD'),
        });
    }
    useEffect( () => {                                      
            fetchData('REGIONS','regions_list')
            fetchData('USERS','users_list')         
            fetchData('POINTS_REQUESTS_STATUSES','point_request_statuses_list')
            advanceSearch()
    }, [pageSize,pageNumber,selectedPointRequestStatus])
    
    useEffect(() => {   
        // console.log(pointsRequests.data)
        if (pointsRequests && pointsRequests.data) {                                    
            setRow(pointsRequests.data);         
            setPointsTotal(pointsRequests.total) 
            setPointsNumberOfPages(pointsRequests.last_page)            
        
        }else{
            setRow([]);                
            setPointsTotal(0)    
            setPointsNumberOfPages(0) 
        }    
    }, [pointsRequests])
    
    useEffect(() => {
        if (Object.keys(pointsRequestsUpdate).length > 0) {
            setcomparePoint(pointsRequestsUpdate)        
            setshowPointRequestForm(true)                    
        }        
    }, [pointsRequestsUpdate])
    
    const columns = [
        { field: 'id', headerName: 'ID' , flex: .08},
        { field: 'region', headerName: 'Location' , flex: 1.5,
        renderCell: (rows) => (
            <div style={{ width: '100%' }} >                               
                  {rows.row.region.district_id === 1 ? "كرخ":"رصافة"}<br/>
                  {rows.row.region.name}
            </div>
        )},
        { field: 'full_name', headerName: 'User' , flex: 1,
        renderCell: (rows) => (
            <div style={{ width: '100%' }} >                               
                  {rows.row.created_by.full_name}                  
            </div>
        )},
        { field: 'name', headerName: 'Name' , flex: .75},
        {
            field: "owner_name",
            flex: .8,
            headerName: "Owner Details",
            // width: 300,        
            renderCell: (rows) => (
              <div style={{ width: '100%' }} >                 
                {rows.row.owner_name}<br/>
                {rows.row.phone}
              </div>
            )
        },
        { field: 'created_at', headerName: 'Request Date' , flex: 1,
            renderCell: (rows) => (
                <div>
                    {rows.row.created_at &&  moment(rows.row.created_at).isValid()? moment(rows.row.created_at).format("DD/MM/YYYY HH:mm:ss"):rows.row.created_at}
                </div>
            )
        },
        { field: 'point_request_status', headerName: 'Status' , flex: .8,
            renderCell: (rows) => (
                <div style={{borderRadius: 5,padding:5,color:"white", backgroundColor:rows.row.point_request_status?.color?rows.row.point_request_status?.color:"gray"}}>
                    {rows.row.point_request_status?.name}
                </div>
            )
        },
        { field: 'actions',flex: .8,
            headerName: 'Actions',                
            renderCell: (param) => (
              <strong>                
                <IconButton                  
                  size="small"
                  style={{ marginLeft: 0 }}                  
                  onClick={() => {                               
                    mapObject(param.row)
                    }}>
                <Tooltip title="Locate">                    
                    <LocationOnRoundedIcon style={{fill: "#5cb85c"}}/>
                </Tooltip>
                </IconButton>                
                
                <IconButton  
                    style={{ marginLeft: 15 }}
                    aria-label="action" 
                    size="small" 
                    onClick={() => {                                                 
                        updateObject(param.row)
                    }}
                >
                    <Tooltip title="Action">                    
                        <SettingsApplicationsRoundedIcon color="primary" />
                    </Tooltip>
                </IconButton>

                <IconButton  
                    style={{ marginLeft: 20 }}
                    aria-label="delete" 
                    size="small" 
                    onClick={                         
                        async () => {                                   
                        setRow(rows.filter((request)=> request.id !== param.row.id ))
                        if (await deleteObject('POINTS_REQUESTS','point_requests',param.row) ===200){                            
                            setRow(rows.filter((request)=> request.id !== param.row.id ))
                        }}
                    }   
                >
                    <Tooltip title="Delete">                    
                        <DeleteRoundedIcon sx={{ color: red[500] }} />
                    </Tooltip>
                </IconButton>                
              </strong>
            ),
        },          
    ]; 
    
    const handlePageChange = (newPage) => {        
        setpageNumber(newPage+1)  
      }
    
    return (
        <>
            {showPointRequestForm?
                <PointRequestForm point={comparePoint} pointRequest={selectedPointRequest} showPointRequestForm={showPointRequestForm} setshowPointRequestForm={handleSetshowPointRequestForm} />
            :null}
            {showPointPreview?            
                <PointPreview pointRequest={selectedPointRequest} showPointPreview={showPointPreview} setshowPointPreview={handleSetshowPointPreview} />
            :null}
            {showPointRequestMapDialog?
                <PointRequestMapDialog pointRequest={selectedPointRequest} showPointRequestMapDialog={showPointRequestMapDialog} setshowPointRequestMapDialog={handleSetshowPointRequestMapDialog} />
            :null}            

            <Box display="flex" >
                <Button display = "flex"
                    startIcon={<FileDownloadIcon />}
                    variant="contained" 
                    sx={{marginRight: 1, marginBottom: accExpansion ? "0":"20px" }}
                    onClick = { async () => {
                                        const fetchedData = await   fetchData('POINTS_REQUESTS','point_requests',{
                                            page:pageNumber,
                                            page_size:pointsTotal,
                                            shop_name:shopName,
                                            owner_name:ownerName,
                                            district:district.id,
                                            region:region.id
                                        });
                                        exportToCSV(fetchedData.data,'points_requests')
                                    } 
                             }>
                    Export
                </Button>           
                {/* <ExportCSV csvData={points.data} fileName={"test"} /> */}
                <Accordion display = "flex" sx={{ flexGrow: 4}} 
                    onChange={(event,expansion) => handleExpansion(expansion)}
                > 
                    <AccordionSummary 
                        expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography>Advance Search</Typography>
                    </AccordionSummary>
                    <AccordionDetails >
                        <Box component="span" display="flex" bgcolor = "white" flexDirection="column"  sx={{ borderRadius: 1 }} >
                            <Box sx={{m:1}} component="span" display="flex" bgcolor = "white" flexDirection="row" >
                                <Box display="flex" sx={{ flexGrow: 2}}>                                    
                                    <Autocomplete
                                        sx={{ml:1}}
                                        fullWidth                            
                                        options = {regions}                            
                                        getOptionLabel = {(option) => option.name || ""}
                                        id="disable-close-on-select"                        
                                        value = {region}
                                        onChange={(e,v,r) => {setRegion(v ? v:'')}}
                                        renderInput={(params) => (
                                        <TextField {...params} label="Regions" variant="outlined" />
                                        )}
                                    />
                                </Box>

                                
                                {/* <SearchField1 state={shopName} setState = {setShopName} fieldLabel='Shop Name'/>                    
                                <SearchField1 state={ownerName} setState = {setOwnerName} fieldLabel='Owner Name'/> */}
                                <BasicDateRangePicker/>

                                <Box display="flex" sx={{ flexGrow: 1 }}>
                                    <Button 
                                        variant="contained" 
                                        spacing={2} sx={{ flexGrow: 1,margin: 1 }}
                                        onClick = {() => advanceSearch("SEARCH")} >
                                        Search
                                    </Button>
                                    <Button variant="contained" 
                                        spacing={2} 
                                        sx={{ flexGrow: 1,margin: 1 }} 
                                        color="secondary"
                                        onClick = {() => {
                                            clearPointsAdvanceSearchFields();
                                            setFromDate(null);
                                            setTODate(null);
                                        }} >
                                        Clear
                                    </Button>                       
                                </Box>                
                            </Box>
                            <Box sx={{m:1}} component="span" display="flex" bgcolor = "white" flexDirection="row" >                
                                <Autocomplete                                                 
                                    sx={{ml:1}}
                                    fullWidth                            
                                    options = {users}                            
                                    getOptionLabel = {(option) => option.full_name || ""}
                                    id="disable-close-on-select"                        
                                    value = {user}
                                    onChange={(e,v,r) => {setUser(v ? v:'');}}
                                    renderInput={(params) => (
                                    <TextField {...params} label="Users" variant="outlined" />
                                    )}
                                />

                                <Autocomplete                                                 
                                    sx={{ml:1}}
                                    multiple
                                    fullWidth
                                    options = {pointsRequestsStatuses}
                                    getOptionLabel = {(option) => option.name || ""}
                                    id="disable-close-on-select"
                                    value = {pointRequestStatus}
                                    onChange={(e,v,r) => {setPointRequestStatus(v ? v:[]);}}
                                    renderInput={(params) => (
                                    <TextField {...params} label="Request Status" variant="outlined" />
                                    )}
                                />

                                {/* <BasicDateRangePicker/> */}
                            </Box>
                            <Box sx={{ m: 1 }} component='span' display='flex' bgcolor='white' flexDirection='row'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        label='From Date'
                                        inputFormat='YYYY-MM-DD'
                                        value={fromDate}
                                        onChange={(date) => {
                                            handelChangeDate(new Date(date), 1);
                                        }}
                                        renderInput={(params) => <TextField {...params} sx={{ ml: 1, width: "100%" }} />}
                                    />
                                    <DesktopDatePicker
                                        label='to Date'
                                        inputFormat='YYYY-MM-DD'
                                        value={toDate}
                                        onChange={(date) => {
                                            handelChangeDate(new Date(date), 2);
                                        }}
                                        renderInput={(params) => <TextField {...params} sx={{ ml: 1, width: "100%" }} />}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>

            <div style={{height:'calc(100% - 9em)'}}>
                
                <StyledDataGrid                                                                          
                    rows={rows}
                    columns={columns}                                    
                    rowCount={pointsTotal}
                    pageSize={pageSize}
                    page = {pageNumber-1}
                    onPageSizeChange={(newPageSize) =>{                    
                        setPageSize(newPageSize)                      
                    }}                
                    rowsPerPageOptions={[5, 10, 20]}
                    // components={{
                    //     Pagination:CustomPagination,
                    //     }}                                        
                    paginationMode="server"
                    onPageChange={(params) =>{
                        handlePageChange(params)
                    }}                         
                />
                <Box width="45%" m="auto">
                    <Pagination                    
                        color="primary"
                        size="large"                
                        count={pointsNumberOfPages}                         
                        page={pageNumber}                
                        showFirstButton
                        showLastButton 
                        onChange ={(e,p) =>{                             
                            setpageNumber(p)  
                        }}
                    />
                </Box>
                <div style={{width:'50%', position:'absolute', top:'100px',margin:'0 auto', left:'50%', top:'50%'}}>
                    {loading && (
                        <CircularIndeterminate />
                    )}
                </div>
            </div>

            {/* <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="card">
                           
                            <div className="card-header">PointRequest List </div>
                            <div className="card-body">
                            <table>
                                <thead>
                                    <tr>
                                        <th>1</th>
                                        <th>Image</th>
                                        <th>Point Name</th>
                                        <th>owner name</th>
                                        <th>Requested by</th>
                                        <th>Request date</th>
                                        <th>Status</th>
                                        <th>Map it</th>
                                        <th>Action</th>
                                        <th>delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {   
                                    state?.pointRequests?.data ? 
                                        state?.pointRequests?.data?.map((object) => (
                                                <tr key={object?.id}>
                                                    <td>{object?.id}</td>
                                                    <td>
                                                    <Avatar
                                                    src={helpers.getImageUrl(object?.profile_image[0]?.thumbnail_image_url)}
                                                    name={object?.name}
                                                    size={40}
                                                    />
                                                    </td>
                                                    <td>{object?.name}</td>
                                                    <td>{object?.owner_name}</td>
                                                    <td>{object?.created_by?.full_name}</td>
                                                    <td>{object?.created_at &&  moment(object?.created_at).isValid()? moment(object?.created_at).format("DD/MM/YYYY HH:mm:ss"):object?.created_at}</td>
                                                    <td><span style={{borderRadius: 5,padding:5,color:"white", backgroundColor:object?.point_request_status?.color?object?.point_request_status?.color:"gray"}}>{object?.point_request_status?.name}</span></td>
                                                    <td><Button style={{ backgroundColor:'green',color:'white'}} onClick={()=>{mapObject(object)}}>Map it</Button></td>
                                                    <td>
                                                    {object?.point_id && object?.point_request_status?.id!=APP_CONSTANTS.POINT_REQUEST_STATUS_APPROVED?<Button onClick={()=>{updateObject(object)}}>compare</Button>:null}
                                                    {!object?.point_id && object?.point_request_status?.id!=APP_CONSTANTS.POINT_REQUEST_STATUS_APPROVED?<Button onClick={()=>{createObject(object)}}>View</Button>:null}
                                                    {object?.point_request_status?.id==APP_CONSTANTS.POINT_REQUEST_STATUS_APPROVED?<Button onClick={()=>{createObject(object)}}>Preview</Button>:null}
                                                    </td>
                                                    <td><Button style={{backgroundColor:'red',color:'white'}} onClick={()=>{deleteObject(object)}}>delete</Button></td>
                                                    
                                                </tr>
                                        )) : "Loading..."
                                }
                                </tbody>
                            </table>
                                <div>
                                    <Pagination
                                        activePage={state?.pointRequests?.current_page ? state?.pointRequests?.current_page : 0}
                                        itemsCountPerPage={state?.pointRequests?.per_page ? state?.pointRequests?.per_page : 0 }
                                        totalItemsCount={state?.pointRequests?.total ? state?.pointRequests?.total : 0}
                                        onChange={(pageNumber) => {
                                            fetchData(pageNumber)
                                        }}
                                        pageRangeDisplayed={8}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        firstPageText="First Page"
                                        lastPageText="Last Lage"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default PointRequest;


