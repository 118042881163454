import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Button } from 'react-bootstrap';
import axios from "axios";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import OrgChartTree from "./OrgChartTree";
import SurveyBuildForm from "./SurveyBuildForm"
import helpers from "../../../../src/assets/js/helper"
import CustomTree from "./CustomTree";
const cookies = new Cookies();
const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"

const urlParams = new URLSearchParams(window.location.search);
const surveyId = urlParams.get('id');

export const SurveyBuild = (props) => {
    const [showSurveyBuildForm, setshowSurveyBuildForm] = useState(false);
    const [loading, setloading] = useState(true);
    const [selectedPoint, setselectedPoint] = useState({
        id:null,
        name:''
    });

    const [createId, setCreateId]=useState(1)
    const [fieldTypes, setfieldTypes] = useState([]);
    const [fieldLists, setfieldLists] = useState([]);
    const [surveyFields, setsurveyFields] = useState(null);
    const [surveyFieldsData,setsurveyFieldsData] = useState([])
//     const [surveyFieldsData,setsurveyFieldsData] = useState([{
// 		"id": 2,
// 		"name": "هل قمت بتعريف جهزني؟",
// 		"survey_id": "4",
// 		"field_type_id": 1,
// 		"field_list_id": 1,
// 		"default_value": null,
// 		"is_mandatory": 1,
// 		"is_multiple_select": 0,
// 		"has_condition": 1,
// 		"survey_field_id": null,
// 		"condition_value": "",
// 		"children": [],
// 		"field_type": {
// 			"id": 1,
// 			"name": "check box",
// 			"has_list": 1
// 		},
// 		"field_list": {
// 			"name": "نعم او لا",
// 			"id": 1
// 		}
// 	},
// 	{
// 		"id": 3,
// 		"name": "هل قمت بتقديم النشرة؟",
// 		"survey_id": "4",
// 		"field_type_id": 1,
// 		"field_list_id": 1,
// 		"default_value": null,
// 		"is_mandatory": 1,
// 		"is_multiple_select": 0,
// 		"has_condition": 1,
// 		"survey_field_id": 2,
// 		"condition_value": "نعم",
// 		"children": [],
// 		"field_type": {
// 			"id": 1,
// 			"name": "check box",
// 			"has_list": 1
// 		},
// 		"field_list": {
// 			"name": "نعم او لا",
// 			"id": 1
// 		}
// 	},
// 	{
// 		"id": 4,
// 		"name": "هل الزبون مهتم؟",
// 		"survey_id": "4",
// 		"field_type_id": 1,
// 		"field_list_id": 1,
// 		"default_value": null,
// 		"is_mandatory": 1,
// 		"is_multiple_select": 0,
// 		"has_condition": 1,
// 		"survey_field_id": 3,
// 		"condition_value": "نعم",
// 		"children": [],
// 		"field_type": {
// 			"id": 1,
// 			"name": "check box",
// 			"has_list": 1
// 		},
// 		"field_list": {
// 			"name": "نعم او لا",
// 			"id": 1
// 		}
// 	},
// 	{
// 		"id": 5,
// 		"name": "ما هي اسباب عدم رضاه",
// 		"survey_id": "4",
// 		"field_type_id": 2,
// 		"field_list_id": 4,
// 		"default_value": null,
// 		"is_mandatory": 1,
// 		"is_multiple_select": 0,
// 		"has_condition": 1,
// 		"survey_field_id": 4,
// 		"condition_value": "لا",
// 		"children": [],
// 		"field_type": {
// 			"name": "Drop down",
// 			"id": 2
// 		},
// 		"field_list": {
// 			"name": "اسباب عدم الاهتمام بالبرنامج",
// 			"id": 4
// 		}
// 	},
// 	{
// 		"id": 6,
// 		"name": "اسباب اخرى",
// 		"survey_id": "4",
// 		"field_type_id": 7,
// 		"field_list_id": null,
// 		"default_value": null,
// 		"is_mandatory": 1,
// 		"is_multiple_select": 0,
// 		"has_condition": 1,
// 		"survey_field_id": 5,
// 		"condition_value": "اخرى",
// 		"children": [],
// 		"field_type": {
// 			"name": "Text area",
// 			"id": 7
// 		},
// 		"field_list": {
// 			"name": "لايوجد",
// 			"id": null
// 		}
// 	},
// 	{
// 		"id": 7,
// 		"name": "اظهر التطبيق للزبون على المتجر",
// 		"survey_id": "4",
// 		"field_type_id": 1,
// 		"field_list_id": 1,
// 		"default_value": null,
// 		"is_mandatory": 0,
// 		"is_multiple_select": 0,
// 		"has_condition": 0,
// 		"survey_field_id": 4,
// 		"condition_value": "نعم",
// 		"children": [],
// 		"field_type": {
// 			"id": 1,
// 			"name": "check box",
// 			"has_list": 1
// 		},
// 		"field_list": {
// 			"name": "نعم او لا",
// 			"id": 1
// 		}
// 	},
// 	{
// 		"id": 8,
// 		"name": "قدم تدريب بسيط للزبون؟",
// 		"survey_id": "4",
// 		"field_type_id": 1,
// 		"field_list_id": 1,
// 		"default_value": null,
// 		"is_mandatory": 0,
// 		"is_multiple_select": 0,
// 		"has_condition": 0,
// 		"survey_field_id": 4,
// 		"condition_value": "نعم",
// 		"children": [],
// 		"field_type": {
// 			"id": 1,
// 			"name": "check box",
// 			"has_list": 1
// 		},
// 		"field_list": {
// 			"name": "نعم او لا",
// 			"id": 1
// 		}
// 	},
// 	{
// 		"id": 9,
// 		"name": "اعطي معلومات الدعم الفني للزبون",
// 		"survey_id": "4",
// 		"field_type_id": 1,
// 		"field_list_id": 1,
// 		"default_value": null,
// 		"is_mandatory": 0,
// 		"is_multiple_select": 0,
// 		"has_condition": 0,
// 		"survey_field_id": 4,
// 		"condition_value": "نعم",
// 		"children": [],
// 		"field_type": {
// 			"id": 1,
// 			"name": "check box",
// 			"has_list": 1
// 		},
// 		"field_list": {
// 			"name": "نعم او لا",
// 			"id": 1
// 		}
// 	}
// ])
   

    const getResources = async () => {
        try {
            setloading(true)
            const resourcesResult = await axios({
                url: Host + "field_lists_list",
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                }
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                    const resources = resourcesResult.data;

                    setfieldLists([{
                        value:null,
                        label:"لايوجد"
                    },...resources.map((resource)=>{
                        return {
                            value:resource.id,
                            label:resource.name
                        }
                    })]);
                   
                } else if (resourcesResult.data.status === false) {

                }
            }
            else {
                toast.error("Error")
            }
            const resourcesResult1 = await axios({
                url: Host + "field_types_list",
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                }
            })
            if (resourcesResult1) {
                if (resourcesResult1.status === 200) {
                    const resources1 = resourcesResult1.data;

                    setfieldTypes(resources1.map((resource)=>{
                        return {
                            value:resource.id,
                            label:resource.name
                        }
                        
                    }));
                   
                } else if (resourcesResult1.data.status === false) {

                }
            }
            else {
                toast.error("Error")
            }
            setloading(false)
        } catch (error) {
            setloading(false)
            toast.error("Network Error")
        }
    };

    const submitForm = async () => {
        try {
        
            const resourcesResult = await axios({
                url: Host + "survey_fields",
                method: "post",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                },
                data:{
                    survey_fields:JSON.stringify(surveyFieldsData)
                } 
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                    toast.success("saved ")
                   
                } else if (resourcesResult.status === 400) {
                    toast.error(resourcesResult.data)
                   
                }
            }
            else {
                toast.error("Error")
                
            }
            setloading(false)
            
        } catch (error) {
           
            const resourcesResult=error.response            
            if (resourcesResult && resourcesResult.status === 400) {
                if(typeof resourcesResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        resourcesResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    
                    toast.error(resourcesResult.data.data)
                }
                
                
            }
            else if(resourcesResult && resourcesResult.status === 401) {
             
                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
              
                toast.error("network error")
            }
        }
    };

    const createObject = async (object) => {
        const maxId = surveyFieldsData.reduce((prev, current) => (prev.id > current.id) ? prev.id : current.id, 1)
        setCreateId(maxId+1)
        setshowSurveyBuildForm(true)
    };
    const deleteObject = async (object) => {
        const tempArray=surveyFieldsData.filter((surveyField)=>{
            
            return surveyField.id!=object.id
        })
        updateData(tempArray)
        setsurveyFieldsData(tempArray)
        
    };
    
    const handleSetshowSurveyBuildForm =(openStatus,needsReload) => {
        if(needsReload)
            updateData();
        setshowSurveyBuildForm(openStatus)
    };

    const updateData=(tempArray)=>{
        console.log(tempArray);
        const tempTreeArray = helpers.listToTree(tempArray)
        console.log(tempTreeArray);
        if(tempTreeArray.length)
        {
            const resources=tempTreeArray[0]
            var child= {
                name: resources.name,
                attributes: {
                    id: resources.id,
                    default_value: resources.default_value,
                    is_mandatory: resources.is_mandatory,
                    is_multiple_select: resources.is_multiple_select,
                    has_condition:resources.has_condition,
                    condition_value:resources.condition_value,
                    field_type_name: resources["field_type"].name,
                    field_type_has_list: resources["field_type"].has_list,
                       
                }
            }
    
            
            var children=resources.children;
            var childrenMapped=null
            if(children!=null && children.length>0){
                childrenMapped = helpers.recursionCategory(children)
            }
            const parent={
                ...child,
                children:childrenMapped
            }
            setsurveyFields(parent)
        }  
        else{
            setsurveyFields(null)
        }     
    }

    const handleSetSurveyFieldData = (data)=>{
        updateData(data)
        setsurveyFieldsData(data)
    }
    useEffect(() => {
        if(surveyFieldsData.length>0)
            updateData(surveyFieldsData)
        if(loading)
            getResources()

        return () => {

        };
    }, [loading,showSurveyBuildForm]);


    return (
        loading ? <>loading</> :
        <>
            {showSurveyBuildForm?
                <SurveyBuildForm 
                createId={createId}
                surveyId={surveyId}
                setsurveyFieldsData={handleSetSurveyFieldData} 
                setsurveyFields={setsurveyFields}
                surveyFieldsData={surveyFieldsData} 
                fieldLists={fieldLists} 
                fieldTypes={fieldTypes} 
                showSurveyBuildForm={showSurveyBuildForm} 
                setshowSurveyBuildForm={handleSetshowSurveyBuildForm} />
            :null}
            <h4>Build survey</h4>
            
                
                
                {/* <OrgChartTree surveyFields={surveyFields} /> */}
                <div className="container">
                {surveyFields?
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div style={{height:400,backgroundColor:'#cde4da'}}>
                            <CustomTree surveyFields={surveyFields}/>
                        </div>
                    </div>
                </div>
                :null}
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">Survey Fields list<Button style={{float:'right'}} onClick={()=>{createObject()}}>create</Button></div>
                            <div className="card-body">
                            <table>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Type</th>
                                        <th>list type</th>
                                        <th>is mandatory</th>
                                        <th>has condition</th>
                                        <th>parent field id</th>
                                        <th>under condition value</th>
                                        <th>Default value</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {   
                                   surveyFieldsData ? 
                                   surveyFieldsData?.map((object) => (
                                                <tr key={object?.id}>
                                                    <td>{object?.id}</td>
                                                    <td>{object?.name}</td>
                                                    <td>{object?.field_type?.name}</td>
                                                    <td>{object?.field_list?.name}</td>
                                                    <td>{object?.is_mandatory}</td>
                                                    <td>{object?.has_condition}</td>
                                                    <td>{object?.survey_field_id}</td>
                                                    <td>{object?.condition_value}</td>
                                                    <td>{object?.default_value}</td>
                                                    <td><Button style={{backgroundColor:'red',color:'white'}} onClick={()=>{deleteObject(object)}}>delete</Button></td>
                                                    
                                                </tr>
                                        )) : "Loading..."
                                }
                                </tbody>
                            </table>
                                
                            </div>
                        </div>
                        <Button style={{width:'100%'}} onClick={()=>{submitForm()}}>Save</Button>
                    </div>
                </div>
            </div>
        </>

    );
};
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',

    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: '100%',
        border: '1px solid #ababab',
        borderRadius: 5,
        padding: 0,
        display: 'flex'
    }),
    container: () => ({

        width: '80%',
        position: 'relative',
        boxSizing: 'border-box',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

export default SurveyBuild;
