import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import IconButton from "@material-ui/core/IconButton";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SaveIcon from "@mui/icons-material/Save";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import BasicDateRangePicker from "../GeneralComponents/DateFromTo";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import CancelIcon from '@mui/icons-material/Cancel';

import Cookies from "universal-cookie";
import CircularIndeterminate from "../GeneralComponents/CirularProgress";
import StyledDataGrid from "../GeneralComponents/StyledDataGrid";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import { useGlobalContext } from "../../../context";
import Host from "../../../assets/js/Host";
import { toast } from "react-toastify";
import axios from "axios";
// import ReactPDF from '@react-pdf/renderer';

import "../../../assets/css/DG.css";
import "../../../assets/css/AccordionStyle.css";
import helpers from "../../../assets/js/helper";
import Tooltip from "@mui/material/Tooltip";
import VisitPreview from "../Visits/VisitPreview";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Dialog } from "evergreen-ui";
// import PDFReport from './PDFReport';

const cookies = new Cookies();
const token = cookies.get("token");
const language = cookies.get("language") ? cookies.get("language") : "ar";
function Collection() {
    const currentDate = new Date();
    const options = { timeZone: "Asia/Baghdad" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(currentDate);

    const [expand, setExpand] = useState(true);
    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setpageNumber] = useState(1);
    const [pointsTotal, setPointsTotal] = useState(0);
    const [pointsNumberOfPages, setPointsNumberOfPages] = useState(0);
    const [showVisitCancelation, setShowVisitCancelation] = useState(false);
    const [reason, setReason] = useState("");

    const {
        loading,
        setLoading,
        fromToDate,
        collections,
        pointsStatuses,
        pointStatus,
        setPointStatus,
        exportToCSV,
        allPoints,
        user,
        users,
        setUser,
        setCollectionType,
        collectionTypes,
        collectionType,
        points,
        district,
        region,
        districts,
        regions,
        ownerName,
        shopName,
        clearPointsAdvanceSearchFields,
        setRegion,
        setDistrict,
        setShopName,
        setOwnerName,
        fetchData,
        deleteObject,
        cancelVisit
    } = useGlobalContext();

    const [showVisitPreview, setshowVisitPreview] = useState(false);
    const [selectedVisit, setselectedVisit] = useState(null);

    const [accExpansion, setAccExpansion] = useState(true);
    const [confirmedPaymentscheckedList, setConfirmedPaymentscheckedList] = useState([]);

    const [fromDate, setFromDate] = useState(formattedDate);
    const [toDate, setTODate] = useState(formattedDate);
    const [visitToCancel, setVisitToCancel] = useState({});

    const handelChangeDate = (date, number) => {
        if (number == 1) {
            setFromDate(date);
        } else if (number == 2) {
            setTODate(date);
        }
    };

    const handleExpansion = (expansion) => setAccExpansion(expansion);

    const handlePageChange = (newPage) => {
        setpageNumber(newPage + 1);
    };

    const advanceSearch = async (type = "BROWSING") => {
        const fetchedData = await fetchData("COLLECTIONS", "collections", {
            page: type === "SEARCH" ? 1 : pageNumber,
            page_size: pageSize,
            collection_type: collectionType.id,
            created_by: user.id,
            creation_date_from: moment(fromDate)?.format("YYYY-MM-DD"),
            creation_date_to: moment(toDate)?.format("YYYY-MM-DD"),
        });
        return fetchedData;
    };

    const handleConfirmPaymentsReceivedCheckedChange = (row, checked) => {
        if (checked) {
            setConfirmedPaymentscheckedList([...confirmedPaymentscheckedList, row]);
        } else {
            setConfirmedPaymentscheckedList(confirmedPaymentscheckedList.filter((item) => item.id != row.id));
        }
    };

    const saveObject = async (object) => {
        if (!window.confirm("Are you sure you want to save all records in all pages?")) {
            return;
        }

        try {
            let formdata = new FormData();

            if (confirmedPaymentscheckedList.length > 0) {
                confirmedPaymentscheckedList.map((item, index) => {
                    formdata.append("visits_ids[" + index + "]", item.id);
                });
                // formdata.append("visits_ids", confirmedPaymentscheckedList.map(item=>item.id))
                formdata.append("settlement_for_user_id", confirmedPaymentscheckedList[0].created_by?.id);
            } else {
                toast.error("Please select at least one value");
                return;
            }
            setLoading(true);

            const result = await axios({
                url: Host + "settlements",
                method: "post",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "X-localization": language,
                },
                data: formdata,
            });

            if (result && result.status == 200) {
                toast.success("Saved successfully. Please wait to download the file");
                window.open(Host + `settlements_report_pdf?id=${result.data.id}`, "_blank");
                setConfirmedPaymentscheckedList([]);
                fetchData("COLLECTIONS", "collections", {
                    page: 1,
                    page_size: pageSize,
                    collection_type: collectionType.id,
                    created_by: user.id,
                    creation_date_from: moment(fromDate)?.format("YYYY-MM-DD"),
                    creation_date_to: moment(toDate)?.format("YYYY-MM-DD"),
                });
            } else if (result.data.status === false) {
                toast.error("Error");
            }
        } catch (error) {
            const responseResult = error.response;
            if (responseResult && responseResult.status === 400) {
                if (typeof responseResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key) => {
                        responseResult.data.data[key].map((message) => {
                            toast.error(message);
                        });
                    });
                } else {
                    toast.error(responseResult.data.data);
                }
            } else if (responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data);
                cookies.remove("token");
                window.location.href = "/";
            } else {
                toast.error("network error");
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData("REGIONS", "regions_list");
        fetchData("USERS", "users_list");
        advanceSearch();
    }, [pageSize, pageNumber]);

    //Reformatting points object once points are loaded
    useEffect(() => {
        if (collections && collections.data && collections.data.length > 0) {
            const reformatCollections = collections.data.map((rowCollection) => {
                if (collectionType == 1) {
                    if (rowCollection?.has_settlement > 0) return rowCollection;
                } else if (collectionType == 2) {
                    if (rowCollection?.has_settlement == 0 && (rowCollection?.total_dollars > 0 || rowCollection?.total_dinars > 0)) return rowCollection;
                }

                return rowCollection;
            });
            setRow(reformatCollections);
            setPointsTotal(collections.total);
            setPointsNumberOfPages(collections.last_page);
        } else {
            setRow([]);
            setPointsTotal(0);
            setPointsNumberOfPages(0);
        }
    }, [collections]);

    const [rows, setRow] = useState([]);
    const columns = [
        { field: "id", headerName: "ID", flex: 0.1 },
        {
            field: "name",
            headerName: "Customer Name",
            flex: 2,
            renderCell: (rows) => (
                <div
                    style={{
                        width: "100%",
                        whiteSpace: "pre-wrap",
                        textAlign: "right",
                    }}>
                    {rows.row.point.name}
                </div>
            ),
        },
        {
            field: "full_name",
            headerName: "User",
            flex: 1,
            renderCell: (rows) => (
                <div
                    style={{
                        width: "100%",
                        whiteSpace: "pre-wrap",
                        textAlign: "right",
                    }}>
                    {rows.row.created_by.full_name}
                </div>
            ),
        },
        {
            field: "start_date",
            headerName: "Start End Date",
            flex: 1.2,
            renderCell: (rows) => (
                <div
                    style={{
                        width: "100%",
                        whiteSpace: "pre-wrap",
                    }}>
                    {rows.row.start_date}
                    <br />
                    {rows.row.end_date}
                </div>
            ),
        },

        {
            field: "canceled",
            headerName: "Canceled",
            flex: 0.4,
            renderCell: (rows) => { 
                return(
                <div
                    style={{
                        width: "100%",
                        whiteSpace: "pre-wrap",
                        textAlign: "right",
                    }}>
                    {rows.row.canceled ? (
                        <span style={{ padding: 5, borderRadius: 5, color: "white", backgroundColor: "red" }}>canceled</span>
                    ) : (
                        <span style={{ padding: 5, borderRadius: 5, color: "white", backgroundColor: "green" }}>valid</span>
                    )}
                </div>
            )},
        },
        {
            field: "cancelation_reason",
            headerName: "Reason",
            flex: 1,
            renderCell: (rows) => (
                <div
                    style={{
                        width: "100%",
                        whiteSpace: "pre-wrap",
                        textAlign: "right",
                    }}>
                    {rows.row.cancelation_reason}
                </div>
            ),
        },
        {
            field: "note",
            headerName: "Note",
            flex: 1,
            renderCell: (rows) => (
                <div
                    style={{
                        width: "100%",
                        whiteSpace: "pre-wrap",
                        textAlign: "right",
                    }}>
                    {rows.row.note}
                </div>
            ),
        },
        {
            field: "total_dinars",
            headerName: "Total ",
            flex: 0.8,
            renderCell: (rows) => (
                <div
                    style={{
                        width: "100%",
                        whiteSpace: "pre-wrap",
                        textAlign: "left",
                    }}>
                    {rows.row.total_dollars ? helpers.numberWithCommasDollar(rows.row.total_dollars) : 0} USD
                    <br></br>
                    {rows.row.total_dinars ? helpers.numberWithCommasDinar(rows.row.total_dinars) : 0} IQD
                </div>
            ),
        },
        // { field: 'total_dinars', headerName: 'Total Dinars' , flex: 2},

        {
            field: "actions",
            flex: 1,
            headerName: "actions",
            renderCell: (rows) => (
                <div>
                    <IconButton
                        style={{ marginLeft: 15 }}
                        aria-label='view'
                        size='small'
                        onClick={() => {
                            previewObject(rows.row);
                            // previewObject(rows.row.object1)
                        }}>
                        <Tooltip title='View'>
                            <RemoveRedEyeRoundedIcon color='primary' />
                        </Tooltip>
                    </IconButton>
                    {rows?.row?.has_settlement == 0 && (rows?.row?.total_dollars > 0 || rows?.row?.total_dinars > 0) ? (
                        <>
                        {rows?.row?.canceled === 0 ?
                            <>
                                <IconButton
                                    style={{ marginLeft: 9 }}
                                    aria-label='view'
                                    size='small'
                                    onClick={() => {
                                        setVisitToCancel(rows.row);
                                        setShowVisitCancelation(true);
                                    }}
                                >
                                    <Tooltip title='cancel'>
                                        <CancelIcon color='error' />
                                    </Tooltip>
                                </IconButton>
                                <Checkbox
                                    checked={confirmedPaymentscheckedList.filter((checkedItem) => rows.row.id == checkedItem.id).length > 0}
                                    onChange={(event) => {
                                        handleConfirmPaymentsReceivedCheckedChange(rows.row, event.target.checked);
                                    }}
                                    sx={{
                                        color: "#132a3b",
                                        "&.Mui-checked": {
                                            color: "#1976d2",
                                        },
                                    }}
                                />
                            </>
                        : <></>}
                        </>
                    ) : null}
                </div>
            ),
        },
    ];

    const previewObject = async (object) => {
        setselectedVisit(object);
        setshowVisitPreview(true);
    };

    const handleSetshowVisitPreview = (openStatus, needsReload) => {
        setshowVisitPreview(openStatus);
    };

    return (
        <>
            {showVisitPreview ? (
                <VisitPreview visit={selectedVisit} showVisitPreview={showVisitPreview} setshowVisitPreview={handleSetshowVisitPreview} />
            ) : null}

            <Box display='flex'>
                <Button
                    display='flex'
                    startIcon={<FileDownloadIcon />}
                    variant='contained'
                    sx={{ marginRight: 1, marginBottom: accExpansion ? "0" : "20px" }}
                    onClick={async () => {
                        const fetchedData = await fetchData("COLLECTIONS", "collections", {
                            page_size: pointsTotal,
                            created_by: user.id,
                            collection_type: collectionType.id,
                            creation_date_from: moment(fromDate)?.format("YYYY-MM-DD"),
                            creation_date_to: moment(toDate)?.format("YYYY-MM-DD"),
                        });
                        exportToCSV(fetchedData.data, "collections");
                    }}>
                    Export
                </Button>
                <Button
                    display='flex'
                    startIcon={<SaveIcon />}
                    variant='contained'
                    style={{ backgroundColor: "#5AA7A7" }}
                    sx={{ marginRight: 1, marginBottom: accExpansion ? "0" : "20px" }}
                    onClick={() => saveObject()}>
                    Save
                </Button>
                {/* <ExportCSV csvData={points.data} fileName={"test"} /> */}
                <Accordion
                    expanded={expand}
                    display='flex'
                    sx={{ flexGrow: 4 }}
                    onChange={(event, expansion) => {
                        setExpand(expansion);
                        handleExpansion(expansion);
                    }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />} aria-controls='panel1a-content' id='panel1a-header'>
                        <Typography>Advance Search</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box component='span' display='flex' bgcolor='white' flexDirection='column' sx={{ borderRadius: 1 }}>
                            <Box sx={{ m: 1 }} component='span' display='flex' bgcolor='white' flexDirection='row'>
                                {/* <SearchField1 state={shopName} setState = {setShopName} fieldLabel='Shop Name'/>                    
                                <SearchField1 state={ownerName} setState = {setOwnerName} fieldLabel='Owner Name'/> */}
                                <BasicDateRangePicker />

                                <Box display='flex' sx={{ flexGrow: 1 }}>
                                    {/* <Button 
                                        variant="contained" 
                                        spacing={2} sx={{ flexGrow: 1,margin: 1 }}
                                        onClick = {() => ReactPDF.render(<PDFReport />)} >
                                        download
                                    </Button> */}

                                    <Button variant='contained' spacing={2} sx={{ flexGrow: 1, margin: 1 }} onClick={() => advanceSearch("SEARCH")}>
                                        Search
                                    </Button>
                                    <Button
                                        variant='contained'
                                        spacing={2}
                                        sx={{ flexGrow: 1, margin: 1 }}
                                        color='secondary'
                                        onClick={() => {
                                            clearPointsAdvanceSearchFields();
                                            setFromDate(null);
                                            setTODate(null);
                                        }}>
                                        Clear
                                    </Button>
                                </Box>
                            </Box>
                            <Box sx={{ m: 1 }} component='span' display='flex' bgcolor='white' flexDirection='row'>
                                <Autocomplete
                                    sx={{ ml: 1 }}
                                    fullWidth
                                    options={users}
                                    getOptionLabel={(option) => option.full_name || ""}
                                    id='disable-close-on-select'
                                    value={user}
                                    onChange={(e, v, r) => {
                                        setUser(v ? v : "");
                                    }}
                                    renderInput={(params) => <TextField {...params} label='Select a user to see his/her data' variant='outlined' />}
                                />
                                <Autocomplete
                                    sx={{ ml: 1 }}
                                    fullWidth
                                    options={collectionTypes}
                                    getOptionLabel={(option) => option.name || ""}
                                    id='disable-close-on-select'
                                    value={collectionType}
                                    onChange={(e, v, r) => {
                                        setCollectionType(v ? v : "");
                                    }}
                                    renderInput={(params) => <TextField {...params} label='Select collection type' variant='outlined' />}
                                />

                                {/* <BasicDateRangePicker/> */}
                            </Box>
                            <Box sx={{ m: 1 }} component='span' display='flex' bgcolor='white' flexDirection='row'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        label='From Date'
                                        inputFormat='YYYY-MM-DD'
                                        value={fromDate}
                                        onChange={(date) => {
                                            handelChangeDate(new Date(date), 1);
                                        }}
                                        renderInput={(params) => <TextField {...params} sx={{ ml: 1, width: "100%" }} />}
                                    />
                                    <DesktopDatePicker
                                        label='to Date'
                                        inputFormat='YYYY-MM-DD'
                                        value={toDate}
                                        onChange={(date) => {
                                            handelChangeDate(new Date(date), 2);
                                        }}
                                        renderInput={(params) => <TextField {...params} sx={{ ml: 1, width: "100%" }} />}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>

            {confirmedPaymentscheckedList.length > 0 ? (
                <div style={{ paddingTop: 5, paddingBottom: 5, fontWeight: 600 }}>
                    Total amount received: (
                    <span style={{ color: "blue" }}>
                        {helpers.numberWithCommasDinar(confirmedPaymentscheckedList.reduce((total, item) => +total + +item.total_dinars, 0))}
                    </span>{" "}
                    IQD), (
                    <span style={{ color: "green" }}>
                        {helpers.numberWithCommasDollar(confirmedPaymentscheckedList.reduce((total, item) => +total + +item.total_dollars, 0))}
                    </span>{" "}
                    USD)
                </div>
            ) : null}
            <div style={{ height: "calc(100% - 9em)" }}>
                <StyledDataGrid
                    rows={rows}
                    columns={columns}
                    rowCount={pointsTotal}
                    pageSize={pageSize}
                    page={pageNumber - 1}
                    onPageSizeChange={(newPageSize) => {
                        setPageSize(newPageSize);
                    }}
                    rowsPerPageOptions={[5, 10, 20]}
                    paginationMode='server'
                    onPageChange={(params) => {
                        handlePageChange(params);
                    }}
                />
                <Box width='45%' m='auto'>
                    <Pagination
                        color='primary'
                        size='large'
                        count={pointsNumberOfPages}
                        page={pageNumber}
                        showFirstButton
                        showLastButton
                        onChange={(e, p) => {
                            setpageNumber(p);
                        }}
                    />
                </Box>
                <Dialog
                    isShown={showVisitCancelation}
                    onCloseComplete={() => setShowVisitCancelation(false)}
                    hasHeader={true}
                    title="Cancel Visit"
                    shouldCloseOnOverlayClick={false}
                    hasFooter={false}
                    width={'60%'}
                    minHeightContent={"100px"}
                    onConfirm={() => { }}
                
                >
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end'
                    }}>
                        <TextField
                            fullWidth 
                            label="reason"
                            id="fullWidth" 
                            value={reason}
                            onChange={(e)=>{
                                setReason(e.target.value)
                            }}
                        />
                        <Button 
                            sx={{
                                bgcolor: "#1976d2",
                                borderWidth: "1px",
                                borderStyle: 'solid',
                                color: "white",
                                padding: "10px 20px",
                                marginTop: "20px",
                                ":hover": {
                                    color: "#000",
                                    borderWidth: "1px",
                                    borderStyle: 'solid',
                                    borderColor: "#1976d2",
                                }
                            }}
                            onClick={async () => {
                                const res = await cancelVisit(visitToCancel.id, reason);
                                if(res && typeof res === 'object') {
                                    setShowVisitCancelation(false);
                                    setReason("");
                                    advanceSearch();
                                }
                            }}
                        >
                            Submit
                        </Button>
                    </div>
                </Dialog>

                <div style={{ width: "50%", position: "absolute", top: "100px", margin: "0 auto", left: "50%", top: "50%" }}>
                    {loading && <CircularIndeterminate />}
                </div>
            </div>
        </>
    );
}

export default Collection;
