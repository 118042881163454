
import React, {useEffect,useState} from 'react';
import Cookies from "universal-cookie";
import axios from "axios";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import RegionForm from './RegionForm';
import RegionMapDialog from './RegionMapDialog';
import RegionImportForm from './RegionImportForm';
import IconButton from "@material-ui/core/IconButton";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import Tooltip from '@mui/material/Tooltip';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PublishRoundedIcon from '@mui/icons-material/PublishRounded';
import CircularIndeterminate from  '../GeneralComponents/CirularProgress'
import StyledDataGrid from '../GeneralComponents/StyledDataGrid'
import "../../../assets/css/DG.css"
import "../../../assets/css/AccordionStyle.css"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import Pagination from '@mui/material/Pagination';
import "../../../assets/css/DG.css"
import "../../../assets/css/AccordionStyle.css"
import red from '@material-ui/core/colors/red';

import { useGlobalContext } from '../../../context'

// const cookies = new Cookies();
// const token = cookies.get("token")
// const language = cookies.get("language")?cookies.get("language"):"ar"

function Regions() {

    const [pageSize, setPageSize] = useState(20);    
    const [pageNumber, setpageNumber] = useState(1);
    const [pointsTotal, setPointsTotal] = useState(0)
    const [pointsNumberOfPages, setPointsNumberOfPages] = useState(0)
    const {loading,data,pointsStatuses,pointStatus,setPointStatus,exportToCSV,allPoints,points,district,region,districts,regions,ownerName,shopName,clearPointsAdvanceSearchFields,setRegion,setDistrict,setShopName,setOwnerName,fetchData,deleteObject} = useGlobalContext()
    const [rows,setRow] = useState([])    
    // const [loading, setloading] = useState(false);
    // const [state, setData] = useState({
    //     regions: ''
    // });
    const [showRegionForm, setshowRegionForm] = useState(false);
    const [showRegionImportForm, setshowRegionImportForm] = useState(false);
    const [showRegionMapDialog,setshowRegionMapDialog] = useState(false)
    const [selectedRegion, setselectedRegion] = useState(null);    
    const [accExpansion,setAccExpansion] = useState(false)
    const [polygon,setPolygon]=useState([])
    const [RegionMapDialog,setRegionMapDialog]=useState()

    const handlePageChange = (newPage) => {        
        setpageNumber(newPage+1)  
    }

    const handleExpansion = (expansion) => setAccExpansion(expansion)

    const advanceSearch = async (type = "BROWSING")=>{         
        const fetchedData = await fetchData('DATA','regions',{
            page:type==="SEARCH" ? 1: pageNumber,                        
            page_size:pageSize,
            district:district.id,
            region:region.id,
        });
        return fetchedData   
    }

    // const fetchData = async (pageNumber = 1) => {
    //     setloading(true)
    //     try {
    //         const result=await axios({
    //             url: Host + "regions?page="+pageNumber,
    //             method: "get",
    //             headers: {
    //             "Authorization" : `Bearer ${token}`,
    //                 Accept:"application/json",
    //                 'X-localization':language,
    //             }
        
    //         })
    //         if(result && result.status == 200)
    //         {
    //             const regions=result.data ;
                
    //             console.log(regions);
    //             setData({
    //                 regions:regions 
    //             });
    //         } else if (result.data.status === false) {
    //             toast.error("Error")
    //         }
    //     } catch (error) {
    //         const responseResult=error.response            
    //         if (responseResult && responseResult.status === 400) {
    //             if(typeof responseResult.data.data === "object")
    //             {
    //                 const errorMessagesKeys = Object.keys(responseResult.data.data);
    //                 errorMessagesKeys.map((key)=>{
                    
    //                     responseResult.data.data[key].map((message)=>{
    //                         toast.error(message)
    //                     })
    //                 })
    //             }
    //             else{
    //                 toast.error(responseResult.data.data)
    //             }
                
    //         }
    //         else if(responseResult && responseResult.status === 401) {
    //             toast.error(responseResult.data.data)
    //             cookies.remove("token");
    //             window.location.href = "/"
    //         }
    //         else
    //         {
    //             toast.error("network error")
    //         }
    //     }
    //     setloading(false)
                   
    // };
    const updateObject = async (object) => {      
        setselectedRegion(object)
        setshowRegionForm(true)
    };
    
    
    // const deleteObject = async (object) => {
    //     if (!window.confirm("Delete the item?")) {
    //         return
    //     }
    //     setloading(true)
    //     try {
    //         const result=await axios({
    //             url: Host + "regions/"+object.id,
    //             method: "delete",
    //             headers: {
    //             "Authorization" : `Bearer ${token}`,
    //                 Accept:"application/json",
    //                 'X-localization':language,
    //             }
        
    //         })
    //         if(result && result.status == 200)
    //         {
    //             toast.error(result.data.data)
    //             fetchData();
    //         } else if (result.data.status === false) {
    //             toast.error("Error")
    //         }
    //     } catch (error) {
    //         const responseResult=error.response            
    //         if (responseResult && responseResult.status === 400) {
    //             if(typeof responseResult.data.data === "object")
    //             {
    //                 const errorMessagesKeys = Object.keys(responseResult.data.data);
    //                 errorMessagesKeys.map((key)=>{
                    
    //                     responseResult.data.data[key].map((message)=>{
    //                         toast.error(message)
    //                     })
    //                 })
    //             }
    //             else{
    //                 toast.error(responseResult.data.data)
    //             }
                
    //         }
    //         else if(responseResult && responseResult.status === 401) {
    //             toast.error(responseResult.data.data)
    //             cookies.remove("token");
    //             window.location.href = "/"
    //         }
    //         else
    //         {
    //             toast.error("network error")
    //         }
    //     }
    //     setloading(false)
    // };
    // const activateObject = async (object) => {
    //     setloading(true)
    //     try {
    //         const result=await axios({
    //             url: Host + "regions/activate",
    //             method: "post",
    //             headers: {
    //             "Authorization" : `Bearer ${token}`,
    //                 Accept:"application/json",
    //                 'X-localization':language,
    //             },
    //             data:{
    //                 region_id:object.id,
    //                 active:object.active==1?0:1
    //             }
        
    //         })
    //         if(result && result.status == 200)
    //         {
    //             fetchData();
    //         } else if (result.data.status === false) {
    //             toast.error("Error")
    //         }
    //     } catch (error) {
    //         const responseResult=error.response            
    //         if (responseResult && responseResult.status === 400) {
    //             if(typeof responseResult.data.data === "object")
    //             {
    //                 const errorMessagesKeys = Object.keys(responseResult.data.data);
    //                 errorMessagesKeys.map((key)=>{
                    
    //                     responseResult.data.data[key].map((message)=>{
    //                         toast.error(message)
    //                     })
    //                 })
    //             }
    //             else{
    //                 toast.error(responseResult.data.data)
    //             }
                
    //         }
    //         else if(responseResult && responseResult.status === 401) {
    //             toast.error(responseResult.data.data)
    //             cookies.remove("token");
    //             window.location.href = "/"
    //         }
    //         else
    //         {
    //             toast.error("network error")
    //         }
    //     }
    //     setloading(false)
    // };
    const createObject = async (object) => {
        setselectedRegion(null)
        setshowRegionForm(true)
    };

    const importObjects = async ()=>{
        setselectedRegion(null)
        setshowRegionImportForm(true)
    }
    const handleSetshowRegionForm = (openStatus,needsReload) => {
      if(needsReload)
    //   fetchData();
    advanceSearch()
      setshowRegionForm(openStatus)
    };
    const handleSetshowRegionImportForm = (openStatus,needsReload) => {
        if(needsReload)
        // fetchData();
        advanceSearch()
        setshowRegionImportForm(openStatus)
      };

    const mapObject= async (object)=>{
        setselectedRegion(object)
        setshowRegionMapDialog(true)
    }
    const handleSetshowRegionMapDialog = (openStatus,needsReload)=>{
        if(needsReload)
            // fetchData();
            advanceSearch()
        setshowRegionMapDialog(openStatus)
    }
    
    useEffect( () => {
        fetchData('REGIONS','regions_list') 
        fetchData('DISTRICTS','districts_list') 
        advanceSearch()            
    }, [pageSize,pageNumber])
    
    // useEffect(() => {
    //     fetchData();
    // }, [])
    
    useEffect(() => {        
        
        if (data && data.data && data.data.length > 0) {            
            setRow(data.data);
            setPointsTotal(data.total)
            setPointsNumberOfPages(data.last_page)                     
        }
    }, [data])

    const columns = [
        { field: 'id', headerName: 'ID' , flex: .1},
        { field: 'district', headerName: 'District' , flex: 1.5,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} >
                    {rows.row.district.name}
                </div>
        )},
        // {
        //     field: "object1",
        //     flex: 1.5,
        //     headerName: "District",
        //     renderCell: (rows) => (
        //       <div style={{ width: '100%' }} >                                 
        //             {rows.value.district}
        //       </div>
        //     )
        // },

        { field: 'name', headerName: 'Region' , flex: 3},
        { field: 'actions',flex: 2,
            headerName: 'Actions',                
            renderCell: (rows) => (
              <strong>                
                <IconButton                  
                  size="small"
                  style={{ marginLeft: 0 }}                  
                  onClick={() => {                               
                    mapObject(rows.row)
                    }}>
                  <Tooltip title="Locate">                    
                        <LocationOnRoundedIcon  style={{fill: "#5cb85c"}}/>
                    </Tooltip>
                </IconButton>

                                
                <IconButton  
                style={{ marginLeft: 15 }}
                aria-label="edit" 
                size="small" 
                onClick={() => {                                   
                    updateObject(rows.row)
                    }}
                >
                    <Tooltip title="Edit">                    
                        <ModeEditOutlineRoundedIcon style={{fill: "#deb12c"}} />
                    </Tooltip>
                </IconButton>

                <IconButton  
                    style={{ marginLeft: 20 }}
                    aria-label="delete" 
                    size="small" 
                    onClick={ async () => {                        
                        if ((await deleteObject('DATA','regions',rows.row))===200) {                            
                            advanceSearch("BROWSING")                            
                        }                        
                    }}
                >
                    <Tooltip title="Delete">                    
                        <DeleteRoundedIcon sx={{ color: red[500] }} />
                    </Tooltip>
                </IconButton>                
              </strong>
            ),
        },        
      ]

    return (
        <>
        {showRegionForm?
            <RegionForm region={selectedRegion} showRegionForm={showRegionForm} setshowRegionForm={handleSetshowRegionForm} />
        :null}
        {showRegionImportForm?
        
            <RegionImportForm showRegionImportForm={showRegionImportForm} setshowRegionImportForm={handleSetshowRegionImportForm} />
        :null}
        {showRegionMapDialog?
        
            <RegionMapDialog region={selectedRegion} showRegionMapDialog={showRegionMapDialog} setshowRegionMapDialog={handleSetshowRegionMapDialog} />
        :null}

        <Box display="flex" >
            <Button display = "flex"
                startIcon={<PublishRoundedIcon />}
                variant="contained" 
                sx={{marginRight: 1,marginBottom:accExpansion ? "0":"20px" }}
                onClick = { async () => {importObjects()}}>
                Import
            </Button>    
            <Button display = "flex"
                startIcon={<FileDownloadIcon />}
                variant="contained" 
                sx={{marginRight: 1,marginBottom:accExpansion ? "0":"20px" }}
                onClick = { async () => {  
                    
                                    const fetchedData = await fetchData('DATA','regions',{                                        
                                        page_size:pointsTotal,
                                        district:district.id,
                                        region:region.id,
                                    });
                                                                        
                                    exportToCSV(fetchedData.data,'regions')                                    
                                }
                            }>
                Export
            </Button>    

            <Button display = "flex"
                startIcon={<AddCircleRoundedIcon />}
                variant="contained"                
                sx={{marginRight: 1,marginBottom:accExpansion ? "0":"20px"}}
                onClick = {() => {createObject()}}>
                Create
            </Button>   
                        
            <Accordion display = "flex" sx={{ flexGrow: 4}}
                onChange={(event,expansion) => handleExpansion(expansion)}> 
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography>Advance Search</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box component="span" display="flex" bgcolor = "white" flexDirection="column"  sx={{ borderRadius: 1 }}>
                        <Box sx={{m:1}} component="span" display="flex" bgcolor = "white" flexDirection="row" >
                            <Box display="flex" sx={{ flexGrow: 2}}> 
                                <Autocomplete       
                                    sx={{ml:1}}                                          
                                    fullWidth                            
                                    options = {districts}                            
                                    getOptionLabel = {(option) => option.name || ""}
                                    id="disable-close-on-select"                        
                                    value = {district}
                                    onChange={(e,v,r) => {setDistrict(v ? v:'')}}
                                    renderInput={(params) => (
                                    <TextField {...params} label="Districts" variant="outlined" />
                                    )}
                                />
                                <Autocomplete    
                                    sx={{ml:1}}                                             
                                    fullWidth                            
                                    options = {regions}                            
                                    getOptionLabel = {(option) => option.name || ""}
                                    id="disable-close-on-select"                        
                                    value = {region}
                                    onChange={(e,v,r) => {setRegion(v ? v:'')}}

                                    renderInput={(params) => (
                                    <TextField {...params} label="Regions" variant="outlined" />
                                    )}
                                 />
                            </Box>
                            <Box display="flex" sx={{ flexGrow: 1 }}>
                                <Button 
                                    variant="contained" 
                                    spacing={2} sx={{ flexGrow: 1,marginLeft: 1, marginRight: 1, height:55 }}
                                    onClick = {advanceSearch} >
                                    Search
                                </Button>
                                <Button variant="contained" 
                                    spacing={2} 
                                    sx={{ flexGrow: 1,margin: 0 , height:55}} 
                                    color="secondary"
                                    onClick = {clearPointsAdvanceSearchFields} >
                                    Clear
                                </Button>                       
                            </Box>                           
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>

        <div style={{height:'calc(100% - 9em)'}}>
                
                <StyledDataGrid                                                                          
                    rows={rows}
                    columns={columns}                                    
                    rowCount={pointsTotal}
                    pageSize={pageSize}
                    page = {pageNumber-1}
                    onPageSizeChange={(newPageSize) =>{                    
                        setPageSize(newPageSize)                      
                    }}                
                    rowsPerPageOptions={[5, 10, 20]}                  
                    paginationMode="server"
                    onPageChange={(params) =>{
                        handlePageChange(params)
                    }}                         
                />
                <Box width="45%" m="auto">
                    <Pagination                    
                        color="primary"
                        size="large"                
                        count={pointsNumberOfPages}                         
                        page={pageNumber}                
                        showFirstButton
                        showLastButton 
                        onChange ={(e,p) =>{                             
                            setpageNumber(p)  
                        }}
                    />
                </Box>
                <div style={{width:'50%', position:'absolute', top:'100px',margin:'0 auto', left:'50%', top:'50%'}}>
                    {loading && (
                        <CircularIndeterminate />
                    )}
                </div>
            </div>

            {/* <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">Region List <Button style={{float:'right',backgroundColor:'green'}} onClick={()=>{importObjects()}}>import</Button><Button style={{float:'right'}} onClick={()=>{createObject()}}>create</Button></div>
                            <div className="card-body">
                            <table>
                                <thead>
                                    <tr>
                                        <th>1</th>
                                        <th>Name</th>
                                        <th>district</th>
                                        <th>Map it</th>
                                        <th>edit</th>
                                        <th>delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {   
                                    state?.regions?.data ? 
                                        state?.regions?.data?.map((object) => (
                                                <tr key={object?.id}>
                                                    <td>{object?.id}</td>
                                                    <td>{object?.name}</td>
                                                    
                                                    <td>{object?.district.name}</td>
                                                    <td><Button style={{backgroundColor:'green',color:'white'}} onClick={()=>{mapObject(object)}}>Map it</Button></td>
                                                    <td><Button onClick={()=>{updateObject(object)}}>update</Button></td>
                                                    <td><Button style={{backgroundColor:'red',color:'white'}} onClick={()=>{deleteObject(object)}}>delete</Button></td>
                                                    
                                                </tr>
                                        )) : "Loading..."
                                }
                                </tbody>
                            </table>
                                <div>
                                    <Pagination
                                        activePage={state?.regions?.current_page ? state?.regions?.current_page : 0}
                                        itemsCountPerPage={state?.regions?.per_page ? state?.regions?.per_page : 0 }
                                        totalItemsCount={state?.regions?.total ? state?.regions?.total : 0}
                                        onChange={(pageNumber) => {
                                            fetchData(pageNumber)
                                        }}
                                        pageRangeDisplayed={8}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        firstPageText="First Page"
                                        lastPageText="Last Lage"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default Regions;


