import Host from './Host';
import axios from "axios";
function isNumeric(value) {
    return /^-?\d+$/.test(value);
}
const helpers = {
    INVALID_TOKEN: function INVALID_TOKEN() {
        return "invalid token";
    },
    http: async function http(request, options, onSuccess, onAPIfailure, onReloadPress, consoleCallName) {
        console.log("###########################################################")
      //  console.log("Accessing :" + Host + request + " token = " + options.headers.jwt);
        await fetch(Host + request, options)
            .then(response => response.json())
            .then(async (responseJson) => {
                // onSuccess(responseJson)
                //  console.log("Response is " + JSON.stringify(responseJson));
                if (responseJson.status == true) {
                    try {
                       // console.log("Response is ok for  " + consoleCallName);
                        await onSuccess(responseJson)
                    } catch (e) {
                        console.log("error in response ok for (" + consoleCallName + ") : " + e)
                    }
                } else if (responseJson.status == false) {
                    try {
                        console.log("Response is not ok  for " + consoleCallName + ":\n" + JSON.stringify(responseJson));
                        await onAPIfailure(responseJson)
                        if (responseJson.data.message.text == helpers.INVALID_TOKEN()) {
                            console.log(helpers.INVALID_TOKEN());
                            // await helpers.reGenerateToken().then(async () => {
                            //     await onReloadPress(responseJson)
                            // })
                           
                        }
                    } catch (e) {
                        console.log("error in response not ok for (" + consoleCallName + ") : " + e)
                    }
                }
            })
            .catch(err => {
                try {

                    console.log(err)
                    //this.refs.toast.show(I18nManager.isRTL ? ("فشل في الاتصال") : ("Network request failed"), 2000);
                    // let isRTL = I18nManager.isRTL;
                    // if (isRTL) {
                    //     Alert.alert(
                    //         "فشل في الاتصال",
                    //         "تأكد من الاتصال بالانترنت واعد المحاولة !", [
                    //         {
                    //             text: "حاول مرة اخرى", onPress: () => {
                    //                 onReloadPress()// this.initHome();
                    //             }
                    //         }
                    //     ]);
                    // } else {
                    //     Alert.alert(
                    //         "Connection Failure",
                    //         "Make sure you have an internet connection and try again", [
                    //         {
                    //             text: "Try again", onPress: () => {
                    //                 onReloadPress()
                    //             }
                    //         }
                    //     ]);
                    // }
                    // // this.setState({ isLoading: false, dataLoaded: false });
                    // console.log(consoleCallName + ": " + err);
                } catch (e) {
                    console.log("error in catch for (" + consoleCallName + ") : " + e)
                }
                // this.loadingHome = false;
            });
        console.log("###########################################################")
    },
    checkIfCustomerHasOpenTask: async function checkIfCustomerHasOpenTask(customerId,taskType,headers) {
        try {
            const apiResult = await axios({
                url: Host + `tasks/check_customer_has_open_task`,
                method: "POST",
                headers: headers,
                data: {
                    customerId:customerId,
                    taskType:taskType
                },
            })
            if (apiResult.data.status === true) {
                return { status:true,error:"", hasOpenTask: true, taskDetails: apiResult.data.data.data };
            } else if (apiResult.data.status === false && apiResult.data?.data?.message?.code == 1) {
            return { status:false,error:"Token expired",hasOpenTask:false, taskDetails: [] };
        
            }
        } catch (error) {
            return {status:false,error:error,hasOpenTask:false,taskDetails:[]}
        }
        

    },
    formatCustomerName: function formatCustomerName (name) {
        var formatedName="1";
       
        if (name && isNumeric(name.slice(0, 2)))
        {
            var nameParts = name.split("-");
            
            if(nameParts.length>0)
            {
                nameParts.shift()
                
                formatedName=nameParts.join(" ");
                formatedName=formatedName.trim()
            }
            else
                formatedName = "customer formate is not valid"
        }
        else
        {
            formatedName = name
        }
        return formatedName;
    },
    getImageUrl:function getImageUrl(image_url){
        var hostURLParts=Host.split("/")
        var hostURL=hostURLParts[0]+"//"+hostURLParts[2]+"/"
        const fullImageURL=hostURL+"api/public/"+image_url
        console.log("fullImageURL",fullImageURL);
      
        return fullImageURL;
    },
    stringToColour: function stringToColour(str){
        var colour = '#fff';
        if(str)
        {
            var hash = 0;
            for (var i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }
            colour = '#';
            for (var i = 0; i < 3; i++) {
                var value = (hash >> (i * 8)) & 0xFF;
                colour += ('00' + value.toString(16)).substr(-2);
            }
        }
        
        return colour;
    },
    recursionCategory:function recursionCategory(data){
        var parent=[];
        for (let item = 0; item < data.length; item++) {
           
         
           
            var child= {
                name: data[item].name,
                attributes: {
                    id: data[item].id,
                    color:helpers.stringToColour(data[item].condition_value),
                    default_value: data[item].default_value,
                    is_mandatory: data[item].is_mandatory,
                    is_multiple_select: data[item].is_multiple_select,
                    has_condition:data[item].has_condition,
                    condition_value:data[item].condition_value,
                    field_type_name: data[item]["field_type"].name,
                    field_type_has_list: data[item]["field_type"].has_list,
                }
            }

            
            var children=data[item].children;
            var childrenMapped=null
            if(children!=null && children.length>0){
                childrenMapped = recursionCategory(children)
            }
            parent.push({
                ...child,
                children:childrenMapped
            })
        }
        return parent;
    },
    listToTree: function listToTree(items, id = null, link = 'survey_field_id'){
        return items
        .filter(item => item[link] === id)
        .map(item => ({ ...item, children: helpers.listToTree(items, item.id) }));
    },
    numberWithCommasDinar :function numberWithCommasDinar (n){

        if (n && n.toString().includes('.')) {
            var parts = n.toString().split(".");
            return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    
        return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    numberWithCommasDollar :function numberWithCommasDollar(n) {

        if (n && n.toString().includes('.')) {
            var parts = n.toString().split(".");
            return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1].substring(0, 2) : "");
        }
    
        return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
};
export default helpers;
