import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Pane, Dialog } from 'evergreen-ui';
import axios from "axios";
import Select from "react-select";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
const cookies = new Cookies();
const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
export const FollowUpMethodForm = (props) => {
    const [loading, setloading] = useState(true);
   
    const [object, setobject] = useState({
        id: null,
        name: null,
        
    });
   

    const submitForm = async () => {
        
        try {
            
        
            if (object.id) {

                const resourcesResult = await axios({
                    url: Host + "follow_up_methods/"+object.id,
                    method: "put",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: object
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("updated ")
                        props.setshowFollowUpMethodForm(false,true)
                    } else if (resourcesResult.data.status === false) {
                        props.setshowFollowUpMethodForm(true,false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setshowFollowUpMethodForm(true,false)
                }
                setloading(false)
            }
            else {
                const resourcesResult = await axios({
                    url: Host + "follow_up_methods",
                    method: "post",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: object
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("saved ")
                        props.setshowFollowUpMethodForm(false,true)
                    } else if (resourcesResult.status === 400) {
                        toast.error(resourcesResult.data)
                        props.setshowFollowUpMethodForm(true,false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setshowFollowUpMethodForm(true,false)
                }
                setloading(false)
            }
        } catch (error) {
            props.setshowFollowUpMethodForm(true,false)
            const resourcesResult=error.response            
            if (resourcesResult && resourcesResult.status === 400) {
                if(typeof resourcesResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        resourcesResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    
                    toast.error(resourcesResult.data.data)
                }
                
                
            }
            else if(resourcesResult && resourcesResult.status === 401) {
             
                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
              
                toast.error("network error")
            }
        }
        
    };


    useEffect(() => {
        if(props&& props.FollowUpMethod&& props.FollowUpMethod.id>0)
        {
            setobject({...object,...props.FollowUpMethod});
        }
        else{
            setobject(
                {
                    id: null,
                    name: null,
                }
            )
        }

        
        return () => {

        };
    }, [props.showFollowUpMethodForm]);


    return (
        
        <Dialog
                isShown={props.showFollowUpMethodForm}
                onCloseComplete={() => props.setshowFollowUpMethodForm(false)}
                hasHeader={false}
                shouldCloseOnOverlayClick={false}
                confirmLabel="Save"
                cancelLabel="Cancel"
                onConfirm={() => {submitForm()}}
                >
            <form >
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input className="form-control" 
                        id="name"
                        value={object.name}
                        onChange={event => { setobject({ ...object, name: event.target.value }); }}
                     />
                </div>
               
                
                
            </form>
        </Dialog>

    );
};
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',

    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: '100%',
        border: '1px solid #ababab',
        borderRadius: 5,
        padding: 0,
        display: 'flex'
    }),
    container: () => ({

        width: '80%',
        position: 'relative',
        boxSizing: 'border-box',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

export default FollowUpMethodForm;
