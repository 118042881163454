
import React, {useEffect,useState} from 'react';
// import Pagination from "react-js-pagination";
import Cookies from "universal-cookie";
import axios from "axios";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import UserDailyPlanForm from './UserDailyPlanForm';
import IconButton from "@material-ui/core/IconButton";
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import Tooltip from '@mui/material/Tooltip';
import CircularIndeterminate from  '../GeneralComponents/CirularProgress'
import StyledDataGrid from '../GeneralComponents/StyledDataGrid'
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import red from '@material-ui/core/colors/red';
import Typography from '@mui/material/Typography';

import { useGlobalContext } from '../../../context'

// const cookies = new Cookies();
// const token = cookies.get("token")
// const language = cookies.get("language")?cookies.get("language"):"ar"
const urlParams = new URLSearchParams(window.location.search);
const userId = urlParams.get('id');

function UserDailyPlans() {
    const [selectedPoint, setselectedPoint] = useState(null);
    const [pageSize, setPageSize] = useState(20);    
    const [pageNumber, setpageNumber] = useState(1);
    const [pointsTotal, setPointsTotal] = useState(0)
    const [pointsNumberOfPages, setPointsNumberOfPages] = useState(0)
    
    const {loading,userDailyPlans,user,setUser,setUserDailyPlans,pointsStatuses,pointStatus,setPointStatus,exportToCSV,allPoints,points,district,region,districts,regions,ownerName,shopName,clearPointsAdvanceSearchFields,setRegion,setDistrict,setShopName,setOwnerName,fetchData,deleteObject} = useGlobalContext()
    // const [user,setuser] = useState(null);
    const [rows,setRow] = useState([])  
    const [userDailyPlan,setUserDailyPlan] = useState({
        plan_date:new Date(),
        user_id:userId,
        points_ids:[],
    });
    const [oldUserDailyPlan,setOldUserDailyPlan] = useState({
        plan_date:new Date(),
        user_id:userId,
        points_ids:[],
    });
    // const [loading, setloading] = useState(false);
    const [state, setData] = useState({
        userDailyPlans: ''
    });
    const [showUserDailyPlanForm, setshowUserDailyPlanForm] = useState(false);
    const [planDate,setplanDate] = useState(null);
    const [pointId,setpointId] = useState(null);
    const [itemPerPage,setitemPerPage] = useState(null);

    const advanceSearch = ()=>{     
        // console.log(pointStatus)
        fetchData('USER_DAILY_PLAN','user_daily_plans',{
            page:pageNumber,
            page_size:pageSize,
            user_id:userId,
            plan_date:planDate,
            point_id:pointId,
        });                        
    }

    // const fetchData = async (pageNumber = 1) => {
    //     setloading(true)
    //     try {
            
    //         var data={
    //             page:pageNumber,
    //         }
    //         if(userId)
    //         {
    //             data={
    //                 ...data,
    //                 user_id:userId,
    //             }
    //         }
    //         if(itemPerPage)
    //         {
    //             data={
    //                 ...data,
    //                 item_per_page:itemPerPage,
    //             }
    //         }
    //         if(planDate)
    //         {
    //             data={
    //                 ...data,
    //                 plan_date:planDate,
    //             }
    //         }
    //         if(pointId)
    //         {
    //             data={
    //                 ...data,
    //                 point_id:pointId,
    //             }
    //         }
    //         const result=await axios({
    //             url: Host + "user_daily_plans",
    //             method: "get",
    //             headers: {
    //             "Authorization" : `Bearer ${token}`,
    //                 Accept:"application/json",
    //                 'X-localization':language,
    //             },
    //             params:data
    //         })
    //         if(result && result.status == 200)
    //         {
    //             const userDailyPlans=result.data;

    //             const tempData={
    //                 ...userDailyPlans,
    //                 data:userDailyPlans?.data.map((data)=>{
    //                     const pointIdsString=data.points_ids.split(',');

    //                     return {
    //                         ...data,
    //                         user_id:userId,
    //                         points_ids:pointIdsString
    //                     }
    //                 })
    //             }
                
                
    //             setData({
    //                 userDailyPlans:tempData 
    //             });
    //         } else if (result.data.status === false) {
    //             toast.error("Error")
    //         }
    //     } catch (error) {
    //         const responseResult=error.response            
    //         if (responseResult && responseResult.status === 400) {
    //             if(typeof responseResult.data.data === "object")
    //             {
    //                 const errorMessagesKeys = Object.keys(responseResult.data.data);
    //                 errorMessagesKeys.map((key)=>{
                    
    //                     responseResult.data.data[key].map((message)=>{
    //                         toast.error(message)
    //                     })
    //                 })
    //             }
    //             else{
    //                 toast.error(responseResult.data.data)
    //             }
                
    //         }
    //         else if(responseResult && responseResult.status === 401) {
    //             toast.error(responseResult.data.data)
    //             cookies.remove("token");
    //             window.location.href = "/"
    //         }
    //         else
    //         {
    //             toast.error("network error")
    //         }
    //     }
    //     setloading(false)
                   
    // };

    // const fetchUserData = async () => {
    //     setloading(true)
    //     try {
            

    //         var data={
    //             id:userId,
    //         }
            
    //         const result=await axios({
    //             url: Host + "user",
    //             method: "get",
    //             headers: {
    //             "Authorization" : `Bearer ${token}`,
    //                 Accept:"application/json",
    //                 'X-localization':language,
    //             },
    //             params:data
    //         })
    //         if(result && result.status == 200)
    //         {
    //             const user=result.data ;
                
    //             setuser(user);
    //         } else if (result.data.status === false) {
    //             toast.error("Error")
    //         }
    //     } catch (error) {
    //         const responseResult=error.response            
    //         if (responseResult && responseResult.status === 400) {
    //             if(typeof responseResult.data.data === "object")
    //             {
    //                 const errorMessagesKeys = Object.keys(responseResult.data.data);
    //                 errorMessagesKeys.map((key)=>{
                    
    //                     responseResult.data.data[key].map((message)=>{
    //                         toast.error(message)
    //                     })
    //                 })
    //             }
    //             else{
    //                 toast.error(responseResult.data.data)
    //             }
                
    //         }
    //         else if(responseResult && responseResult.status === 401) {
    //             toast.error(responseResult.data.data)
    //             cookies.remove("token");
    //             window.location.href = "/"
    //         }
    //         else
    //         {
    //             toast.error("network error")
    //         }
    //     }
    //     setloading(false)
                   
    // };
    const updateObject = async (object) => {
      
        setUserDailyPlan({
            update:true,
            ...object
        })
        setOldUserDailyPlan({
            update:true,
            ...object
        })
       
        setshowUserDailyPlanForm(true)
    };
    // const deleteObject = async (object) => {
    //     if (!window.confirm("Delete the item?")) {
    //         return
    //     }
    //     setloading(true)
    //     try {
    //         const result=await axios({
    //             url: Host + "user_daily_plans/"+object.id,
    //             method: "delete",
    //             headers: {
    //             "Authorization" : `Bearer ${token}`,
    //                 Accept:"application/json",
    //                 'X-localization':language,
    //             }
        
    //         })
    //         if(result && result.status == 200)
    //         {
    //             toast.error(result.data.data)
    //             fetchData();
    //         } else if (result.data.status === false) {
    //             toast.error("Error")
    //         }
    //     } catch (error) {
    //         const responseResult=error.response            
    //         if (responseResult && responseResult.status === 400) {
    //             if(typeof responseResult.data.data === "object")
    //             {
    //                 const errorMessagesKeys = Object.keys(responseResult.data.data);
    //                 errorMessagesKeys.map((key)=>{
                    
    //                     responseResult.data.data[key].map((message)=>{
    //                         toast.error(message)
    //                     })
    //                 })
    //             }
    //             else{
    //                 toast.error(responseResult.data.data)
    //             }
                
    //         }
    //         else if(responseResult && responseResult.status === 401) {
    //             toast.error(responseResult.data.data)
    //             cookies.remove("token");
    //             window.location.href = "/"
    //         }
    //         else
    //         {
    //             toast.error("network error")
    //         }
    //     }
    //     setloading(false)
    // };
    
    const createObject = async (object) => {
        setUserDailyPlan({
            update:false,
            user_id:userId,
            ...object
        })
        setshowUserDailyPlanForm(true)
    };

    const handleSetshowUserDailyPlanForm = (openStatus,needsReload) => {
      if(needsReload)
      advanceSearch()
    //   fetchData();
      setshowUserDailyPlanForm(openStatus)
    };

    const handlePageChange = (newPage) => {        
        setpageNumber(newPage+1)  
      }
    
    useEffect( () => {        
        fetchData('USER','user',{id:userId})                        
        advanceSearch()
    
    }, [pageSize,pageNumber])

    //Reformatting points object once points are loaded
    useEffect(() => {
        
        if (userDailyPlans && userDailyPlans.data && userDailyPlans.data.length > 0) {            
            const   reformatPoints  = {...userDailyPlans,
            data:userDailyPlans?.data.map((data,key)=>{

                const pointIdsString=data.points_ids.split(',');                

                return {
                    ...data,
                    user_id:userId,
                    id:key,
                    points_ids:pointIdsString
                }
            })
            } 
            console.log('reformatPoints')                                                  
            console.log(reformatPoints)

            setRow(reformatPoints.data);                
            setPointsTotal(userDailyPlans.total)    
            setPointsNumberOfPages(userDailyPlans.last_page)                     
        }
    }, [userDailyPlans])

    // useEffect(() => {
    //     fetchUserData();
    //     fetchData();        
    // }, [])
    const [columns] = useState([
        { field: 'id', headerName: '#' , flex: .1},
        { field: 'plan_date', headerName: 'Plan Date' , flex: .5},
        { field: 'total_points', headerName: 'Total' , flex: 3},
        
        { field: 'actions',flex: 2,
            headerName: 'Actions',
            renderCell: (rows) => (
              <strong>
                <IconButton
                style={{ marginLeft: 15 }}
                aria-label="edit"
                size="small"
                onClick={() => {
                    updateObject(rows.row)
                }}
                >
                    <Tooltip title="Edit">            
                        <ModeEditOutlineRoundedIcon style={{fill: "#deb12c"}} />
                    </Tooltip>
                </IconButton>

                <IconButton
                    style={{ marginLeft: 20 }}
                    aria-label="delete"
                    size="small"
                    onClick={() => {
                        deleteObject('USER_DAILY_PLAN','user_daily_plans',rows.row)
                        }}
                >
                    <Tooltip title="Delete">
                        <DeleteRoundedIcon sx={{ color: red[500] }} />
                    </Tooltip>
                </IconButton>
              </strong>
            ),
        },        
      ]);
    
    return (
        <>
            {showUserDailyPlanForm?
                <UserDailyPlanForm user={user} oldObject={oldUserDailyPlan} object={userDailyPlan} setobject={setUserDailyPlan}  showUserDailyPlanForm={showUserDailyPlanForm} setshowUserDailyPlanForm={handleSetshowUserDailyPlanForm} />
            :null}
            <Box display="flex" justifyContent='space-between'>
                <Typography display= "flex"  variant="h5" component="div" gutterBottom color="#000">
                    User Daily Plans List - {user?.full_name}
                </Typography>
                
                <Button  onClick={()=>{createObject()}}>create</Button>
            </Box>
                <div style={{height:'calc(100% - 9em)'}}>                    
                    <StyledDataGrid                                                          
                        rows={rows} //getRowId={(row) => row.internalId}
                        columns={columns}                    
                        rowCount={points?.total ? points?.total : 0}
                        pageSize={pageSize}
                        page = {pageNumber-1}
                        onPageSizeChange={(newPageSize) =>{                        
                            setPageSize(newPageSize)                      
                        }}
                        
                        rowsPerPageOptions={[5, 10, 20]}
                        // pagination
                        paginationMode="server"
                        onPageChange={(params) =>{
                            handlePageChange(params)
                        }}                               
                    />
                    <Box width="45%" m="auto">
                        <Pagination                    
                            color="primary"
                            size="large"                
                            count={pointsNumberOfPages}                         
                            page={pageNumber}                
                            showFirstButton
                            showLastButton 
                            onChange ={(e,p) =>{                             
                                setpageNumber(p)  
                            }}
                        />
                    </Box>
                                                
                    <div style={{width:'50%', position:'absolute', top:'100px',margin:'0 auto', left:'50%', top:'50%'}}>
                        {loading && (
                            <CircularIndeterminate />
                        )}
                    </div>            
                </div>  
         
            {/* <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">User Daily Plans List - {user?.full_name} <Button style={{float:'right'}} onClick={()=>{createObject()}}>create</Button></div>
                            <div className="card-body">
                            <table>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Date</th>
                                        <th>Total Points</th>
                                        <th>edit</th>
                                        <th>delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {   
                                    state?.userDailyPlans?.data ? 
                                        state?.userDailyPlans?.data?.map((object,index) => (
                                                <tr key={index +1}>
                                                    <td>{index+1}</td>
                                                    <td>{object?.plan_date}</td>
                                                    <td>{object?.total_points}</td>
                                                    <td><Button onClick={()=>{
                                                      
                                                        updateObject(object)}}>update</Button></td>
                                                    <td><Button style={{backgroundColor:'red',color:'white'}} 
                                                    // onClick={()=>{deleteObject(object)}}>
                                                        onClick={()=>{deleteObject('USER_DAILY_PLAN','user_daily_plans',rows.row)}}>
                                                            delete
                                                        </Button></td>
                                                    
                                                </tr>
                                        )) : "Loading..."
                                }
                                </tbody>
                            </table>
                                <div>
                                    <Pagination
                                        activePage={state?.userDailyPlans?.current_page ? state?.userDailyPlans?.current_page : 0}
                                        itemsCountPerPage={state?.userDailyPlans?.per_page ? state?.userDailyPlans?.per_page : 0 }
                                        totalItemsCount={state?.userDailyPlans?.total ? state?.userDailyPlans?.total : 0}
                                        onChange={(pageNumber) => {
                                            fetchData(pageNumber)
                                        }}
                                        pageRangeDisplayed={8}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        firstPageText="First Page"
                                        lastPageText="Last Lage"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default UserDailyPlans;


