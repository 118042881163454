
import React, {useEffect,useState} from 'react';
// import Pagination from "react-js-pagination";
import Cookies from "universal-cookie";
import axios from "axios";
import {Avatar} from "evergreen-ui"
import Host,{FrontEndDomain} from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import SettlementForm from './SettlementForm';
import host from '../../../assets/js/Host';
import SettlementPreview from './SettlementPreview';
import IconButton from "@material-ui/core/IconButton";
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import BasicDateRangePicker from '../GeneralComponents/DateFromTo'
import moment from 'moment';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import Tooltip from '@mui/material/Tooltip';
import CircularIndeterminate from  '../GeneralComponents/CirularProgress'
import StyledDataGrid from '../GeneralComponents/StyledDataGrid'
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { useGlobalContext } from '../../../context'

import "../../../assets/css/DG.css"
import "../../../assets/css/AccordionStyle.css"
import SearchField1 from  '../GeneralComponents/SearchField1'

const cookies = new Cookies();
const token = cookies.get("token")
const language = cookies.get("language")?cookies.get("language"):"ar"
function Settlement() {
    const currentDate = new Date();
    const options = { timeZone: "Asia/Baghdad" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(currentDate);

    const [pageSize, setPageSize] = useState(20);    
    const [pageNumber, setpageNumber] = useState(1);
    const [pointsTotal, setPointsTotal] = useState(0)
    const [pointsNumberOfPages, setPointsNumberOfPages] = useState(0)

    const {loading,fromToDate,settlements,pointsStatuses,pointStatus,setPointStatus,exportToCSV,allPoints,user,users,setUser,points,district,region,districts,regions,ownerName,shopName,clearPointsAdvanceSearchFields,setRegion,setDistrict,setShopName,setOwnerName,fetchData,deleteObject} = useGlobalContext()
    
    // const [loading, setloading] = useState(false);
    // const [state, setData] = useState({
    //     settlements: ''
    // });
    const [showSettlementForm, setshowSettlementForm] = useState(false);
    const [showSettlementPreview,setshowSettlementPreview] = useState(false)
    const [selectedSettlement, setselectedSettlement] = useState(null);
    const [accExpansion,setAccExpansion] = useState(false)
    const [fromDate, setFromDate] = useState(formattedDate);
    const [toDate, setTODate] = useState(formattedDate);
    
    const handelChangeDate = (date, number) => {
        if (number == 1) {
            setFromDate(date);
        } else if (number == 2) {
            setTODate(date);
        }
    };


    const handleExpansion = (expansion) => setAccExpansion(expansion)

    const handlePageChange = (newPage) => {        
        setpageNumber(newPage+1)          
    }

    const advanceSearch = async(type = "BROWSING")=>{

        const fetchedData = await fetchData('SETTLEMENTS','settlements',{
            page:type==="SEARCH" ? 1: pageNumber,            
            page_size:pageSize,            
            
            created_by:user.id,          
            creation_date_from:moment(fromDate)?.format('YYYY-MM-DD'),
            creation_date_to:moment(toDate)?.format('YYYY-MM-DD'),            
        })
        
        return fetchedData
    }

    const updateObject = async (object) => {
        setselectedSettlement(object)
        setshowSettlementForm(true)
    };
    const previewObject = async (object) => {
        setselectedSettlement(object)
        setshowSettlementPreview(true)
    }
    
    const createObject = async (object) => {
        setselectedSettlement(null)
        setshowSettlementForm(true)
    };

    const handleSetshowSettlementForm = (openStatus,needsReload) => {
      if(needsReload)
        
        advanceSearch()
      setshowSettlementForm(openStatus)
    };

    const handleSetshowSettlementPreview = (openStatus,needsReload)=>{
        if(needsReload)
            
            advanceSearch()
        setshowSettlementPreview(openStatus)
    }



    const getImageUrl =  (image_url)=>{
        var hostURLParts=host.split("/")
        var hostURL=hostURLParts[0]+"//"+hostURLParts[2]+"/"
        const fullImageURL=hostURL+image_url
      
        return fullImageURL;
    }
    
    useEffect( () => {    
        fetchData('REGIONS','regions_list') 
        fetchData('USERS','users_list')                             
        advanceSearch()    
    }, [pageSize,pageNumber])
    
    //Reformatting points object once points are loaded
    useEffect(() => {        
        
        if (settlements && settlements.data && settlements.data.length > 0) {
            const reformatSettlements  = settlements.data.map((rowSettlement) =>{                    
             
           
                return {   ...rowSettlement,
                                    
                }                
            })                             
            setRow(reformatSettlements);                
            setPointsTotal(settlements.total)    
            setPointsNumberOfPages(settlements.last_page)                     
        }else{
            setRow([]);                
            setPointsTotal(0)    
            setPointsNumberOfPages(0) 
        }

    }, [settlements])
    

    const [rows,setRow] = useState([])
    const columns = [
        { field: 'id', headerName: 'ID' , flex: .1},        
        { field: 'settlement_by_user.full_name', headerName: 'Settlement made by' , flex: 2,
        renderCell: (rows) => (
            <div style={{ width: '100%' }} >                               
                  {rows.row.settlement_by_user.full_name}
            </div>
        )},
        { field: 'settlement_for_user.full_name', headerName: 'Settlement for ' , flex: 1.5,
        renderCell: (rows) => (
            <div style={{ width: '100%',}} >                               
                  {rows.row.settlement_for_user.full_name}                  
            </div>
        )}, 
        { field: 'settlement_amounts', headerName: 'Amount collected (USD) ' , flex: 1.5,
        renderCell: (rows) => (
            <div style={{ width: '100%',}} >    
                {rows.row.settlement_amounts.length>0?rows.row.settlement_amounts[0].amount:0}                 
            </div>
        )}, 
        {  headerName: 'Amount collected (IQD) ' , flex: 1.5,
        renderCell: (rows) => (
            <div style={{ width: '100%',}} >    
               {rows.row.settlement_amounts.length>1?rows.row.settlement_amounts[1].amount:0}                
            </div>
        )},  
              
        { field: 'created_at', headerName: 'created Date' , flex: 1.5,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} >                               
                    {rows.row.created_at}                 
                </div>)
        },

       
        { field: 'actions',flex: 1,
            headerName: 'Actions',                
            renderCell: (rows) => (
              <strong>                
                

                <IconButton  
                    style={{ marginLeft: 15 }}
                    aria-label="view" 
                    size="small" 
                    onClick={() => {                                   
                    previewObject(rows.row)
                    // previewObject(rows.row.object1)
                    }}>
                    <Tooltip title="View">
                        <RemoveRedEyeRoundedIcon color="primary" />
                    </Tooltip>

                </IconButton>
                <IconButton  
                    style={{ marginLeft: 15 }}
                    aria-label="Download" 
                    size="small" 
                    onClick={async () => {                       
                        window.open(Host + `settlements_report_pdf?id=${rows.row.id}`, '_blank')
                    }}>
                    <Tooltip title="Download">
                        <DownloadForOfflineIcon style={{ color: '#578c70' }} />
                    </Tooltip>
                    
                </IconButton>                                                
              </strong>
            ),
        },        
    ];


    return (
        <>
            {showSettlementForm?
                <SettlementForm settlement={selectedSettlement} showSettlementForm={showSettlementForm} setshowSettlementForm={handleSetshowSettlementForm} />
            :null}
            {showSettlementPreview?
                <SettlementPreview settlement={selectedSettlement} showSettlementPreview={showSettlementPreview} setshowSettlementPreview={handleSetshowSettlementPreview} />
            :null}
            
           
           <Box display="flex" >
                <Button display = "flex"
                    startIcon={<FileDownloadIcon />}
                    variant="contained" 
                    sx={{marginRight: 1, marginBottom: accExpansion ? "0":"20px" }}
                    onClick = { async () => {
                                      
                                        const fetchedData = await fetchData('SETTLEMENTS','settlements',{
                                            page_size:pointsTotal,     
                                            created_by:user.id,         
                                            creation_date_from:moment(fromDate)?.format('YYYY-MM-DD'),
                                            creation_date_to:moment(toDate)?.format('YYYY-MM-DD'),            
                                        })
                                        exportToCSV(fetchedData.data,'settlements')
                                        
                                    } 
                             }>
                    Export
                </Button>           
                {/* <ExportCSV csvData={points.data} fileName={"test"} /> */}
                <Accordion display = "flex" sx={{ flexGrow: 4}} 
                    onChange={(event,expansion) => handleExpansion(expansion)}
                > 
                    <AccordionSummary 
                        expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography>Advance Search</Typography>
                    </AccordionSummary>
                    <AccordionDetails >
                        <Box component="span" display="flex" bgcolor = "white" flexDirection="column"  sx={{ borderRadius: 1 }} >
                            <Box sx={{m:1}} component="span" display="flex" bgcolor = "white" flexDirection="row" >
                               

                                
                                {/* <SearchField1 state={shopName} setState = {setShopName} fieldLabel='Shop Name'/>                    
                                <SearchField1 state={ownerName} setState = {setOwnerName} fieldLabel='Owner Name'/> */}
                                {/* <BasicDateRangePicker/> */}

                                <Box display="flex" sx={{ flexGrow: 1 }}>
                                    <Button 
                                        variant="contained" 
                                        spacing={2} sx={{ flexGrow: 1,margin: 1 }}
                                        onClick = {() => advanceSearch("SEARCH")} >
                                        Search
                                    </Button>
                                    <Button variant="contained" 
                                        spacing={2} 
                                        sx={{ flexGrow: 1,margin: 1 }} 
                                        color="secondary"
                                        onClick = {() => {
                                            clearPointsAdvanceSearchFields();
                                            setFromDate(null);
                                            setTODate(null);
                                        }} >
                                        Clear
                                    </Button>                       
                                </Box>                
                            </Box>
                            <Box sx={{m:1}} component="span" display="flex" bgcolor = "white" flexDirection="row" >                
                                <Autocomplete                                                 
                                    sx={{ml:1}}
                                    fullWidth                            
                                    options = {users}                            
                                    getOptionLabel = {(option) => option.full_name || ""}
                                    id="disable-close-on-select"                        
                                    value = {user}
                                    onChange={(e,v,r) => {setUser(v ? v:'');}}
                                    renderInput={(params) => (
                                    <TextField {...params} label="Users" variant="outlined" />
                                    )}
                                />


                                {/* <BasicDateRangePicker/> */}
                            </Box>
                            <Box sx={{ m: 1 }} component='span' display='flex' bgcolor='white' flexDirection='row'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        label='From Date'
                                        inputFormat='YYYY-MM-DD'
                                        value={fromDate}
                                        onChange={(date) => {
                                            handelChangeDate(new Date(date), 1);
                                        }}
                                        renderInput={(params) => <TextField {...params} sx={{ ml: 1, width: "100%" }} />}
                                    />
                                    <DesktopDatePicker
                                        label='to Date'
                                        inputFormat='YYYY-MM-DD'
                                        value={toDate}
                                        onChange={(date) => {
                                            handelChangeDate(new Date(date), 2);
                                        }}
                                        renderInput={(params) => <TextField {...params} sx={{ ml: 1, width: "100%" }} />}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>


           <div style={{height:'calc(100% - 9em)'}}>
                
                <StyledDataGrid                                                                          
                    rows={rows}
                    columns={columns}                                    
                    rowCount={pointsTotal}
                    pageSize={pageSize}
                    page = {pageNumber-1}
                    onPageSizeChange={(newPageSize) =>{                    
                        setPageSize(newPageSize)                      
                    }}                
                    rowsPerPageOptions={[5, 10, 20]}                  
                    paginationMode="server"
                    onPageChange={(params) =>{
                        handlePageChange(params)
                    }}                         
                />
                <Box width="45%" m="auto">
                    <Pagination                    
                        color="primary"
                        size="large"                
                        count={pointsNumberOfPages}                         
                        page={pageNumber}                
                        showFirstButton
                        showLastButton 
                        onChange ={(e,p) =>{                             
                            setpageNumber(p)                             
                        }}
                    />
                </Box>
                <div style={{width:'50%', position:'absolute', top:'100px',margin:'0 auto', left:'50%', top:'50%'}}>
                    {loading && (
                        <CircularIndeterminate />
                    )}
                </div>
            </div>

                 </>
    );
}

export default Settlement;


