import React,{useEffect,useState} from "react";
import './ErrorPage.css'
import {Link} from 'react-router-dom'
import {styled} from '@mui/material/styles'
import { useSelector } from "react-redux";
import {Box} from '@mui/material';
// import {useTranslation} from "react-i18next";
const ContainerRedirectPage=styled('div')(({theme,SideMenuOpen,screenWidth,SearchButtonTheme})=>({
  position: 'relative',
  top:'-60px',
  left:SideMenuOpen?screenWidth>767?'-170px':'50px':'-30px',
  // left:'-30px',
  width: '100vw',
  height:'100vh',
  '& .button_back':{
    fontFamily: "'Quicksand', sans-serif",
    fontSize: "14px",
    textDecoration: "none;",
    textTransform: "uppercase",
    background:SearchButtonTheme?.go_back_button_color,
    display: "inline-block",
    padding:" 15px 30px;",
    borderRadius: "5px;",
    color:SearchButtonTheme?.go_back_button_text_color,
    fontWeight: 700,
    marginTop:" 20px",
  },
}))

export default function ErrorPage({Code,title,RedirectPage,ErrorMessage}) {

  useEffect(() => {


  }, []);
  return (
    <Box className="containerRedirectPage"
      sx={{
        position: 'relative',
        backgroundColor:'#fff',
        top:'-60px',
        left:'50px',
        // left:'-30px',
        width: '90%',
        height:'90%',
        '& .button_back':{
          fontFamily: "'Quicksand', sans-serif",
          fontSize: "14px",
          textDecoration: "none;",
          textTransform: "uppercase",
          background:'green',
          display: "inline-block",
          padding:" 15px 30px;",
          borderRadius: "5px;",
          color:'#fff',
          fontWeight: 700,
          marginTop:" 20px",
        },
      }}
    >
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-bg">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <h1>oops!</h1>
          <h2>{ErrorMessage} {Code} : {title}</h2>
          <Link to={RedirectPage} className="button_back">{'Go Back'}</Link>
        </div>
      </div>
    </Box>
  );
}
