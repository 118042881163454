import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Pane, Dialog } from 'evergreen-ui';
import axios from "axios";
import Select from "react-select";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
const cookies = new Cookies();
const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
export const UserRoleForm = (props) => {
    const [loading, setloading] = useState(true);
    const [roles, setroles] = useState([]);
    const [userRolesSelected, setuserRolesSelected] = useState([]);
    const [object, setobject] = useState({
        id: null,
        full_name: null,
        phone: null,
        username: null,
        password:null,
        role_id: null,
        role:{
            id:null,
            name:null
        }
    });
    const getResources = async () => {
        try {
            setloading(true)
            const resourcesResult = await axios({
                url: Host + "roles_list",
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                }
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                    const resources = resourcesResult.data;

                    // console.log(resources);
                    setroles(resources);
                    if(props&& props.user&& props.user.id>0 )
                    {
                        if(props.user.roles && props.user.roles.length>0)
                        {
                            console.log("in");
                            setuserRolesSelected(props.user.roles.map((role)=>role.id))
                        }
                        
                        setobject({...object,...props.user});
                    }
                    else{
                        console.log("hi");
                        setobject(
                            {
                                id: null,
                                full_name: null,
                                phone: null,
                                username: null,
                                password:null,
                                role_id: null,
                                role:{
                                    id:null,
                                    name:null,
                                }
                            }
                        )
                    }
                } else if (resourcesResult.data.status === false) {

                }
            }
            else {
                toast.error("Error")
            }
            setloading(false)
        } catch (error) {
            setloading(false)
            toast.error("Network Error")
        }
    };

    const submitForm = async () => {
        
        try {
            
        
            if (object.id) {                
                let formdata = new FormData();

                formdata.append("roles", JSON.stringify(userRolesSelected));
               
                const resourcesResult = await axios({
                    url: Host + "users/roles/"+object.id,
                    method: "put",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                       
                        accept: "application/json",
                        // Accept: "application/json",
                        // "Content-Type": "multipart/form-data",
                        'X-localization': language,
                    },
                    data:{
                        roles:userRolesSelected
                    }
                    
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("updated ")
                        props.setshowUserRoleForm(false,true)
                    } else if (resourcesResult.data.status === false) {
                        props.setshowUserRoleForm(true,false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setshowUserRoleForm(true,false)
                }
                setloading(false)
            }
            else{

            }
           
        } catch (error) {
            props.setshowUserRoleForm(true,false)
            const resourcesResult=error.response            
            if (resourcesResult && resourcesResult.status === 400) {
                if(typeof resourcesResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        resourcesResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    
                    toast.error(resourcesResult.data.data)
                }
                
                
            }
            else if(resourcesResult && resourcesResult.status === 401) {
             
                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
              
                toast.error("network error")
            }
        }
        
    };

    const handleInputChange = (event,role_id) => {
        const target = event.target;
        // console.log("ischecked",target.checked);
        if(target.checked)
        {
          
            setuserRolesSelected([...userRolesSelected,role_id])
        }
        
        else
        {
            const userRolesSelectedtemp = userRolesSelected.filter(function(item) {
                return item !== role_id
            })
            setuserRolesSelected(userRolesSelectedtemp)
        }
        

    };
    
    useEffect(() => {
        console.log(props.user);
       
        if(loading)
            getResources()

        return () => {

        };
    }, [loading,props.showUserRoleForm]);


    return (
        loading ? <></> :
        <Dialog
                isShown={props.showUserRoleForm}
                onCloseComplete={() => props.setshowUserRoleForm(false)}
                hasHeader={false}
                shouldCloseOnOverlayClick={false}
                confirmLabel="Save"
                cancelLabel="Cancel"
                onConfirm={() => {submitForm()}}
                >
            <h4>User Roles</h4>
            <form  >
                {roles.map((role)=>{
                    var isChecked=false;
                    if(userRolesSelected.length>0 && userRolesSelected.includes(role.id))
                    {
                        isChecked=true;
                    }
                    return (
                        <div className="form-group">
                            {/* <label htmlFor={"role_"+role.id}>{role.name} </label>
                            <input className="form-control" 
                                type="checkbox"
                                checked={isChecked}
                                onChange={(e)=>handleInputChange(e,role.id)}
                                id={"role_"+role.id}
                               
                            /> */}

                            <div className="form-check">
                                <input className="form-check-input" 
                                type="checkbox"
                                 value={role.name} 
                                 checked={isChecked}
                                 onChange={(e)=>handleInputChange(e,role.id)}
                                 id={"role_"+role.id}/>
                                <label className="form-check-label" htmlFor={"role_"+role.id}>
                                {role.name}
                                </label>
                            </div>

                        </div>
                    );
                    
                })}                
            </form>
        </Dialog>

    );
};
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',

    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: '100%',
        border: '1px solid #ababab',
        borderRadius: 5,
        padding: 0,
        display: 'flex'
    }),
    container: () => ({

        width: '80%',
        position: 'relative',
        boxSizing: 'border-box',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

export default UserRoleForm;
