import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import React from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Context from "./assets/js/context";
import Login from "./component/main/Login";
import AppBarAndContainer from "./component/main/AppBarAndContainer";
import { ToastContainer } from "react-toastify";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/test1.css";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            ms: [],
            data1: [],
            Dash: [],
            check: "",
            che: "login",
            new: "",
            inprogress: "",
            closed: "",
            approved: "",
            rejected: "",
            archived: "",
            noti: "",
            desc: "",
            commenter: "",
            body: "",
            title: "",
            SiFilter: [],
        };
    }

    componentDidMount() {}

    render() {
        return (
            <BrowserRouter>
                <ToastContainer position='top-center' autoClose={5000} hideProgressBar newestOnTop closeOnClick rtl pauseOnVisibilityChange draggable pauseOnHover />

                <Context.Provider
                    value={{
                        value: this.state,
                        action: {},
                    }}>
                    <Switch>
                        <Route exact path='/' component={Login} />

                        <Route path='/Users' component={AppBarAndContainer} />
                        <Route path='/Roles' component={AppBarAndContainer} />
                        <Route path='/Dashboard' component={AppBarAndContainer} />
                        <Route path='/Surveys' component={AppBarAndContainer} />
                        <Route path='/build_survey' component={AppBarAndContainer} />
                        <Route path='/PointStatus' component={AppBarAndContainer} />
                        <Route path='/PointRequests' component={AppBarAndContainer} />
                        <Route path='/Points' component={AppBarAndContainer} />
                        <Route path='/Visits' component={AppBarAndContainer} />
                        <Route path='/Settlements' component={AppBarAndContainer} />
                        <Route path='/Collections' component={AppBarAndContainer} />
                        <Route path='/Departments' component={AppBarAndContainer} />
                        <Route path='/Districts' component={AppBarAndContainer} />
                        <Route path='/Regions' component={AppBarAndContainer} />
                        <Route path='/Journeys' component={AppBarAndContainer} />
                        <Route path='/user_daily_plans' component={AppBarAndContainer} />
                        <Route path='/field_lists' component={AppBarAndContainer} />
                        <Route path='/field_list_values' component={AppBarAndContainer} />
                        <Route path='/follow_up_type' component={AppBarAndContainer} />
                        <Route path='/follow_up_method' component={AppBarAndContainer} />
                        <Route path='/point_follow_ups' component={AppBarAndContainer} />
                        <Route path='/point_scedule_follow_ups' component={AppBarAndContainer} />
                        <Route path='/invoices' component={AppBarAndContainer} />
                        <Route path='*' component={AppBarAndContainer} />
                    </Switch>
                </Context.Provider>
            </BrowserRouter>
        );
    }
}

export default App;
