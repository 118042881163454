import { configureStore,getDefaultMiddleware  } from '@reduxjs/toolkit';
import dailyPlanReducer from '../component/reduxStore/dailyPlanReducer';
import forceReloadReducer from '../component/reduxStore/forceReloadReducer';



export default configureStore({
  reducer: {
    dailyPlanData: dailyPlanReducer,
    forceReload: forceReloadReducer,
   
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  },
});
