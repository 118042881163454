
import React, {useEffect,useState} from 'react';
import Pagination from "react-js-pagination";
import Cookies from "universal-cookie";
import axios from "axios";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import FieldListValueForm from './FieldListValueForm';

const cookies = new Cookies();
const token = cookies.get("token")
const language = cookies.get("language")?cookies.get("language"):"ar"

const urlParams = new URLSearchParams(window.location.search);
const fieldListId = urlParams.get('id');

function FieldListValue() {

    const [loading, setloading] = useState(false);
    const [state, setData] = useState({
        fieldListValues: ''
    });
    const [showFieldListValueForm, setshowFieldListValueForm] = useState(false);
    const [selectedFieldListValue, setselectedFieldListValue] = useState(null);
    const fetchData = async (pageNumber = 1) => {
        setloading(true)
        try {
            const result=await axios({
                url: Host + "field_list_values_list",
                method: "get",
                headers: {
                "Authorization" : `Bearer ${token}`,
                    Accept:"application/json",
                    'X-localization':language,
                },
                params:{
                    field_list_id:fieldListId
                }
        
            })
            if(result && result.status == 200)
            {
                const fieldListValues=result.data ;
                
                setData({
                    fieldListValues:fieldListValues 
                });
            } else if (result.data.status === false) {
                toast.error("Error")
            }
        } catch (error) {
            const responseResult=error.response            
            if (responseResult && responseResult.status === 400) {
                if(typeof responseResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        responseResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    toast.error(responseResult.data.data)
                }
                
            }
            else if(responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
                toast.error("network error")
            }
        }
        setloading(false)
                   
    };
    const updateObject = async (object) => {
      
        
        setselectedFieldListValue(object)
        setshowFieldListValueForm(true)
    };
    const deleteObject = async (object) => {
        if (!window.confirm("Delete the item?")) {
            return
        }
        setloading(true)
        try {
            const result=await axios({
                url: Host + "field_list_values/"+object.id,
                method: "delete",
                headers: {
                "Authorization" : `Bearer ${token}`,
                    Accept:"application/json",
                    'X-localization':language,
                }
        
            })
            if(result && result.status == 200)
            {
                toast.error(result.data.data)
                fetchData();
            } else if (result.data.status === false) {
                toast.error("Error")
            }
        } catch (error) {
            const responseResult=error.response            
            if (responseResult && responseResult.status === 400) {
                if(typeof responseResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        responseResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    toast.error(responseResult.data.data)
                }
                
            }
            else if(responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
                toast.error("network error")
            }
        }
        setloading(false)
    };
    
    const createObject = async (object) => {
        setselectedFieldListValue(null)
        setshowFieldListValueForm(true)
    };

    const handleSetshowFieldListValueForm = (openStatus,needsReload) => {
      if(needsReload)
      fetchData();
      setshowFieldListValueForm(openStatus)
    };
    
    
    useEffect(() => {
        fetchData();
    }, [])
    
    return (
        <>
            {showFieldListValueForm?
                <FieldListValueForm  fieldListId={fieldListId} fieldListValue={selectedFieldListValue} showFieldListValueForm={showFieldListValueForm} setshowFieldListValueForm={handleSetshowFieldListValueForm} />
            :null}
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">FieldListValue List <Button style={{float:'right'}} onClick={()=>{createObject()}}>create</Button></div>
                            <div className="card-body">
                            <table>
                                <thead>
                                    <tr>
                                        <th>1</th>
                                        <th>FieldListValue Name</th>
                                        <th>edit</th>
                                        <th>delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {   
                                    state?.fieldListValues ? 
                                        state?.fieldListValues?.map((object) => (
                                                <tr key={object?.id}>
                                                    <td>{object?.id}</td>
                                                    <td>{object?.value}</td>
                                                    <td><Button onClick={()=>{updateObject(object)}}>update</Button></td>
                                                    <td><Button style={{backgroundColor:'red',color:'white'}} onClick={()=>{deleteObject(object)}}>delete</Button></td>
                                                    
                                                </tr>
                                        )) : "Loading..."
                                }
                                </tbody>
                            </table>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FieldListValue;


