
import React, {useEffect,useState} from 'react';
import Pagination from "react-js-pagination";
import Cookies from "universal-cookie";
import axios from "axios";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import DepartmentForm from './DepartmentForm';

const cookies = new Cookies();
const token = cookies.get("token")
const language = cookies.get("language")?cookies.get("language"):"ar"
function Department() {

    const [loading, setloading] = useState(false);
    const [state, setData] = useState({
        departments: ''
    });
    const [showDepartmentForm, setshowDepartmentForm] = useState(false);
    const [selectedDepartment, setselectedDepartment] = useState(null);
    const fetchData = async (pageNumber = 1) => {
        setloading(true)
        try {
            const result=await axios({
                url: Host + "departments?page="+pageNumber,
                method: "get",
                headers: {
                "Authorization" : `Bearer ${token}`,
                    Accept:"application/json",
                    'X-localization':language,
                }
        
            })
            if(result && result.status == 200)
            {
                const departments=result.data ;
                
                console.log(departments);
                setData({
                    departments:departments 
                });
            } else if (result.data.status === false) {
                toast.error("Error")
            }
        } catch (error) {
            const responseResult=error.response            
            if (responseResult && responseResult.status === 400) {
                if(typeof responseResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        responseResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    toast.error(responseResult.data.data)
                }
                
            }
            else if(responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
                toast.error("network error")
            }
        }
        setloading(false)
                   
    };
    const updateObject = async (object) => {
      
        
        setselectedDepartment(object)
        setshowDepartmentForm(true)
    };
    const deleteObject = async (object) => {
        if (!window.confirm("Delete the item?")) {
            return
        }
        setloading(true)
        try {
            const result=await axios({
                url: Host + "departments/"+object.id,
                method: "delete",
                headers: {
                "Authorization" : `Bearer ${token}`,
                    Accept:"application/json",
                    'X-localization':language,
                }
        
            })
            if(result && result.status == 200)
            {
                toast.error(result.data.data)
                fetchData();
            } else if (result.data.status === false) {
                toast.error("Error")
            }
        } catch (error) {
            const responseResult=error.response            
            if (responseResult && responseResult.status === 400) {
                if(typeof responseResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        responseResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    toast.error(responseResult.data.data)
                }
                
            }
            else if(responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
                toast.error("network error")
            }
        }
        setloading(false)
    };
    
    const createObject = async (object) => {
        setselectedDepartment(null)
        setshowDepartmentForm(true)
    };

    const handleSetshowDepartmentForm = (openStatus,needsReload) => {
      if(needsReload)
      fetchData();
      setshowDepartmentForm(openStatus)
    };
    
    
    useEffect(() => {
        fetchData();
    }, [])
    
    return (
        <>
            {showDepartmentForm?
                <DepartmentForm department={selectedDepartment} showDepartmentForm={showDepartmentForm} setshowDepartmentForm={handleSetshowDepartmentForm} />
            :null}
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">Department List <Button style={{float:'right'}} onClick={()=>{createObject()}}>create</Button></div>
                            <div className="card-body">
                            <table>
                                <thead>
                                    <tr>
                                        <th>1</th>
                                        <th>Department Name</th>
                                        <th>edit</th>
                                        <th>delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {   
                                    state?.departments?.data ? 
                                        state?.departments?.data?.map((object) => (
                                                <tr key={object?.id}>
                                                    <td>{object?.id}</td>
                                                    <td>{object?.name}</td>
                                                    <td><Button onClick={()=>{updateObject(object)}}>update</Button></td>
                                                    <td><Button style={{backgroundColor:'red',color:'white'}} onClick={()=>{deleteObject(object)}}>delete</Button></td>
                                                    
                                                </tr>
                                        )) : "Loading..."
                                }
                                </tbody>
                            </table>
                                <div>
                                    <Pagination
                                        activePage={state?.departments?.current_page ? state?.departments?.current_page : 0}
                                        itemsCountPerPage={state?.departments?.per_page ? state?.departments?.per_page : 0 }
                                        totalItemsCount={state?.departments?.total ? state?.departments?.total : 0}
                                        onChange={(pageNumber) => {
                                            fetchData(pageNumber)
                                        }}
                                        pageRangeDisplayed={8}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        firstPageText="First Page"
                                        lastPageText="Last Lage"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Department;


