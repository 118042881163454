

import React from "react";
import loading from '../../assets/js/loading.json';
import axios from "axios";
import Component from "@reactions/component";
import Cookies from "universal-cookie";
import Lottie from 'lottie-react-web';
import Host from "../../assets/js/Host";
import { toast } from 'react-toastify';


const cookies = new Cookies();
const language = cookies.get("language")?cookies.get("language"):"ar"

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            username: '',
            password: '',
            errors: false,
        };
    }

    render() {
        if (cookies.get("token") !== undefined) window.location.href = "/dashboard";
        return (
            <div id='main' >
                <div id="login_main">
                    <img src={require("../../assets/img/logo_extended.png")} alt="img" style={{ height: "125px" }} />
                    {/* <div
                        style={{
                            fontSize: 22,
                            lineHeight: "40px",
                            color: "#707070",
                            fontWeight: "bold"
                        }}
                    >
                        Ticket System
                        </div> */}
                    <div
                        style={{
                            fontSize: 20,
                            lineHeight: "40px",
                            color: "#707070",
                            marginBottom: 30
                        }}
                    >
                    </div>
                    <div className="Sign_container" style={{ position: 'relative' }} >
                        <div className="up_field">
                            <input
                                id="field1"
                                placeholder="Username"
                                value={this.state.username}
                                onKeyDown={(e) => this.onEnter(e)}
                                onChange={e => {
                                    this.setState({ username: e.target.value });
                                }}
                            />
                            <input
                                id="field12"
                                name="text-input-name"
                                type="password"
                                placeholder="Password "
                                required
                                value={this.state.password}
                                onKeyDown={(e) => this.onEnter(e)}
                                onChange={e => {
                                    this.setState({ password: e.target.value });
                                }}
                            />
                        </div>
                        <div className="down_field">
                            <Component initialState={{ isShown: true, spin: false }}>
                                {({ state, setState }) =>
                                    <div
                                        id="sign_but"
                                        onClick={async (e) => {
                                            if (this.state.username.length < 3) {
                                                return (
                                                    toast.error(`Username is short`)
                                                );
                                            }
                                            else if (this.state.password.length < 3) {

                                                return (
                                                    toast.error(`Password must be more than 3 char`)
                                                );
                                            }
                                            setState({ spin: true });

                                            try {
                                                const responseResult = await axios({
                                                    url: Host + "login",
                                                    method: "post",
                                                    headers: {
                                                        // 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                                                        Accept:"application/json",
                                                        'X-localization':language,
                                                        
                                                    },
                                                    data:{
                                                        username: this.state.username.includes("+964")?null:this.state.username,
                                                        phone: this.state.username.includes("+964")?this.state.username:null,
                                                        password: this.state.password,
                                                    }
                                                
                                                })

                                                if (responseResult.data.success === true) { 
                                                    localStorage.setItem("defaultSettings", JSON.stringify(responseResult.data.data));
                                                    var responseJson=responseResult.data
                                                
                                                    let token = responseJson.data.token
                                                    let status = responseJson.status
                                                    cookies.set("status", status)
                                                    cookies.set("username", responseJson.data.user.username)
                                                    cookies.set("full_name", responseJson.data.user.full_name)
                                                    cookies.set("user_id", responseJson.data.user.id)
                                                    // localStorage.setItem("roles", JSON.stringify(responseJson.data.roles));
                                                    cookies.set("token", token, {
                                                        path: "/",
                                                        expires: new Date(Date.now() + 60480000)
                                                    });
                                                    setState({ spin: false })
                                                    window.location.href = "/Dashboard";
                                                } else if (responseResult.data.status === false) {
                                                    
                                                }
                                            } catch (error) {
                                                const responseResult=error.response            
                                                if (responseResult && responseResult.status === 400) {
                                                    if(typeof responseResult.data.data === "object")
                                                    {
                                                        const errorMessagesKeys = Object.keys(responseResult.data.data);
                                                        errorMessagesKeys.map((key)=>{
                                                        
                                                            responseResult.data.data[key].map((message)=>{
                                                                toast.error(message)
                                                            })
                                                        })
                                                    }
                                                    else{
                                                        toast.error(responseResult.data.data)
                                                    }
                                                    
                                                }
                                                else if(responseResult && responseResult.status === 401) {
                                                    toast.error(responseResult.data.data)
                                                    cookies.remove("token");
                                                    window.location.href = "/"
                                                }
                                                else
                                                {
                                                    toast.error("network error")
                                                }  
                                                setState({ spin: false })
                                            }
                                        }} >
                                        {state.spin === false ? (
                                            <div> Login  </div>
                                        ) : (
                                                <div>
                                                    <div>loading ...</div>
                                                    <div style={{ width: "100%", position: "absolute", top: '102px', right: '-7px' }}>
                                                        <Lottie
                                                            options={{
                                                                animationData: loading
                                                            }}
                                                            width={300}
                                                            height={150}
                                                            position="absolute"
                                                        />
                                                    </div></div>
                                            )}
                                    </div>
                                }
                            </Component>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ textAlign: 'center', color: 'rgb(112, 112, 112)', marginTop: 5, padding: 5, fontSize: 18 }} >© 2020 Integrated Solutions . All rights reserved </div>
                </div>
            </div>

        );
    }

    onEnter(event) {
        if (event.keyCode === 13) {
            document.getElementById("sign_but").click();
        }
    }
}


export default Login;
